import React, { FormEvent, useState } from "react";
import { useMutation } from "@apollo/client";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment-mini";
import { LOGIN_MUTATION } from "../../api/auth";
import UIInputWrapper from "../ui/InputWrapper";
import UILabel from "../ui/Label";
import UIInput from "../ui/Input";
import UIButton from "../ui/Button";

interface ISignIn {
  hastoNavigate?: boolean;
  handleModal?: () => void;
}

const SignIn: React.FC<ISignIn> = ({ hastoNavigate, handleModal }) => {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [loginUser] = useMutation(LOGIN_MUTATION, {
    variables: {
      email: email,
      password: password,
    },
  });

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    try {
      const res = await loginUser();

      if (res && res.data.userLogin.token) {
        localStorage.setItem("token", "Bearer " + res.data.userLogin.token);
        localStorage.setItem("userId", res.data.userLogin.user.id);
        localStorage.setItem("name", res.data.userLogin.user.name);
        localStorage.setItem("email", res.data.userLogin.user.email);
        localStorage.setItem(
          "phoneNumber",
          res.data.userLogin.user.phoneNumber,
        );
        localStorage.setItem("loginTime", moment().toString());
        localStorage.setItem("totalEvent", res.data.userLogin.totalEvent);
        localStorage.setItem("liveEvent", res.data.userLogin.liveEvent);
        localStorage.setItem("draftEvent", res.data.userLogin.draftEvent);
        localStorage.setItem("stripeInfo", res.data.userLogin.stripeKeys);
        if (hastoNavigate) {
          if (res.data.userLogin.stripeKeys) {
            toast.success("Login successful.", {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 2000,
            });
            navigate("/plan-your-event");
          } else {
            toast.success("Please add your stripe info.", {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 2000,
            });
            navigate("/dashboard");
          }
        } else {
          handleModal && handleModal();
        }
      }
    } catch (error: any) {
      if (error.message === "Invalid Password") {
        toast.error("Invalid password.");
      } else if (
        error.message === "You must validate yourself from your emailId"
      ) {
        toast.error("You must validate your email.");
      } else if (error.message === "email does not exists") {
        toast.error("Invalid Email.");
      } else {
        toast.error("Unable to login.");
      }
      console.error(error);
    }
  };
  return (
    <form onSubmit={handleSubmit}>
      <UIInputWrapper>
        <UILabel htmlFor="email">Email</UILabel>
        <UIInput
          id="email"
          type="email"
          placeholder="Enter your email address"
          required
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </UIInputWrapper>
      <UIInputWrapper>
        <div className="flex justify-between">
          <UILabel htmlFor="password">Password</UILabel>
        </div>
        <UIInput
          id="password"
          type="password"
          className="form-input w-full text-gray-800"
          placeholder="Enter your password"
          required
          value={password}
          autoComplete="true"
          onChange={(e) => setPassword(e.target.value)}
        />
        <Link
          to="/forgot-password"
          className="mt-1 block text-right text-xs text-blue-600 transition duration-150 ease-in-out hover:underline"
        >
          Forgot password ?
        </Link>
      </UIInputWrapper>
      <UIInputWrapper>
        <UIButton type="submit">Sign in</UIButton>
      </UIInputWrapper>
    </form>
  );
};

export default SignIn;
