import { gql } from "@apollo/client";

export const UPLOAD_EVENT_IMAGE = gql`
  mutation Event_uploadImageUrl(
    $userid: Int!
    $eventid: Int!
    $imageUrl: String!
  ) {
    event_uploadImageUrl(
      userid: $userid
      eventid: $eventid
      image_url: $imageUrl
    )
  }
`;
