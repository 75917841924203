import { gql } from "@apollo/client";

export const CANCEL_SUBSCRIPTION = gql`
  mutation Payment_cancelSubscription(
    $userId: Int!
    $userStripeSubscriptionId: String!
    $planId: Int!
  ) {
    payment_cancelSubscription(
      userId: $userId
      userStripeSubscriptionId: $userStripeSubscriptionId
      planId: $planId
    ) {
      subscription
    }
  }
`;
