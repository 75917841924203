import React from "react";
import Yoga from "../../assets/images/yogaclass.jpg";
import House from "../../assets/images/house.jpg";
import Events from "../../assets/images/events.jpg";
import ListingCard from "../../components/common/ListingCard";
import { Link } from "react-router-dom";

const cases1 = [
  "Comedy shows",
  "Parties",
  "Music shows",
  "Conferences",
  "Meetups",
  "Exhibitions",
];

const cases2 = [
  "Yoga sessions",
  "Coaching",
  "Professional training",
  "Consulting",
  "Freelancing",
  "Book Medical appointments",
];

const cases3 = [
  "Inspections for house",
  // "Inspections for cars",
  "Inspections for shops",
  "Inspections for co-working spaces",
  "Inspections for flats",
];

const StartCards: React.FC = () => {
  return (
    <section id="usage">
      <h1 className="pt-10 text-center text-[40px] font-extrabold text-secondary">
        Features
      </h1>
      <div className="flex items-center justify-center py-10">
        {/* <div className="grid grid-cols-1 gap-6 sm:grid-cols-6 md:grid-cols-3"> */}
        <div  >
          <ListingCard name="Events" bgImage={Events} list={cases1}>
            <Link className="text-gray-700" to="/event-management">
              See more
            </Link>
          </ListingCard>
          {/* <ListingCard
            comming={true}
            name="Appointments"
            bgImage={Yoga}
            list={cases2}
          >
            <Link className="pointer-events-none text-gray-700" to="#">
              See more
            </Link>
          </ListingCard>
          <ListingCard
            comming={true}
            name="Inspections"
            bgImage={House}
            list={cases3}
          >
            <Link className="text-gray-700" to="/inspection-management">
              See more
            </Link>
          </ListingCard> */}
        </div>
      </div>
    </section>
  );
};

export default StartCards;