import { gql } from "@apollo/client";

export const GET_ALL_SUBSCRIPTION_SUBSCRIBED = gql`
query Query($limit: Int!, $offset: Int!, $subscriptionId: Int, $userId: Int) {
  sub_getSubscriptionSubscribedList(limit: $limit, offset: $offset, subscriptionId: $subscriptionId, userId: $userId) {
    subscriptionPlanId
    userId
    stripeSubscriptionId
    userEmail
    phoneNumber
    planId
    amountPaid
    subscribedAt
    endAt
    title
    description
    cost
    costNZ
    frequency
    stripe_product_name
    userCanceledAt
    status
  }
}` 