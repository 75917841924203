import React, { InputHTMLAttributes } from "react";

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {}

const UIInput: React.FC<InputProps> = ({ className = "", ...rest }) => {
  return (
    <input
      className={
        "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full form-input " +
        className
      }
      {...rest}
    />
  );
};

export default UIInput;
