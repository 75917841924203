import React from "react";
import heroImage from "../../assets/images/eventManagement/heroEventManagement.png"
import whatWeOffer from "../../assets/images/eventManagement/WhatWeOffer.png";
import whatAttendeesEnjoy from "../../assets/images/eventManagement/WhatAttendeesEnjoy.png";
import benefits from "../../assets/images/eventManagement/benefits.png";
import webIntegration from "../../assets/images/eventManagement/webIntegration.png";
import { Link } from "react-router-dom";

const EventManagement: React.FC = () => {
  const data = [
    {
      title: "What We Offer",
      content: [
        "Effortless Event Creation: Easily set up events with all the details in minutes.",
        "Free Ticketing & Collect Payments: No transaction fees for creating or managing events, ticket booking, or payments. Easily integrate your Stripe payment account. Monthly or yearly payment plan available for organizer.",
        "Seamless Attendee Management: Track registrations, communicate with attendees, and manage attendance effectively.",
        "Automated Notifications: Keep attendees informed with automatic event updates and reminders.",
      ],
      url: whatWeOffer,
      alt: "our offer"
    },
    {
      title: "What Attendees Enjoy",
      content: [
        "Simple Ticket Booking: Find and book tickets for events quickly and easily.",
        "Multiple Ticket Options: Choose from paper tickets, email delivery, or convenient QR codes.",
        "Easy Access: Access event information and tickets anytime, anywhere.",
      ],
      url: whatAttendeesEnjoy,
      alt: "customer enjoying"
    },
    {
      title: "Benefits for Everyone",
      content: [
        "Streamlined Workflow: Save time and resources by managing everything in one place.",
        "Increased Attendance: Make event booking and access effortless for attendees.",
        "Reduced Costs: Avoid unnecessary transaction charges.",
        "Enhanced Experience: Create memorable events for both organizers and attendees.",
      ],
      url: benefits,
      alt: "benefits"
    },
    {
      title: "Easily Integrate with Existing Websites",
      content: [
        "Enhanced User Experience: Keep your audience engaged on your website, minimizing disruptions and maximizing convenience.",
        "Increased Brand Consistency: Maintain your website's branding and visual identity throughout the event booking process.",
        "Improved Conversion Rates: Streamline the ticket booking journey, encouraging higher participation in your events.",
        "Reduced Platform Switching: Eliminate the need for users to switch between platforms, fostering a smooth and enjoyable experience.",
      ],
      url: webIntegration,
      alt: "integrating into your website"
    },
  ];

  const subtitles = data.map((element) =>
    element.content.map((item) => item.split(": ")[0]),
  );
  return (
    <div>
      <div className="bg-secondary">
        <div className="mx-auto mb-4 flex flex-col px-2 py-2 md:max-w-[1000px] md:flex-row md:items-center md:py-6">
          <div className="md:w-3/5">
            <h1 className="mt-4 text-center text-xl font-bold text-white md:text-left md:text-4xl">
              Simplify Your{" "}
              <span className="text-primary">Event Management</span> with
              bookable Online!
            </h1>
            <p className="my-8 px-2 md:px-0 text-sm text-white md:text-lg">
              Tired of juggling multiple platforms for your events? We offer an
              all-in-one solution for event organizers and attendees alike.
            </p>
          </div>
          <img
            src={heroImage}
            alt="event-management"
            className="mx-auto h-auto w-48 md:w-1/3"
          />
        </div>
      </div>

      <div className="px-2 mx-auto sm:max-w-[1000px]">
        {data.map((item, index) => (
          <section
            key={index}
            className={`my-12 flex flex-col items-center ${index % 2 === 0 ? "md:flex-row" : "md:flex-row-reverse"}`}
          >
            <div className="md:w-1/3">
              <div
                className="h-auto w-48 md:w-64 "
                style={{
                  boxShadow:
                    "rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em",
                }}
              ></div>
              <img src={item.url} loading="lazy" alt={item.alt} className="h-auto w-48 md:w-64 mb-2" />
            </div>
            <div className="md:w-2/3">
              <p className="text-md mb-2 px-2 md:px-0 font-bold text-primary sm:text-3xl">
                {item.title}
              </p>
              {item.content.map((contentItem, contentIndex) => (
                <div key={contentIndex} className="mb-2 px-2 md:px-0 ">
                  <span className="text-sm text-xs font-bold leading-6 text-gray-800 md:text-lg">
                    {subtitles[index][contentIndex]}:
                  </span>
                  &nbsp;
                  <span className="text-sm leading-6 text-gray-700 md:text-lg">
                    {contentItem.split(": ")[1]}
                  </span>
                </div>
              ))}
            </div>
          </section>
        ))}
      </div>
      <div className="text-center text-xs text-gray-700 md:text-lg">
        <p className="mt-8">Ready to simplify your event management</p>
        <p>
          <Link
            to="/signup"
              className="text-primary underline transition duration-150 ease-in-out font-bold"
          >
            Sign up
          </Link>{" "}
          for bookable online today and experience the difference!
        </p>
      </div>
    </div>
  );
};

export default EventManagement;
