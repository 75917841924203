import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { CREATE_USER_MUTATION } from "../../api/auth";
import { userSchema } from "../../validations/UserValidation";
import UIInputWrapper from "../ui/InputWrapper";
import UILabel from "../ui/Label";
import UIInput from "../ui/Input";
import UIButton from "../ui/Button";
import { getCountryCode } from "../../helpers/get-country-code";

interface ISignUp {
  navigationPath?: string;
  setSignUpModal?: React.Dispatch<React.SetStateAction<boolean>>;
}

const SignUp: React.FC<ISignUp> = ({ navigationPath, setSignUpModal }) => {
  const dynCountryCode = getCountryCode();
  const navigate = useNavigate();
  const [countryCode, setCountryCode] = useState("");

  const [addUser] = useMutation(CREATE_USER_MUTATION);
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: {
        name: "",
        userName: "",
        email: "",
        phoneNumber: "",
        password: "",
        confirm: "",
        inspectionApiAccess: true,
      },
      validationSchema: userSchema,
      validateOnBlur: true,
      onSubmit: async (values, action) => {
        const { confirm: _, ...submitVals } = values;

        if (countryCode && values.phoneNumber.length >= 8) {
          submitVals.phoneNumber = countryCode + values.phoneNumber;
        }
        try {
          const res = await addUser({ variables: submitVals });
          if (res && res.data.userRegistration) {
            const successMessage = navigationPath
              ? "Registration successful. Please verify email."
              : "Registration successful.";
            toast.success(successMessage);
            if (navigationPath) {
              navigate(navigationPath);
            } else {
              setSignUpModal && setSignUpModal(false);
            }
          } else {
            toast.error("Unable to register.");
          }
        } catch (e: any) {
          const msg = e.message.toString();
          if (msg.includes("Duplicate") && msg.includes("user.email")) {
            toast.error("Email already in use.");
          } else if (
            msg.includes("Duplicate") &&
            msg.includes("user.userName")
          ) {
            toast.error("User name already in use.");
          } else {
            toast.error("Unable to register.");
          }
          console.error(e.message);
        }
        action.resetForm();
      },
    });

  const handleCountryCodeChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    setCountryCode(event.target.value);
  };

  useEffect(() => {
    if (dynCountryCode) {
      setCountryCode(dynCountryCode);
    }
  }, [dynCountryCode]);

  return (
    <form onSubmit={handleSubmit}>
      <UIInputWrapper>
        <UILabel htmlFor="name">
          Name <span className="text-red-600">*</span>
        </UILabel>
        <UIInput
          id="name"
          type="text"
          placeholder="Enter your name"
          name="name"
          required
          onChange={handleChange}
          value={values.name}
          onBlur={handleBlur}
        />
        {errors.name && touched.name && (
          <span className="block text-sm text-red-600">{errors.name}</span>
        )}
      </UIInputWrapper>

      <UIInputWrapper>
        <UILabel htmlFor="userName">
          User name <span className="text-red-600">*</span>
        </UILabel>
        <UIInput
          id="userName"
          type="text"
          placeholder="Enter your user name"
          name="userName"
          required
          onChange={handleChange}
          value={values.userName}
          onBlur={handleBlur}
        />
        {errors.userName && touched.userName && (
          <span className="block text-sm text-red-600">{errors.userName}</span>
        )}
      </UIInputWrapper>

      <UIInputWrapper>
        <UILabel htmlFor="email">
          Email <span className="text-red-600">*</span>
        </UILabel>
        <UIInput
          id="email"
          type="email"
          className="form-input w-full text-gray-800"
          placeholder="Enter your email address"
          name="email"
          required
          onChange={handleChange}
          value={values.email}
          onBlur={handleBlur}
        />
        {errors.email && touched.email && (
          <span className="block text-sm text-red-600">{errors.email}</span>
        )}
      </UIInputWrapper>
      <UILabel htmlFor="phoneNumber">
        Phone number <span className="text-red-600">*</span>
      </UILabel>
      <UIInputWrapper>
        <div className="flex">
          <select
            id="countryCode"
            name="countryCode"
            className="form-select h-12 w-1/3 rounded-lg  bg-gray-50  p-1 text-sm text-gray-800"
            onChange={handleCountryCodeChange}
            value={countryCode}
            required
          >
            <option value="" disabled>
              Country code
            </option>
            <option value="64">+64 (New Zealand)</option>
            <option value="91">+91 (India)</option>
          </select>

          <div style={{ alignContent: "center", alignItems: "center" }}>
            {" "}
            <UIInput
              id="phoneNumber"
              type="text"
              className="form-input ml-1  w-[20em] pl-2 text-gray-800"
              placeholder="Enter your Phone Number"
              name="phoneNumber"
              required
              onChange={handleChange}
              value={values.phoneNumber}
              onBlur={handleBlur}
              minLength={dynCountryCode === "91" ? 10 : 8}
              maxLength={10}
            />{" "}
            {errors.phoneNumber && touched.phoneNumber && (
              <span className="block text-sm text-red-600">
                {errors.phoneNumber}
              </span>
            )}
          </div>
        </div>
      </UIInputWrapper>

      <UIInputWrapper>
        <UILabel htmlFor="password">
          Password <span className="text-red-600">*</span>
        </UILabel>
        <UIInput
          id="password"
          type="password"
          className="form-input w-full text-gray-800"
          placeholder="Enter your password"
          name="password"
          required
          onChange={handleChange}
          value={values.password}
          autoComplete="off"
          onBlur={handleBlur}
        />
        {errors.password && touched.password && (
          <span className="block text-sm text-red-600">{errors.password}</span>
        )}
      </UIInputWrapper>

      <UIInputWrapper>
        <UILabel htmlFor="confirm">
          Confirm password <span className="text-red-600">*</span>
        </UILabel>
        <UIInput
          id="confirm"
          type="password"
          className="form-input w-full text-gray-800"
          placeholder="Confirm your password"
          name="confirm"
          required
          onChange={handleChange}
          value={values.confirm}
          autoComplete="off"
          onBlur={handleBlur}
        />
        {errors.confirm && touched.confirm && (
          <span className="block text-sm text-red-600">{errors.confirm}</span>
        )}
      </UIInputWrapper>

      <UIInputWrapper>
        <UIButton type="submit">Sign up</UIButton>
      </UIInputWrapper>

      <div className="mt-3 text-center text-sm text-gray-500">
        By creating an account, you agree to the{" "}
        <Link className="underline" to="/terms-of-service">
          terms of service
        </Link>{" "}
        and our{" "}
        <Link className="underline" to="/privacy-policy">
          privacy policy
        </Link>
        .
      </div>
    </form>
  );
};

export default SignUp;
