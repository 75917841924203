import { host } from "./site-info";

interface HostCountry {
  domain: string;
  countryCode: string;
}

const countryCodes: HostCountry[] = [
  { domain: "bookable.co.in", countryCode: "91" },
  { domain: "bookableonline.co.nz", countryCode: "64" },
  { domain: "localhost:3000", countryCode: "91" },
  { domain: "localhost:3001", countryCode: "64" },
];

export const getCountryCode = (): string | null => {
  const match = countryCodes.find((entry) => entry.domain === host);
  return match?.countryCode || null;
};
