/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import {
  ClipboardDocumentListIcon,
  HomeIcon,
  ArrowLongRightIcon,
} from "@heroicons/react/20/solid";
import { ClockIcon, CurrencyDollarIcon } from "@heroicons/react/24/outline";
import { EVENT_GET_EVENT_IMAGES_URL } from "../../api/getUserEventImage";
import { USER_EVENT_PUBLISH } from "../../api/Event/UserEventPublish";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { splitTitleToLink } from "../../helpers/links";
import { SubscriptionListing } from "../../types/SubscriptionListing";
import { GET_ALL_SUBSCRIPTION_SUBSCRIBED } from "../../api/Payment/getAllSubscriptionSubscribed";
import { fullDomain } from "../../helpers/site-info";
import EventImageCarousel from "../EventImageCarousel";

const Card: React.FC<any> = ({
  title,
  startDate,
  duration,
  paid,
  type,
  eventID,
  organizer,
  publishStatus,
  userPlanId,
  imageUrls,
  summary,
}) => {
  const navigate = useNavigate();
  const navigateToListing = () => {
    navigate(`/customer-listing/${eventID}`, { state: { organizer, title } });
  };
  const titleNavLinkString = splitTitleToLink(title);
  const encodedEventId = btoa(eventID || "");

  // const { error, data } = useQuery(EVENT_GET_EVENT_IMAGES_URL, {
  //   variables: { eventid: eventID },
  // });
  const [publishEvent] = useMutation(USER_EVENT_PUBLISH);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [published, setPublished] = useState(false);

  // if (error) return <p>Error: {error.message}</p>;

  const handleCopyClick = async () => {
    try {
      await navigator.clipboard.writeText(
        `<iframe src="${fullDomain}/embedded-event/${titleNavLinkString}/${encodedEventId}" title="events" class="event"></iframe>`,
      );
      toast.success("Event URL copied to clipboard!", {
        position: "top-center",
        autoClose: 2000,
      });
    } catch (err) {
      toast.error("Error copying event URL", {
        position: "top-center",
        autoClose: 2000,
      });
    }
  };
  const handlePublishClick = async () => {
    const userid = localStorage.getItem("userId");
    if (!userid) {
      return;
    }

    try {
      const publishData = await publishEvent({
        variables: {
          userid: Number(userid),
          eventid: Number(eventID),
          planId: userPlanId?.length > 0 ? userPlanId[0] : 1,
          status: true,
        },
      });

      if (
        publishData.data.event_updateEventPublishStatus ===
        "Successfully updated"
      ) {
        toast.success("Event published successfully!", {
          position: "top-center",
          autoClose: 2000,
        });
        setPublished(true);
      } else {
        toast.error("Error while publishing the event", {
          position: "top-center",
          autoClose: 2000,
        });
      }
    } catch (err) {
      if (
        (err as Error).message ===
        "User has exceeded the maximum allowed events for their subscription plan."
      ) {
        toast.error(
          "Your trial has been completed. Please buy a subscription plan.",
        );
      } else {
        toast.error("Error while publishing the event", {
          position: "top-center",
          autoClose: 2000,
        });
        console.error(err);
      }
    }
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleOptionClick = (option: string) => {
    closeModal();
    if (option === "iframe") {
      handleCopyClick();
    } else if (option === "button") {
      try {
        const buttonHtml = `
<div class="bookableWrapper">
  <button id="bookableBtn" class="bookableBtnClass">Book Event</button>
  <div id="bookableEventModal" class="bookableModal">
    <div class="bookableModal-content">
      <span class="bookableClose">&times;</span>
      <iframe
        id="iframeDialog"
        class="bookableIframeStyle"
        src="${fullDomain}/embedded-event/${titleNavLinkString}/${encodedEventId}"
        title="events"
      ></iframe>
    </div>
  </div>
</div>
`;

        navigator.clipboard.writeText(buttonHtml);

        toast.success("Button code copied to clipboard!", {
          position: "top-center",
          autoClose: 2000,
        });
      } catch (err) {
        toast.error("Error copying button code", {
          position: "top-center",
          autoClose: 2000,
        });
      }
    }
  };

  return (
    <>
      <div
        style={{ maxWidth: 320 }}
        className="overflow-hidden rounded shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)]"
      >
        <div className="relative">
          <EventImageCarousel images={imageUrls} />

          <div className="absolute bottom-0 left-0 right-0 top-0 bg-gray-900 opacity-25 transition duration-300 hover:bg-transparent"></div>
          <div className="absolute bottom-0 left-0 bg-primary px-4 py-2 text-xs text-white transition duration-500 ease-in-out ">
            {organizer}
          </div>

          <div className="absolute right-0 top-0 mr-3 mt-3 flex h-12 w-12 flex-col items-center justify-center rounded-full bg-primary p-0  text-xs text-white transition duration-500 ease-in-out ">
            <span className="text-center font-bold">{startDate}</span>
          </div>
        </div>

        <div className="px-3 py-2">
          {Boolean(publishStatus) && (
            <>
              <div className="flex  flex-wrap	 justify-between px-0">
                <span className=" mr-1 flex flex-row items-center py-1  text-gray-700">
                  <ClockIcon className="w-4" />
                  <span className="ml-1 text-sm">{duration}</span>
                </span>
                <span className=" mr-1 flex flex-row items-center py-1  text-gray-700">
                  <CurrencyDollarIcon className="w-4" />
                  <span className="ml-1 text-sm">{paid ? "Paid" : "Free"}</span>
                </span>{" "}
                <span className=" mr-1 flex flex-row items-center py-1  text-gray-700">
                  <HomeIcon className="w-4" />
                  <span className="ml-1 text-sm">{type}</span>
                </span>
              </div>
              <div className="mt-3 flex  justify-between">
                {" "}
                <Link
                  to={`/event/${titleNavLinkString}/${encodedEventId}`}
                  className="inline-block text-lg font-semibold text-gray-900 transition duration-500 ease-in-out"
                >
                  {title.length > 55 ? `${title.substring(0, 55)}...` : title}
                </Link>
                <span
                  className=" cursor-pointer text-gray-500 hover:text-gray-600"
                  onClick={openModal}
                >
                  <ClipboardDocumentListIcon className="mt-1 w-6" />
                </span>
              </div>
            </>
          )}
          <p className="text-sm text-gray-500">
            {summary.length > 150 ? `${summary.substring(0, 150)}...` : summary}
          </p>
        </div>

        <div className="flex flex-row  justify-between  py-3">
          {Boolean(publishStatus) && (
            <button className="flex flex-row items-center px-3 py-1 text-gray-900 hover:text-primary ">
              <span
                className="ml-1 cursor-pointer text-sm underline"
                onClick={navigateToListing}
              >
                See Attendees
              </span>
              &nbsp;
              <ArrowLongRightIcon className="w-4" />
            </button>
          )}

          {!publishStatus && (
            <button
              onClick={handlePublishClick}
              className={`text-md m-auto justify-center rounded-md border-2 border-primary bg-transparent px-10 pb-0 pt-1 font-thin text-primary transition duration-300 ease-in-out hover:border-primary hover:bg-primary hover:text-white ${
                published && " pointer-events-none"
              }`}
            >
              {published ? "Published" : "Publish"}
            </button>
          )}
        </div>
      </div>
      {isModalOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center">
          <div
            className="absolute inset-0 bg-black opacity-50"
            onClick={closeModal}
          ></div>
          <div className="z-10 w-full max-w-md rounded-md bg-white p-5 shadow-lg">
            <h2 className="mb-2  text-2xl font-bold  text-gray-800">
              Select event functionality options:
            </h2>
            <p className="mb-6 text-sm text-gray-600">
              Choose the preferred method to copy content to your clipboard. Opt
              for an iframe or a button based on your convenience:
            </p>
            <div className="flex justify-between">
              <button
                className="focus:shadow-outline-secondary text-md mr-2 rounded-md border border-secondary bg-transparent px-6  py-2  text-secondary transition duration-300 ease-in-out hover:border-secondary hover:bg-secondary hover:text-white focus:border-secondary focus:outline-none"
                onClick={() => handleOptionClick("iframe")}
              >
                Copy as Iframe
              </button>
              <button
                className="focus:shadow-outline-secondary text-md rounded-md border border-secondary bg-transparent px-6  py-2  text-secondary transition duration-300 ease-in-out hover:border-secondary hover:bg-secondary hover:text-white focus:border-secondary focus:outline-none"
                onClick={() => handleOptionClick("button")}
              >
                Copy as Button
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default Card;
