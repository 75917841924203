import { gql } from "@apollo/client";

export const GET_ALL_EVENTS = gql`
  query Event_getAllEvent(
    $publishStatus: Boolean!
    $limit: Int!
    $offset: Int!
  ) {
    event_getAllEvent(
      publishStatus: $publishStatus
      limit: $limit
      offset: $offset
    ) {
      id
      title
      description
      organizer
      user_id
      category
      address
      eventPattern
      timeZone
      language
      summary
      free
      paid
      publish
      startDate
      endDate
      ticketSaleStartDate
      ticketSaleEndDate
      totalTicketInFreeEvent
      createdAt
      updatedAt
      user {
        id
        name
        email
        username
      }
      organizerSection
      refundPolicy
      location
      imageUrls {
        id
        url
      }
      recurringType {
        id
        type
        end_time
        start_time
      }
      monthlyRecurring {
        id
        date
      }
      singleDay {
        id
        end_time
        start_time
      }
      weeklyRecurring {
        id
        daysOfWeek
      }
      pricingDetails {
        id
        label
        price
        priceId
        currency
        productId
        description
        warningMsgOn
        ticketQuantity
      }
    }
  }
`;

export const GET_EVENT = gql`
  query Iframe_getEventByid($eventId: Int!) {
    iframe_getEventByid(eventId: $eventId) {
      id
      title
      description
      organizer
      user_id
      category
      address
      eventPattern
      timeZone
      language
      summary
      free
      paid
      publish
      startDate
      endDate
      ticketSaleStartDate
      ticketSaleEndDate
      totalTicketInFreeEvent
      createdAt
      updatedAt
      user {
        id
        name
        email
        username
      }
      organizerSection
      refundPolicy
      location
      imageUrls {
        id
        url
      }
      recurringType {
        id
        type
        end_time
        start_time
      }
      monthlyRecurring {
        id
        date
      }
      singleDay {
        id
        end_time
        start_time
      }
      weeklyRecurring {
        id
        daysOfWeek
      }
      pricingDetails {
        id
        label
        price
        priceId
        currency
        productId
        description
        warningMsgOn
        ticketQuantity
      }
    }
  }
`;
