import React from "react";
import SignUp from "../../../components/common/SignUp";
import { Link } from "react-router-dom";

const SignUpPage: React.FC = () => {
  return (
    <section>
      <div className="mx-auto max-w-6xl px-4 sm:px-6">
        <div className="pb-12 md:pb-20 mt-4">
          <h1 className="mb-4 text-center text-4xl font-bold">Sign up</h1>
          {/* Form */}
          <div className="mx-auto max-w-sm">
            <SignUp navigationPath="/signin"/>
            <div className="mt-6 text-center text-gray-600">
              Already using bookable?{" "}
              <Link
                to="/signin"
                className="text-blue-600 transition duration-150 ease-in-out hover:underline"
              >
                Sign in
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default SignUpPage;
