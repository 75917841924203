/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Typography,
  IconButton,
  Carousel,
  Button,
} from "@material-tailwind/react";
import "quill/dist/quill.snow.css";
import FeatureCard from "../../ui/FeatureCard";
import {
  CalendarIcon,
  ClockIcon,
  MapPinIcon,
  CurrencyDollarIcon,
  CurrencyRupeeIcon,
  GlobeAltIcon,
} from "@heroicons/react/24/outline";
import moment from "moment-mini";
import { indianSite } from "../../../helpers/site-info";
import NoImageFound from "../../../assets/images/noEventImage.jpg";
import {
  IEventComponent,
  IEventImage,
  PricingDetail,
} from "../../../types/EventComponent";
import StaticMap from "./StaticMap";
import { formatEventDuration } from "../../../utils/formatEventDuration";
interface EventPattern {
  recurring: "SingleDay" | "WEEKLY";
  weekDays: string[];
}

interface PreviewData {
  startDate?: number;
  endDate?: number;
  startTime?: number;
  endTime?: number;
  eventPattern?: EventPattern;
  address?: string;
  location?: string;
}

const EventComponent: React.FC<IEventComponent> = ({
  eventImages,
  handleOpen,
  previewData,
  eventData,
  duration,
  formattedStartDate,
}) => {
  const [epochTime, setEpochTime] = useState<number | null>(null);

  useEffect(() => {
    if (formattedStartDate) {
      convertToEpoch();
    }
  }, [formattedStartDate]);

  const convertToEpoch = () => {
    const startDateMoment = moment(
      formattedStartDate,
      "MMMM DD, YYYY h:mm:ss A [GMT]Z",
    );
    const epochTime = startDateMoment.isValid()
      ? startDateMoment.valueOf()
      : null;
    setEpochTime(epochTime);
  };

  const startTime = previewData?.startTime;
  const endTime = previewData?.endTime;

  const formattedDuration = formatEventDuration(startTime, endTime);
  const currentMoment = moment();

  const previewStartDate = moment(Number(previewData?.startDate) * 1000);
  const previewEndDate = previewData?.endDate
    ? moment(Number(previewData?.endDate) * 1000)
    : null;
  const previewStartTime = moment(previewData?.startTime * 1000).format(
    "h:mm A",
  );

  const isRecurringWeekly =
    previewData?.eventPattern?.recurring === "WEEKLY" ||
    eventData?.recurringType?.type === "weekly";
  const isRecurringMonthly =
    previewData?.eventPattern?.recurring === "MONTHLY" ||
    eventData?.recurringType?.type === "monthly";
  const weekDays =
    previewData?.eventPattern?.weekDays ||
    eventData?.weeklyRecurring?.daysOfWeek ||
    [];
  const monthDate =
    previewData?.eventPattern?.monthDate || eventData?.monthlyRecurring?.date;

  function findNextWeeklyOccurrence(
    currentMoment: moment.Moment,
    startMoment: moment.Moment,
    weekDays: string[],
    endDate: moment.Moment | null,
  ): moment.Moment | null {
    let nextOccurrence: moment.Moment | null = null;

    weekDays.forEach((day) => {
      const dayOfWeek = moment().isoWeekday(day.toUpperCase());
      let candidateDate = startMoment
        .clone()
        .isoWeekday(dayOfWeek.isoWeekday());

      if (candidateDate.isBefore(currentMoment)) {
        candidateDate.add(1, "weeks");
      }

      if (endDate && candidateDate.isAfter(endDate)) {
        return;
      }

      if (nextOccurrence === null || candidateDate.isBefore(nextOccurrence)) {
        nextOccurrence = candidateDate;
      }
    });

    return nextOccurrence;
  }

  function findNextMonthlyOccurrence(
    currentMoment: moment.Moment,
    startMoment: moment.Moment,
    monthDate: number,
    endDate: moment.Moment | null,
  ): moment.Moment | null {
    let nextOccurrence = startMoment.clone().date(monthDate);

    if (nextOccurrence.isBefore(currentMoment)) {
      nextOccurrence.add(1, "months");
    }

    if (endDate && nextOccurrence.isAfter(endDate)) {
      return null;
    }

    return nextOccurrence;
  }

  let previewEventDescription: string | undefined;

  if (previewData?.eventPattern?.singleDay) {
    previewEventDescription = `${previewStartDate.format(
      "dddd, MMMM D, YYYY",
    )} ${previewStartTime}`;
  } else if (isRecurringWeekly) {
    const nextOccurrence = findNextWeeklyOccurrence(
      currentMoment,
      previewStartDate,
      weekDays,
      previewEndDate,
    );
    previewEventDescription = nextOccurrence
      ? `${nextOccurrence.format("dddd, MMMM D, YYYY")} ${previewStartTime}`
      : "Next occurrence not available";
  } else if (isRecurringMonthly && monthDate) {
    const nextOccurrence = findNextMonthlyOccurrence(
      currentMoment,
      previewStartDate,
      monthDate,
      previewEndDate,
    );
    previewEventDescription = nextOccurrence
      ? `${nextOccurrence.format("dddd, MMMM D, YYYY")} ${previewStartTime}`
      : "Next occurrence not available";
  }

  const defaultFeatures = [
    {
      title: "Date and Time",
      icon: CalendarIcon,
      description: previewEventDescription,
    },

    {
      title: "Duration",
      icon: ClockIcon,
      description: formattedDuration,
    },
  ];

  const { REACT_APP_EVENT_IMAGE } = process.env;

  const liveEventStartTime = Number(
    eventData?.singleDay?.start_time || eventData?.recurringType?.start_time,
  );
  const liveEventEndTime = Number(
    eventData?.singleDay?.end_time || eventData?.recurringType?.end_time,
  );
  const eventFormattedDuration = formatEventDuration(
    liveEventStartTime,
    liveEventEndTime,
  );

  const eventStartDate = moment(Number(eventData?.startDate) * 1000);
  const eventEndDate = eventData?.endDate
    ? moment(Number(eventData?.endDate) * 1000)
    : null;
  const eventStartTime = moment(
    eventData?.recurringType?.start_time * 1000 ||
      eventData?.singleDay?.start_time * 1000,
  ).format("h:mm A");

  let eventDescription: string | undefined;
  if (eventData?.eventPattern?.singleDay || eventData?.singleDay) {
    eventDescription = `${eventStartDate.format(
      "dddd, MMMM D, YYYY",
    )} ${eventStartTime}`;
  } else if (isRecurringWeekly) {
    const nextOccurrence = findNextWeeklyOccurrence(
      currentMoment,
      eventStartDate,
      weekDays,
      eventEndDate,
    );
    eventDescription = nextOccurrence
      ? `${nextOccurrence.format("dddd, MMMM D, YYYY")} ${eventStartTime}`
      : "Next occurrence not available";
  } else if (isRecurringMonthly && monthDate) {
    const nextOccurrence = findNextMonthlyOccurrence(
      currentMoment,
      eventStartDate,
      monthDate,
      eventEndDate,
    );
    eventDescription = nextOccurrence
      ? `${nextOccurrence.format("dddd, MMMM D, YYYY")} ${eventStartTime}`
      : "Next occurrence not available";
  }


  const eventFeatures = [
    {
      title: "Date and Time",
      icon: CalendarIcon,
      description: eventDescription,
    },
    {
      title: "Duration",
      icon: ClockIcon,
      description: eventFormattedDuration,
    },
  ];
  const soldOut =
    !eventData?.paid &&
    eventData?.totalTicketInFreeEvent &&
    eventData?.totalTicketInFreeEvent <= 0;
  const uploadedImages = previewData?.uploadedImages || [];

  const imageUrls = uploadedImages
    .map((file: File | string) => {
      if (file instanceof File) {
        return URL.createObjectURL(file);
      } else {
        return null;
      }
    })
    .filter(Boolean);

  const leastPrice = eventData?.pricingDetails?.reduce(
    (minPrice: number, currentItem: PricingDetail) => {
      return Math.min(minPrice, currentItem.price);
    },
    eventData?.pricingDetails?.[0]?.price ?? 0,
  );

  return (
    <>
      <div className="container relative mx-auto my-2 max-w-6xl">
        <div className="relative mx-auto aspect-video max-h-[600px]">
          <Carousel
            className="aspect-video overflow-hidden rounded-xl"
            navigation={({ setActiveIndex, activeIndex, length }) => (
              <div className="absolute bottom-4 left-2/4 z-50 flex -translate-x-2/4 gap-2">
                {new Array(length).fill("").map((_, i) => (
                  <span
                    key={i}
                    className={`block h-1 cursor-pointer rounded-2xl transition-all content-[''] ${
                      activeIndex === i ? "w-8 bg-white" : "w-4 bg-white/50"
                    }`}
                    onClick={() => setActiveIndex(i)}
                  />
                ))}
              </div>
            )}
          >
            {!previewData &&
              eventImages &&
              eventImages?.map((image: IEventImage) => (
                <img
                  key={image.id}
                  src={`${REACT_APP_EVENT_IMAGE}/${image.url}`}
                  alt={`image ${image.id + 1}`}
                  className="aspect-video w-full object-cover"
                  onError={(e) => {
                    e.currentTarget.onerror = null;
                    e.currentTarget.src =
                      "https://img.freepik.com/free-vector/flat-design-no-photo-sign_23-2149272417.jpg?w=740&t=st=1705735041~exp=1705735641~hmac=7f0dbe96496657ecd3156c10388d2a11771fbed93e8b715f3d72cf2f221526e0";
                  }}
                />
              ))}

            {/* {(previewData && previewData?.uploadedImages?.length === 0) ||
              (!eventImages && (
                <img
                  src={NoImageFound}
                  className="aspect-video w-full object-cover opacity-40"
                  loading="lazy"
                  alt="event-image"
                />
              ))} */}

            {previewData && previewData?.uploadedImages?.length === 0 && (
              <img
                src={NoImageFound}
                className="aspect-video w-full object-cover opacity-40"
                loading="lazy"
                alt="event-image"
              />
            )}
            {(eventImages && eventImages?.length === 0) ||
              (!eventImages && (
                <img
                  src={NoImageFound}
                  className="aspect-video w-full object-cover opacity-40"
                  loading="lazy"
                  alt="event-image"
                />
              ))}
            {imageUrls?.map((imageUrl, index) => (
              <img
                key={index}
                src={imageUrl ?? "fallback-image-url"}
                alt={`image ${index + 1}`}
                className="aspect-video w-full object-cover"
              />
            ))}
          </Carousel>
          <div className="col-span-6 mt-4 flex justify-center md:-mt-20 md:mr-10 md:justify-end">
            <Card className="min-w-[150px] rounded-lg border text-center shadow-lg shadow-gray-500/10">
              <CardBody className="p-3 ">
                <IconButton
                  variant="gradient"
                  size="lg"
                  color={"green"}
                  className="pointer-events-none mb-3 rounded-full"
                >
                  {indianSite ? (
                    <CurrencyRupeeIcon className="h-5 w-5 text-white" />
                  ) : (
                    <CurrencyDollarIcon className="h-5 w-5 text-white" />
                  )}
                </IconButton>
                <Typography
                  variant="small"
                  color="blue-gray"
                  className="font-normal"
                >
                  Tickets
                </Typography>
                <Typography
                  variant="h6"
                  color="blue-gray"
                  className="mb-3 mt-2 font-bold"
                >
                  {Boolean(eventData?.paid) && (
                    <>
                      From{" "}
                      {leastPrice !== undefined
                        ? `${leastPrice}`
                        : "Price unavailable"}
                    </>
                  )}{" "}
                  {previewData?.perTicketCharges ||
                    eventData?.perTicketCharges ||
                    previewData?.free ||
                    (Boolean(eventData?.free) && "Free")}
                </Typography>
                <div></div>

                {!previewData && (
                  <Button
                    className="bg-primary"
                    disabled={Boolean(soldOut) || soldOut === 0}
                    onClick={handleOpen}
                  >
                    {Boolean(soldOut) || soldOut === 0 ? (
                      "Sold Out"
                    ) : (
                      <>{eventData?.paid ? "Buy Ticket" : "Get Ticket"}</>
                    )}
                  </Button>
                )}
              </CardBody>
            </Card>
          </div>
        </div>
        <div className="my-6 w-full px-4">
          <Typography variant="h1" className="mb-6 font-black text-secondary">
            {previewData?.title ||
              eventData?.title ||
              "Event Title Unavailable"}
          </Typography>
          <div
            className="text-secondary opacity-80"
            dangerouslySetInnerHTML={{
              __html:
                previewData?.description ||
                eventData?.description ||
                "Event Description Unavailable!",
            }}
          />
        </div>
      </div>
      <div className="container mx-auto my-10 max-w-6xl">
        <div
          className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-2"
          style={{ justifyContent: "center", width: "70%", margin: "auto" }}
        >
          {!previewData &&
            eventFeatures?.map(({ title, icon, description }) => (
              <FeatureCard
                key={title}
                color={"blue"}
                title={title}
                icon={React.createElement(icon, {
                  className: "w-5 h-5 text-white",
                })}
                description={description}
              />
            ))}
          {!eventData &&
            defaultFeatures.map(({ title, icon, description }) => (
              <FeatureCard
                key={title}
                color={"blue"}
                title={title}
                icon={React.createElement(icon, {
                  className: "w-5 h-5 text-white",
                })}
                description={description}
              />
            ))}
        </div>
        <div className="mt-16 flex flex-wrap items-start">
          <div className="mx-auto w-full px-4 md:w-7/12">
            <div className="mb-6 inline-flex   h-12 w-12 items-center justify-center rounded-full bg-secondary p-0 text-center shadow-lg">
              <GlobeAltIcon className="h-6 w-6 text-white " />
            </div>
            <Typography
              variant="h4"
              className="mb-3 font-bold"
              color="blue-gray"
            >
              About this event
            </Typography>
            <Typography className="mb-8 font-normal text-blue-gray-500">
              {previewData?.summary ||
                eventData?.summary ||
                "Summary not Available"}
            </Typography>
            <div>
              <div className="relative mb-4 flex h-12 w-12 items-center justify-center rounded-full bg-secondary text-white">
                <h2 className="h4">
                  {(previewData?.organizer &&
                    previewData?.organizer[0].toUpperCase()) ||
                    (eventData?.organizer &&
                      eventData?.organizer[0].toUpperCase())}
                </h2>
              </div>
              <Typography variant="h6" className="font-normal">
                Organized by{" "}
              </Typography>
              <Typography
                variant="h4"
                className="mb-8 font-normal"
                color="blue-gray"
              >
                <b className="text-2xl text-blue-gray-800">
                  {" "}
                  {previewData?.organizer ||
                    eventData?.organizer ||
                    "Organizer Not Available"}
                </b>
              </Typography>
              <p className="mb-8 font-normal leading-5 text-blue-gray-500 ">
                {" "}
                {previewData?.orgnizerSection || eventData?.orgnizerSection}
              </p>
            </div>
            {(previewData?.refundPolicy || eventData?.refundPolicy) && (
              <div>
                <Typography
                  variant="h4"
                  className="mb-3 font-bold"
                  color="blue-gray"
                >
                  Refund Policy
                </Typography>
                <Typography className="mb-8 font-normal text-blue-gray-500">
                  {previewData?.refundPolicy || eventData?.refundPolicy}
                </Typography>
              </div>
            )}
          </div>

          <div className="mx-auto mt-8 flex w-full justify-center px-4 md:w-5/12 lg:mt-0">
            <Card className="min-w-[80%] rounded-lg border shadow-lg shadow-gray-500/10">
              <CardBody>
                <div className="relative mb-4 flex">
                  {
                    <IconButton
                      variant="gradient"
                      size="lg"
                      color={"blue"}
                      className="pointer-events-none rounded-full"
                    >
                      <MapPinIcon className="h-6 w-6" />
                    </IconButton>
                  }
                </div>
                <Typography
                  variant="h4"
                  color="blue-gray"
                  className="mb-3 font-bold"
                >
                  Location
                </Typography>
                <Typography color="blue-gray" className="mb-3 mt-2">
                  <h4 className="text-lg font-bold  leading-6">
                    {" "}
                    {previewData?.location ||
                      eventData?.location ||
                      "location Not Available"}
                  </h4>
                  <StaticMap
                    address={previewData?.location || eventData?.location}
                  />
                </Typography>
              </CardBody>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};

export default EventComponent;
