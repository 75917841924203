import React, { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { GET_ALL_EVENTS } from "../../api/get-event";
import Event from "../../types/Events";
import { convertEpochToReadableDate } from "../../helpers/date-helpers";
import EventCard from "./EventCard";
import UIInputWrapper from "../../components/ui/InputWrapper";
import UIButton from "../../components/ui/Button";
import LoadingSpinner from "../../components/common/LoadingSpinner/LoadingSpinner";
import { formatEventDuration } from "../../utils/formatEventDuration";

const Events: React.FC = () => {
  const [events, setEvents] = useState<Event[] | never>([]);
  const [moreLoading, setMoreLoading] = useState(false);
  const [moreErrorMsg, setMoreErrorMsg] = useState("");
  const [getData] = useLazyQuery(GET_ALL_EVENTS);

  const handleLoadMore = async () => {
    fetchEvents();
  };

  const fetchEvents = async () => {
    setMoreLoading(true);
    const { data, error } = await getData({
      variables: {
        publishStatus: true,
        limit: 6,
        offset: events.length,
      },
    });

    if (error) {
      setMoreErrorMsg("No more events found");
      setMoreLoading(false);
    } else if (data?.event_getAllEvent && data?.event_getAllEvent.length > 0) {
      setEvents([...events, ...data?.event_getAllEvent]);
      setMoreLoading(false);
    } else {
      setMoreErrorMsg("No events found");
      setMoreLoading(false);
    }
  };

  useEffect(() => {
    fetchEvents();
  }, []);
  
  

  return (
    <section className="relative mt-2 min-h-[200px] bg-white px-4 pt-12 md:pt-0">
      <div className="container mx-auto">
        <h2 className="h2 my-5 text-center">All Events</h2>
        <div className="flex h-full flex-col items-center justify-center">
          {events.length > 0 && (
            <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 md:grid-cols-3">
              {events?.map((event: any) => {
                const startTime =
                  event.recurringType?.start_time || event.singleDay.start_time;
                const endTime =
                  event.recurringType?.end_time || event.singleDay.end_time;
                return (
                  <EventCard
                    key={event.id}
                    id={event.id}
                    title={event.title}
                    description={event.description}
                    summary={event.summary}
                    address={event.address}
                    type={event.type}
                    paid={event.paid}
                    organizer={event.organizer}
                    startDate={convertEpochToReadableDate(event?.startDate)}
                    warningMsgOnTicketLeft={event.warningMsgOnTicketLeft}
                    duration={formatEventDuration(startTime, endTime)}
                    imageUrls={event?.imageUrls}
                  />
                );
              })}
            </div>
          )}
          {!moreLoading ? (
            <>
              {moreErrorMsg.length > 0 ? (
                <div className="mt-8 max-w-screen-md text-center text-xl font-medium text-gray-700">
                  {events.length === 0 ? (
                    <>Oops! It seems there are no events.</>
                  ) : (
                    <>{moreErrorMsg}</>
                  )}
                </div>
              ) : (
                <UIInputWrapper className="mt-8 max-w-xs md:w-full">
                  <UIButton onClick={handleLoadMore}>Load More</UIButton>
                </UIInputWrapper>
              )}
            </>
          ) : (
            <>
              <LoadingSpinner />
            </>
          )}
        </div>
      </div>
    </section>
  );
};

export default Events;
