import React from "react";
import HeroImg from "../../assets/images/hero.jpg";
import UILink from "../../components/ui/Link";
import ClockLogo from "../../assets/images/clocklogo.png"

const HeroLanding: React.FC = () => {
  return (
    <section
      style={{ backgroundImage: `url(${HeroImg})` }}
      className={`relative block bg-cover bg-center bg-no-repeat`}
    >
      <div className="absolute inset-0 bg-white/50"></div>
      {/* <div className="block md:hidden h-60 relative">
      <div className="absolute inset-0 bg-white/50"></div>
        <img src={HeroImg} alt="Hero Image" />
      </div> */}
      <div className="md:p-18 relative ml-auto mr-0 flex max-w-screen-sm justify-center bg-black/50 px-6 py-12 text-white md:mr-20 md:bg-secondary">
        <div className="max-w-lg text-center ltr:sm:text-left rtl:sm:text-right">
          <img
            className="h-72 w-full object-contain object-center my-4"
            src={ClockLogo}
            alt="Logo"
          />
          <h1 className="text-3xl font-extrabold sm:text-5xl">
            book
            <strong className="inline-block font-extrabold text-primary">
              able
            </strong>
          </h1>

          <h4 className="h4 mt-4">
            Your all-in-one platform for the seamless management of events.
          </h4>

          <div className="mt-8 flex flex-wrap items-center justify-center gap-4 text-center">
            <UILink to={"/pricing"} className="block sm:w-auto">
              Get Started
            </UILink>
            <UILink
              to={"signin"}
              className="block bg-white text-primary sm:w-auto"
            >
              Sign In
            </UILink>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroLanding;
