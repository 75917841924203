import { gql } from "@apollo/client";

export const EVENT_GET_EVENT_BY_USER_ID = gql`
  query Event_getEventByUserId(
    $userid: Int!
    $publishStatus: Boolean!
    $expire: Boolean!
    $limit: Int!
    $offset: Int!
  ) {
    event_getEventByUserId(
      userid: $userid
      publishStatus: $publishStatus
      expire: $expire
      limit: $limit
      offset: $offset
    ) {
      id
      title
      description
      organizer
      user_id
      category
      address
      eventPattern
      timeZone
      language
      summary
      free
      paid
      publish
      startDate
      endDate
      ticketSaleStartDate
      ticketSaleEndDate
      totalTicketInFreeEvent
      createdAt
      updatedAt
      user {
        id
        name
        email
        username
      }
      organizerSection
      refundPolicy
      location
      imageUrls {
        id
        url
      }
      recurringType {
        id
        type
        end_time
        start_time
      }
      monthlyRecurring {
        id
        date
      }
      singleDay {
        id
        end_time
        start_time
      }
      weeklyRecurring {
        id
        daysOfWeek
      }
      pricingDetails {
        id
        label
        price
        priceId
        currency
        productId
        description
        warningMsgOn
        ticketQuantity
      }
    }
  }
`;
