export const eventCategoryOptions: string[] = [
  "Technology",
  "Business",
  "Health",
  "Art and Culture",
  "Sports and Fitness",
  "Science",
  "Education",
  "Entertainment",
  "Food and Drink",
  "Charity and Causes",
  "Community",
  "Fashion",
  "Music",
  "Film and Media",
  "Travel and Outdoor",
  "Government",
  "Hobbies",
  "Home and Lifestyle",
  "Other",
];

export const timeZoneOptions: string[] = [
  "New Delhi, India (UTC+05:30)",
  "Mumbai, India (UTC+05:30)",
  "Sydney, Australia (UTC+10:00)",
  "Melbourne, Australia (UTC+11:00)",
  "Auckland, New Zealand (UTC+12:00)",
  "Wellington, New Zealand (UTC+13:00)",
];

export const languageOptions: string[] = [
  "English",
  "Chinese",
  "Japanese",
  "Korean",
  "Hindi",
  "Maori",
  "Samoan",
];
