import React, { Fragment, MouseEventHandler, useState } from "react";
import { Link } from "react-router-dom";
import { indianSite, siteCurrency } from "../../helpers/site-info";
import ConfirmationDialog from "./ConfirmationDialog";
import SubscriptionDetails from "./subscriptionDetails";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/20/solid";
import LoadingSpinner from "../../components/common/LoadingSpinner/LoadingSpinner";

interface SubscriptionProps {
  data?: any;
  error: any;
  loading: boolean;
}

const Subscription: React.FC<SubscriptionProps> = ({
  data,
  error,
  loading,
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [subId, setSubId] = useState<string | null>(null);
  const [planId, setPlanId] = useState<number | null>(null);
  const [visible, setVisibility] = useState<boolean>(false);

  const openDialog: (
    subId: string,
    planId: number,
  ) => MouseEventHandler<HTMLButtonElement> = (subId, planId) => () => {
    setSubId(subId);
    setPlanId(planId);
    setIsDialogOpen(true);
  };

  if (loading) {
    return (
      <section className="px-4 py-10 text-center">
        <LoadingSpinner  />
      </section>
    );
  }

  // if (error || !data) {
  //   return (
  //     <div className="mx-auto mt-4 w-full font-medium text-gray-700">
  //       <p>
  //         Oops! It seems you don't have an active subscription at the moment. No
  //         worries! You can unlock our full range of services by subscribing now.
  //         To get started, simply{" "}
  //         <span className="font-bold text-primary">
  //           <Link to="/pricing">explore our subscription plans</Link>
  //         </span>{" "}
  //         ✨. Let's make sure you never miss out on the best we have to offer!
  //       </p>
  //     </div>
  //   );
  // }
  const activeSubscriptions = data?.filter(
    (details: any) => details.status === "active",
  );
  const previousSubscriptions = data?.filter(
    (details: any) => details.status === "canceled",
  );

  return (
    <>
      <section className=" rounded-md bg-white px-6 ">
        {activeSubscriptions?.length > 0 ? (
          activeSubscriptions?.map((item: any) => (
            <Fragment>
              <span className="text-xl font-bold md:text-3xl">
                Your plan details
              </span>
              <div className="max-w-xl" key={item.subscriptionPlanId}>
                <p className="my-4 text-lg font-bold md:text-xl">
                  Current Subscriptions
                </p>
                <div className="">
                  <p className="text-xl md:text-2xl">{item.title}</p>
                  <p className="text-md mt-2 font-bold md:text-xl">
                    {siteCurrency} {indianSite ? item.cost : item.costNZ} per{" "}
                    {item.frequency}
                  </p>
                  <button
                    className="my-2 text-sm"
                    onClick={() => setVisibility(!visible)}
                  >
                    {visible ? (
                      <div className="flex items-center">
                        <span>See less</span>
                        <ChevronUpIcon className="h-6 w-6" />
                      </div>
                    ) : (
                      <div className="flex items-center">
                        <span>See more</span>
                        <ChevronDownIcon className="h-6 w-6" />
                      </div>
                    )}
                  </button>
                  {visible && (
                    <SubscriptionDetails
                      email={item.userEmail}
                      amountPaid={item.amountPaid}
                      purchasedAt={item.subscribedAt}
                      endsAt={item.endAt}
                      cancelled={false}
                    />
                  )}
                  <p className="mb-2 text-xs text-gray-800 md:text-sm">
                    Upon cancellation, you will continue to have access to the
                    service until the end of the current billing period.
                  </p>
                  <p className="text-xs text-gray-800 md:text-sm">
                    No prorated refunds will be issued for the remaining days of
                    your current period.
                  </p>
                  <button
                    className="my-4 rounded-lg bg-red-800 p-2 text-sm text-white"
                    type="button"
                    onClick={openDialog(item.stripeSubscriptionId, item.planId)}
                  >
                    Cancel Subscription
                  </button>
                </div>
              </div>
            </Fragment>
          ))
        ) : (
          <div className="mx-auto max-w-4xl mt-4 w-full font-medium text-gray-700">
            <p>
              Oops! It seems you don't have an active subscription at the
              moment. No worries! You can unlock our full range of services by
              subscribing now. To get started, simply{" "}
              <span className="font-bold text-primary">
                <Link to="/pricing">explore our subscription plans</Link>
              </span>{" "}
              ✨. Let's make sure you never miss out on the best we have to
              offer!
            </p>
          </div>
        )}
      </section>

      <section
        className={`${
          activeSubscriptions?.length > 0 ? "mt-16" : "mt-64"
        } rounded-md bg-white p-6 shadow-lg`}
      >
        <div>
          <p className="text-lg font-bold md:text-3xl">
            Previous Subscriptions
          </p>
          {previousSubscriptions && previousSubscriptions.length > 0 ? (
            previousSubscriptions.map((details: any) => (
              <Fragment key={details.subscriptionPlanId}>
                <div className="mt-4 flex flex-row border-b border-gray-800 text-xs font-bold sm:text-lg">
                  <p className="w-sm md:w-1/5">Title</p>
                  <p className="w-sm md:w-1/5">Cost</p>
                  <p className="w-sm md:w-1/5">Amount Paid</p>
                  <p className="w-sm md:w-1/5">Purchased at</p>
                  <p className="w-sm md:w-1/5">Expired at</p>
                </div>
                <div>
                  <SubscriptionDetails
                    title={details.title}
                    cost={details.cost}
                    frequency={details.frequency}
                    email={details.userEmail}
                    amountPaid={details.amountPaid}
                    purchasedAt={details.subscribedAt}
                    endsAt={details.endAt}
                    cancelled={true}
                  />
                </div>
              </Fragment>
            ))
          ) : (
            <p className="mt-4 text-gray-500">
              No previous subscriptions found.
            </p>
          )}
        </div>
      </section>

      {isDialogOpen && (
        <ConfirmationDialog
          onClose={() => setIsDialogOpen(false)}
          show={isDialogOpen}
          subId={subId}
          planId={planId}
        />
      )}
    </>
  );
};

export default Subscription;
