import React, { useState, useEffect } from "react";
import { Tab } from "@headlessui/react";
import { ApolloError } from "@apollo/client";

const AttendeeStatusTab: React.FC<any> = ({
  setPresence,
  refetch,
  setErrorMsg,
  setDisable,
}) => {
  function classNames(...classes: any) {
    return classes.filter(Boolean).join(" ");
  }

  const [hasMounted, setHasMounted] = useState(false);

  let [categories] = useState({
    all: [
      {
        title: "All",
        presence: null,
        disable: false,
      },
    ],
    attented: [
      {
        title: "Attended",
        presence: true,
        disable: true,
      },
    ],
    didNotAttended: [
      {
        title: "Did not attend",
        presence: false,
        disable: true,
      },
    ],
  });

  useEffect(() => {
    if (hasMounted) {
      refetch().catch((error: ApolloError) => setErrorMsg(error));

      setHasMounted(false);
    }
  }, [hasMounted, refetch]);

  return (
    <Tab.Group>
      <Tab.List className="flex border-b-4 border-primary">
        {Object.entries(categories)?.map(([category, items]) => (
          <Tab
            key={category}
            className={({ selected }) =>
              classNames(
                "w-full outline-none rounded-t-lg md:py-2.5 px-[2px] md:px-3  md:text-base font-medium leading-5",
                selected ? "bg-primary text-white" : "hover:text-primary",
              )
            }
            onClick={() => {
              setPresence(items[0].presence);
              setHasMounted(true);
              setDisable(items[0].disable);
            }}
          >
            {items[0].title}
          </Tab>
        ))}
      </Tab.List>
    </Tab.Group>
  );
};

export default AttendeeStatusTab;
