import React, { useState, useEffect } from "react";
import UILabel from "../../components/ui/Label";
import UIInput from "../../components/ui/Input";
import UISelect from "../../components/ui/Select";
import { useFormik } from "formik";
import { ticketUserSchema } from "../../validations/UserValidation";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import {
  PAYMENT_LINK,
  BOOK_PAID_EVENT,
  BOOK_FREE_EVENT,
} from "../../api/book-ticket";
import { getTicketListing } from "../../helpers/slots";
import { XMarkIcon } from "@heroicons/react/20/solid";
import OrderSummary from "./OrderSummary";
import { GetTicketDialogProps } from "../../types/GetTicketDialogData";
import { getCountryCode } from "../../helpers/get-country-code";

const formData = {
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
};

const GetTicketDialog: React.FC<GetTicketDialogProps> = ({
  open,
  handleOpen,
  eventId,
  loadingBtn,
  setLoadingBtn,
  isEmbeddedEvent,
  eventData,
}) => {
  const dynCountryCode = getCountryCode();

  const navigate = useNavigate();
  const [ticketDetails, setTicketDetails] = useState<any>([]);
  const [orderDetails, setOrderDetails] = useState<any>([]);
  const [freeTicket, setFreeTicket] = useState(0);
  const [minTicketSelected, setMinTicketSelected] = useState(true);

  const [listOfTickets] = useState(
    getTicketListing(eventData.totalTicketInFreeEvent),
  );
  const finalTicketListing =
    listOfTickets.length > 0
      ? listOfTickets
      : getTicketListing(eventData?.totalTicketInFreeEvent);

  const handleTicketQuantityChange = (
    currency: string,
    priceId: string,
    pricePerTicket: number,
    label: string,
    quantity: number,
  ) => {
    const existingTicketIndex = ticketDetails.findIndex(
      (ticket: { priceId: string }) => ticket.priceId === priceId,
    );

    if (existingTicketIndex !== -1) {
      if (quantity === 0) {
        const updatedTicketDetails = [...ticketDetails];
        updatedTicketDetails.splice(existingTicketIndex, 1);
        setTicketDetails(updatedTicketDetails);
      } else {
        const updatedTicketDetails = [...ticketDetails];
        updatedTicketDetails[existingTicketIndex].quantity = quantity;
        setTicketDetails(updatedTicketDetails);
      }
    } else {
      if (quantity > 0) {
        setTicketDetails([
          ...ticketDetails,
          {
            currency: currency,
            priceId: priceId,
            pricePerTicket: pricePerTicket,
            quantity: quantity,
          },
        ]);
      }
    }

    const existingOrderIndex = orderDetails.findIndex(
      (order: { priceId: string }) => order.priceId === priceId,
    );

    if (existingOrderIndex !== -1) {
      if (quantity === 0) {
        const updatedOrderDetails = [...orderDetails];
        updatedOrderDetails.splice(existingOrderIndex, 1);
        setOrderDetails(updatedOrderDetails);
      } else {
        const updatedOrderDetails = [...orderDetails];
        updatedOrderDetails[existingOrderIndex].quantity = quantity;
        setOrderDetails(updatedOrderDetails);
      }
    } else {
      if (quantity > 0) {
        setOrderDetails([
          ...orderDetails,
          {
            currency: currency,
            priceId: priceId,
            pricePerTicket: pricePerTicket,
            quantity: quantity,
            label: label,
          },
        ]);
      }
    }
  };

  const totalPriceFinal = ticketDetails.reduce((total: any, ticket: any) => {
    return total + ticket.pricePerTicket * ticket.quantity;
  }, 0);

  const [startPaidBooking] = useMutation(BOOK_PAID_EVENT);
  const [startFreeBooking] = useMutation(BOOK_FREE_EVENT);
  const [createPaymentLink] = useMutation(PAYMENT_LINK);

  const [countryCode, setCountryCode] = useState("");

  const handleCountryCodeChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    setCountryCode(event.target.value);
  };
  const isTrue = eventData.free;
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: formData,
      validationSchema: ticketUserSchema,
      validateOnBlur: true,
      onSubmit: async (values) => {
        const { ...submitVals } = values;

        if (countryCode && values.phoneNumber.length >= 8) {
          submitVals.phoneNumber = countryCode + values.phoneNumber;
        }

        try {
          setLoadingBtn(true);
          let res;
          if (isTrue) {
            res = await startFreeBooking({
              variables: {
                ...submitVals,
                eventId: Number(eventId),
                noOfTickets: freeTicket,
                dateTicketFor: Number(eventData.startDate),
              },
            });

            if (res?.data?.event_freeEventBuyNow?.customerid) {
              if (isEmbeddedEvent) {
                window.parent.location = `/success/?eventid=${eventId}&customerid=${res?.data?.event_freeEventBuyNow?.customerid}&totalPrice=${totalPriceFinal}&paymentstatus=true`;
              } else {
                navigate(
                  `/success/?eventid=${eventId}&customerid=${res?.data?.event_freeEventBuyNow?.customerid}&totalPrice=${totalPriceFinal}&paymentstatus=true`,
                );
              }
            }
          } else {
            if (orderDetails.length < 1) {
              setMinTicketSelected(false);
              setLoadingBtn(false);
              return;
            }

            res = await startPaidBooking({
              variables: {
                ...submitVals,
                eventId: Number(eventId),
                ticketDetails: ticketDetails,
                dateTicketFor: Number(eventData.startDate),
              },
            });
          }

          if (
            res &&
            res?.data?.event_buyNow?.customerid &&
            totalPriceFinal !== 0
          ) {
            try {
              const linkRes = await createPaymentLink({
                variables: {
                  eventId: Number(eventId),
                  customerId: res?.data?.event_buyNow?.customerid,
                  userId: eventData?.user_id,
                  totalPrice: totalPriceFinal,
                  ticket: ticketDetails.map((ticket: any) => ({
                    price: ticket.priceId,
                    quantity: ticket.quantity,
                  })),
                },
              });
              if (linkRes && linkRes?.data?.event_PaymentLink) {
                if (isEmbeddedEvent) {
                  window.parent.location = linkRes?.data?.event_PaymentLink;
                } else {
                  window.location.assign(linkRes?.data?.event_PaymentLink);
                }
                setLoadingBtn(false);
              } else {
                setLoadingBtn(false);
              }
            } catch (e: any) {
              toast.error("Unable to book tickets.");
              setLoadingBtn(false);
            }
          } else if (
            res &&
            res?.data?.event_buyNow?.customerid &&
            totalPriceFinal === 0
          ) {
            if (isEmbeddedEvent) {
              window.parent.location = `/success/?eventid=${eventId}&customerid=${res?.data?.event_buyNow?.customerid}&totalPrice=${totalPriceFinal}&paymentstatus=true`;
            } else {
              navigate(
                `/success/?eventid=${eventId}&customerid=${res?.data?.event_buyNow?.customerid}&totalPrice=${totalPriceFinal}&paymentstatus=true`,
              );
            }
          } else {
            setLoadingBtn(false);
          }
        } catch (e: any) {
          toast.error("Unable to book tickets.");
          setLoadingBtn(false);
        }
      },
    });

  useEffect(() => {
    if (open) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }

    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, [open]);

  useEffect(() => {
    if (dynCountryCode) {
      setCountryCode(dynCountryCode);
    }
  }, [dynCountryCode]);

  return (
    <div
      className={`fixed inset-0 z-50 overflow-y-auto ${
        open ? "block" : "hidden"
      }`}
    >
      <div className="flex min-h-screen items-end justify-center px-4 pb-20 pt-4 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity " aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>

        <span
          className="hidden sm:inline-block sm:h-screen sm:align-middle"
          aria-hidden="true"
        >
          &#8203;
        </span>

        <div
          className="inline-block max-h-full transform overflow-y-auto rounded-lg bg-white p-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-0 sm:align-middle md:max-w-4xl"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <form onSubmit={handleSubmit}>
            <div className="flex flex-col md:flex-row">
              <div className="bg-white sm:p-6 sm:pb-4">
                <div className="flex">
                  <h4 className="h4 mb-5">Buy Tickets</h4>
                </div>
                <div className="mb-5">
                  <div className="flex flex-1 flex-col md:flex-row md:gap-3">
                    <div className="mb-3 flex w-full flex-col">
                      <UILabel htmlFor="firstName">
                        First Name <span className="text-red-600">*</span>
                      </UILabel>
                      <UIInput
                        id="firstName"
                        type="text"
                        placeholder="Enter your First Name"
                        name="firstName"
                        required
                        onChange={handleChange}
                        value={values.firstName}
                        onBlur={handleBlur}
                      />
                      {errors.firstName && touched.firstName && (
                        <span className="block text-sm text-red-600">
                          {errors.firstName}
                        </span>
                      )}
                    </div>
                    <div className="mb-3 flex w-full flex-col">
                      <UILabel htmlFor="lastName">
                        Last Name <span className="text-red-600">*</span>
                      </UILabel>
                      <UIInput
                        id="lastName"
                        type="text"
                        placeholder="Enter your Last Name"
                        name="lastName"
                        required
                        onChange={handleChange}
                        value={values.lastName}
                        onBlur={handleBlur}
                      />
                      {errors.lastName && touched.lastName && (
                        <span className="block text-sm text-red-600">
                          {errors.lastName}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="flex flex-1 flex-col md:flex-row md:gap-3">
                    <div className="mb-3 flex w-full flex-col">
                      <UILabel htmlFor="email">
                        Email <span className="text-red-600">*</span>
                      </UILabel>
                      <UIInput
                        id="email"
                        type="email"
                        className="form-input w-full text-gray-800"
                        placeholder="Enter your email address"
                        name="email"
                        required
                        onChange={handleChange}
                        value={values.email}
                        onBlur={handleBlur}
                      />
                      {errors.email && touched.email && (
                        <span className="block text-sm text-red-600">
                          {errors.email}
                        </span>
                      )}
                    </div>
                    <div className="mb-3 flex w-full flex-col">
                      <UILabel htmlFor="firstName">
                        Phone Number<span className="text-red-600">*</span>
                      </UILabel>
                      <div className="flex">
                        <select
                          id="countryCode"
                          name="countryCode"
                          className="form-select mr-1 h-12 w-1/3 rounded-lg  bg-gray-50  p-2 text-sm text-gray-800"
                          onChange={handleCountryCodeChange}
                          value={countryCode}
                          required
                        >
                          <option value="" disabled>
                            Country code
                          </option>
                          <option value="64">+64 (New Zealand)</option>
                          <option value="91">+91 (India)</option>
                        </select>
                        <div
                          style={{
                            alignContent: "center",
                            alignItems: "center",
                          }}
                        >
                          {" "}
                          <UIInput
                            id="phoneNumber"
                            type="tel"
                            className="form-input w-full text-gray-800"
                            placeholder="Enter your Phone Number"
                            name="phoneNumber"
                            required
                            onChange={handleChange}
                            value={values.phoneNumber}
                            onBlur={handleBlur}
                            minLength={dynCountryCode === "91" ? 10 : 8}
                            maxLength={10}
                          />
                          {errors.phoneNumber && touched.phoneNumber && (
                            <span className="block text-sm text-red-600">
                              {errors.phoneNumber}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {eventData.totalTicketInFreeEvent > 0 && (
                  <>
                    <UILabel
                      htmlFor="ticketInfo"
                      className="mb-0 mt-2 font-bold"
                    >
                      Number of Tickets
                    </UILabel>
                    <UISelect
                      arr={finalTicketListing}
                      selected={freeTicket}
                      setSelected={setFreeTicket}
                    />
                  </>
                )}

                <div className="mb-4"></div>
                <div className="space-y-4">
                  <div className="-mx-1 flex flex-wrap">
                    {eventData.pricingDetails?.map((pricingDetail: any) => (
                      <div
                        key={pricingDetail.id}
                        className={`relative mb-4 w-full px-1 ${
                          pricingDetail.ticketQuantity === 0
                            ? "cursor-not-allowed opacity-50"
                            : "cursor-pointer"
                        }`}
                        title={
                          pricingDetail.ticketQuantity === 0 ? "Sold Out" : ""
                        }
                      >
                        <div
                          className={`rounded-lg border-2 border-solid border-secondary px-4 py-3 text-gray-800 shadow-md   transition-all `}
                        >
                          <div className="flex w-full items-center justify-between">
                            <div className="flex items-center">
                              <div className="text-base">
                                <p className={` font-semibold text-gray-800`}>
                                  {pricingDetail.label}
                                </p>
                                <span
                                  className={`font-semibold text-gray-800 `}
                                >
                                  {pricingDetail.currency} {pricingDetail.price}
                                </span>
                              </div>
                            </div>

                            <div className="flex w-1/3 items-center justify-end">
                              <div className="w-full">
                                <UISelect
                                  arr={getTicketListing(
                                    pricingDetail.ticketQuantity,
                                  )}
                                  selected={
                                    ticketDetails.find(
                                      (ticket: { priceId: any }) =>
                                        ticket.priceId ===
                                        pricingDetail.priceId,
                                    )?.quantity || 0
                                  }
                                  setSelected={(quantity) =>
                                    handleTicketQuantityChange(
                                      pricingDetail.currency,
                                      pricingDetail.priceId,
                                      pricingDetail.price,
                                      pricingDetail.label,
                                      quantity,
                                    )
                                  }
                                />
                              </div>
                            </div>
                          </div>
                          <p
                            className={`mt-2 border-t py-4 text-sm text-gray-700 
                            `}
                          >
                            {pricingDetail.description}
                          </p>
                        </div>
                        {pricingDetail?.ticketQuantity &&
                        pricingDetail?.ticketQuantity <=
                          pricingDetail?.warningMsgOn ? (
                          <span className="absolute bottom-0 right-0 mb-1 mr-2 rounded bg-orange-600 px-2 py-1 text-xs font-medium text-white">
                            Very few tickets left
                          </span>
                        ) : null}
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              <OrderSummary
                eventData={eventData}
                orderDetails={orderDetails}
                totalPriceFinal={totalPriceFinal}
                minTicketSelected={minTicketSelected}
              />
              <div
                onClick={handleOpen}
                className="absolute right-0 cursor-pointer"
              >
                <XMarkIcon width={"30px"} />
              </div>
            </div>
            <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
              <button
                type="submit"
                disabled={loadingBtn}
                className={`hover:bg-primary-dark focus:ring-primary-dark inline-flex w-full justify-center rounded-md border border-transparent bg-primary px-4 py-2 text-base font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm ${
                  loadingBtn ? "cursor-not-allowed opacity-50" : ""
                }`}
              >
                {loadingBtn ? "Loading..." : "Buy Now"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default GetTicketDialog;
