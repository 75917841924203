import React, { useState, useEffect } from "react";
import UIInput from "../../../components/ui/Input";
import FreeTicket from "./FreeTicket";
import PaidTicket from "./PaidTicket";

type TabType = "free" | "paid";

interface Ticket {
  id: number;
  label: string;
  description: string;
  ticketQuantity: number;
  ticketWarningOn: number;
  currency: string;
  price: number;
}

interface TicketInfo {
  free: boolean;
  paid: boolean;
  tickets: Ticket[];
}

const TicketTypeSelector: React.FC<any> = ({ formik }) => {
  const [activeTab, setActiveTab] = useState<TabType | null>("free");
  const [tickets, setTickets] = useState<Ticket[]>([]);
  const [ticketCount, setTicketCount] = useState(1);
  const [showEditOptions, setShowEditOptions] = useState<number | null>(null);
  const [freeTicket, setFreeTicket] = useState<number>(0);

  useEffect(() => {
    if (activeTab === "free" && formik) {
      formik.setFieldValue("free", true);
      formik.setFieldValue("paid", false);
    }
  }, []);

  const handleTabClick = (tab: TabType) => {
    setActiveTab(tab);
    if (tab === "free" && formik) {
      formik.setFieldValue("free", true);
      formik.setFieldValue("paid", false);
    } else if (tab === "paid" && formik) {
      formik.setFieldValue("free", false);
      formik.setFieldValue("paid", true);
      setFreeTicket(0); 
      formik.setFieldValue("freeTicket", 0); 
    }
  };

  const handleCreateTicket = (ticket: Ticket) => {
    const updatedTickets = [...tickets, ticket];
    setTickets(updatedTickets);
    setTicketCount(ticketCount + 1);
    formik.setFieldValue("ticketInfo", updatedTickets);
  };

  const handleEditTicket = (editedTicket: Ticket) => {
    const updatedTickets = tickets.map((ticket) =>
      ticket.id === editedTicket.id ? editedTicket : ticket,
    );
    setTickets(updatedTickets);
    setShowEditOptions(null);
    formik.setFieldValue("ticketInfo", updatedTickets);
  };

  const handleEditOptionsClick = (id: number) => {
    if (showEditOptions === id) {
      setShowEditOptions(null);
    } else {
      setShowEditOptions(id);
    }
  };

  const handleDeleteTicket = (id: number) => {
    const updatedTickets = tickets.filter((ticket) => ticket.id !== id);
    setTickets(updatedTickets);
    setShowEditOptions(null);
    formik.setFieldValue("ticketInfo", updatedTickets);
  };

  const handleFreeTicketChange = (value: number) => {
    setFreeTicket(value);
    formik.setFieldValue("freeTicket", value);
  };

  return (
    <div className="w-full">
      <div className="flex border-b border-gray-200">
        <button
          type="button"
          className={`${
            activeTab === "free" ? "bg-secondary text-white" : "text-secondary"
          } px-8 py-2 focus:outline-none`}
          onClick={() => handleTabClick("free")}
        >
          Free
        </button>
        <button
          type="button"
          className={`${
            activeTab === "paid" ? "bg-secondary text-white" : "text-secondary"
          } px-8 py-2  focus:outline-none`}
          onClick={() => handleTabClick("paid")}
        >
          Paid
        </button>
      </div>
      <div className="mt-4">
        {activeTab === "free" && (
          <FreeTicket
            freeTicket={freeTicket}
            onFreeTicketChange={handleFreeTicketChange}
          />
        )}
        {activeTab === "paid" && (
          <PaidTicket
            onCreateTicket={handleCreateTicket}
            onEditTicket={handleEditTicket}
            ticketCount={ticketCount}
            tickets={tickets}
            handleEditOptionsClick={handleEditOptionsClick}
            showEditOptions={showEditOptions}
            handleDeleteTicket={handleDeleteTicket}
          />
        )}
      </div>
    </div>
  );
};
export default TicketTypeSelector;
