import { Typography } from "@material-tailwind/react";
import React, { HTMLAttributes } from "react";

interface IPageTitle extends HTMLAttributes<HTMLDivElement> {
  section?: string;
  heading?: string;
}

const PageTitle: React.FC<IPageTitle> = ({ section, heading, children }) => {
  return (
    <div className="mx-auto w-full px-4 text-center lg:w-2/3">
      {section && (
        <Typography variant="lead" className="font-semibold">
          {section}
        </Typography>
      )}
      {heading && (
        <Typography variant="h2" color="blue-gray" className="my-3">
          {heading}
        </Typography>
      )}
      {children && (
        <Typography variant="lead" className="text-blue-gray-500">
          {children}
        </Typography>
      )}
    </div>
  );
};

export default PageTitle;
