import { gql } from "@apollo/client";

export const CONTACT_US = gql`
  mutation ContactUs($fullname: String!, $email: String!, $message: String!) {
    contactUs(fullname: $fullname, email: $email, message: $message) {
      msg
      data
    }
  }
`;
