import moment from "moment-mini";

const getTimeStops = (duration: number) => {
  const startTime = moment("00:00", ["HH:mm"]);
  const endTime = moment("23:59", ["HH:mm"]);

  if (endTime.isBefore(startTime)) {
    endTime.add(1, "day");
  }

  const timeStops = [];

  while (startTime <= endTime) {
    timeStops.push(moment(startTime).format("hh:mm A"));
    startTime.add(duration, "minutes");
  }
  return timeStops;
};

export const getSlots = (duration: number) => {
  const arr = getTimeStops(duration);

  const slotsArr = arr.map((startTime, index) => ({
    from: startTime,
    to: arr[index + 1],
  }));

  slotsArr[slotsArr.length - 1] = {
    ...slotsArr[slotsArr.length - 1],
    to: "12:00 AM",
  };
  return slotsArr;
};
export const getTicketListing = (length: number): number[] => {
  if (length > 10) {
    return Array.from({ length: 11 }, (_, i) => i);
  } else {
    return Array.from({ length: length + 1 }, (_, i) => i);
  }
};

export const getListing = (length: number): number[] =>
  Array.from({ length: length }, (_, i) => i + 1);
