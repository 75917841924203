import { gql } from "@apollo/client";

export const SETUP_INTENT_AND_CREATE_SUBSCRIPTION = gql`
  mutation Payment_retrieveSetupIntentAndCreateSubscription(
    $subscriptionId: Int!
    $userId: Int!
    $customerid: String!
    $setupIntentId: String!
    $priceid: String!
  ) {
    payment_retrieveSetupIntentAndCreateSubscription(
      subscriptionId: $subscriptionId
      userId: $userId
      customerid: $customerid
      setupIntentId: $setupIntentId
      priceid: $priceid
    ) {
      message
      setupIntent {
        id
        object
        automatic_payment_methods {
          allow_redirects
          enabled
        }
        created
        customer
        latest_attempt
        livemode
        payment_method
        payment_method_configuration_details {
          id
          parent
        }
        payment_method_options {
          card {
            mandate_options
            network
            request_three_d_secure
          }
        }
        payment_method_types
        status
        usage
      }
      subscription {
        id
        invoice
      }
    }
  }
`;
