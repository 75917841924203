import React, { FormEvent, useState } from "react";
import CommonDatePicker from "../../components/common/CommonDatePicker";
import CommonTimePicker from "../../components/common/CommonTimePicker";
import UIInput from "../../components/ui/Input";
import UIInputWrapper from "../../components/ui/InputWrapper";
import UILabel from "../../components/ui/Label";
import UISelect from "../../components/ui/Select";
import { getListing, getSlots } from "../../helpers/slots";
import SelectDuration from "./SelectDuration";
import { CREATE_INSPECTION_MUTATION } from "../../api/inspections";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { getUserDetails } from "../../helpers/users";
import { useMutation } from "@apollo/client";
import moment from "moment-mini";
import UIButton from "../../components/ui/Button";

const CreateInspection: React.FC = () => {
  const { token, userId } = getUserDetails();
  const navigate = useNavigate();
  const [entityName, setEntityName] = useState("");

  const durations = [15, 30, 45, 60];
  const [selectedDuration, setSelectedDuration] = useState(durations[0]);

  const [selectedDate, setSelectedDate] = useState(new Date());

  const slots = getSlots(selectedDuration);
  const [selectedTime, setSelectedTime] = useState(slots[0]);

  const numberOfPeople = getListing(50);
  const [selected, setSelected] = useState(numberOfPeople[0]);

  const [createInspection, { data }] = useMutation(CREATE_INSPECTION_MUTATION, {
    variables: {
      userId: Number(userId),
      entityId: entityName,
      date: moment(selectedDate).format("YYYY-MM-DD"),
      slots: [
        {
          from: selectedTime.from,
          numberOfPeople: selected,
          to: selectedTime.to,
        },
      ],
    },
  });

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    try {
      const res = await createInspection();
      if (res && res.data.createInspectionTimings[0]["slotID"]) {
        toast.success('Inspection created successfully.', {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000, 
        });
        navigate("/create-inspection");
      }
    } catch (error: any) {
      if (
        error.message ===
        "Cannot create another inspection for this entity for same date."
      ) {
        toast.error(
          "Cannot create another inspection for this entity for same date.",
        );
        toast.error("Try creating another slots.");
      } else {
        toast.error("Unable to create inspection.");
      }
      console.error(error);
    }
  };
  return (
    <section>
      <div className="mx-auto max-w-6xl px-4 sm:px-6">
        <div className="py-12 md:py-20">
          {/* Form */}
          <div className="mx-auto max-w-sm">
            <h1 className="h2 mb-10 text-center">
              Create Inspection or Appointment
            </h1>
            <form onSubmit={handleSubmit}>
              <UIInputWrapper>
                <UILabel htmlFor="entity_id">Entity Id</UILabel>
                <UIInput
                  type="text"
                  id="entity_id"
                  placeholder="Enter the Entity Id for booking Inspection"
                  required
                  value={entityName}
                  onChange={(e) => setEntityName(e.target.value)}
                />
              </UIInputWrapper>

              <UIInputWrapper>
                <UILabel>Date</UILabel>
                <CommonDatePicker
                  selected={selectedDate}
                  setSelected={setSelectedDate}
                />
              </UIInputWrapper>

              <UIInputWrapper>
                <UILabel>Select Duration</UILabel>
                <SelectDuration
                  arr={durations}
                  selected={selectedDuration}
                  setSelected={setSelectedDuration}
                />
              </UIInputWrapper>

              <UIInputWrapper>
                <UILabel>Time</UILabel>
                <CommonTimePicker
                  arr={slots}
                  selected={selectedTime}
                  setSelected={setSelectedTime}
                />
              </UIInputWrapper>

              <UIInputWrapper>
                <UILabel htmlFor="entity_url">Number of people</UILabel>
                <UISelect
                  arr={numberOfPeople}
                  selected={selected}
                  setSelected={setSelected}
                />
              </UIInputWrapper>
              <UIInputWrapper>
                <UIButton type="submit">Create Inspection</UIButton>
              </UIInputWrapper>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CreateInspection;
