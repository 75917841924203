import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import { ApolloProvider } from "@apollo/client";
import { client } from "./api/client";
import "./css/style.css";
import { ThemeProvider } from "@material-tailwind/react";
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  <ThemeProvider>
    <ApolloProvider client={client}>
      <Router>
          <App />
      </Router>
    </ApolloProvider>
  </ThemeProvider>
);
