import { gql } from "@apollo/client";

export const BOOK_PAID_EVENT = gql`
  mutation Event_buyNow(
    $firstName: String!
    $lastName: String!
    $email: String!
    $eventId: Int!
    $phoneNumber: String!
    $ticketDetails: [customerTicketDetail]!
    $dateTicketFor: BigInt!
  ) {
    event_buyNow(
      first_name: $firstName
      last_name: $lastName
      email: $email
      event_id: $eventId
      phoneNumber: $phoneNumber
      ticketDetails: $ticketDetails
      dateTicketFor: $dateTicketFor
    ) {
      customer_name
      customer_email
      eventid
      customerid
      no_of_ticket
    }
  }
`;
export const BOOK_FREE_EVENT = gql`
  mutation Event_freeEventBuyNow(
    $firstName: String!
    $lastName: String!
    $email: String!
    $eventId: Int!
    $noOfTickets: Int!
    $phoneNumber: String!
    $dateTicketFor: BigInt!
  ) {
    event_freeEventBuyNow(
      first_name: $firstName
      last_name: $lastName
      email: $email
      event_id: $eventId
      no_of_tickets: $noOfTickets
      phoneNumber: $phoneNumber
      dateTicketFor: $dateTicketFor
    ) {
      customer_name
      customer_email
      eventid
      customerid
      no_of_ticket
    }
  }
`;

export const PAYMENT_LINK = gql`
  mutation Event_PaymentLink(
    $userId: Int!
    $ticket: [lineItem]!
    $eventId: Int!
    $customerId: Int!
    $totalPrice: Int!
  ) {
    event_PaymentLink(
      userId: $userId
      ticket: $ticket
      eventId: $eventId
      customerId: $customerId
      totalPrice: $totalPrice
    )
  }
`;

export const STATUS_PAYMENT = gql`
  mutation Event_updateCustomerPaymentStatus(
    $customerid: Int!
    $totalPrice: String!
    $paymentStatus: Boolean!
  ) {
    event_updateCustomerPaymentStatus(
      customerid: $customerid
      total_price: $totalPrice
      payment_status: $paymentStatus
    )
  }
`;
export const FREE_TICKET_PAYMENT_STATUS = gql`
  mutation Event_updateCustomerPaymentStatusForFreeEvent(
    $customerid: Int!
    $totalPrice: String!
    $paymentStatus: Boolean!
  ) {
    event_updateCustomerPaymentStatusForFreeEvent(
      customerid: $customerid
      total_price: $totalPrice
      payment_status: $paymentStatus
    )
  }
`;
