import { useMutation } from "@apollo/client";
import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment } from "react";
import { CANCEL_SUBSCRIPTION } from "../../api/Payment/cancelSubscription";
import { toast } from "react-toastify";
import { TrashIcon } from "@heroicons/react/24/outline";

interface ConfirmationDialogProps {
  onClose: () => void;
  show: boolean;
  subId: string | null;
  planId: number | null;
}
const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  onClose,
  show,
  subId,
  planId,
}) => {
  const userid = localStorage.getItem("userId");

  const [cancelSubscription] = useMutation(CANCEL_SUBSCRIPTION);

  const handleCancellation = async () => {
    try {
      const res = await cancelSubscription({
        variables: {
          userId: Number(userid),
          userStripeSubscriptionId: subId,
          planId: planId,
        },
      });

      if (
        res &&
        res.data &&
        res.data.payment_cancelSubscription.subscription !== null
      ) {
        toast.success("Subscription Cancelled successfully", {
          position: "top-center",
          autoClose: 2000,
          onClose: () => {
            window.location.reload();
          },
        });
      }
    } catch (error) {
      toast.error("Something went wrong", {
        position: "top-center",
        autoClose: 2000,
      });
    }
  };

  return (
    <>
      <Transition show={show} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10 transition-all"
          onClose={onClose}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <div className="flex justify-center">
                    <TrashIcon className="h-48 w-48 font-light text-secondary" />
                  </div>
                  <div className="mt-4 text-center text-gray-700 ">
                    <p className="mb-2  text-xl font-bold">Are you sure?</p>
                    <p className="mb-2 text-sm ">
                      Upon cancellation, you will continue to have access to the
                      service until the end of the current billing period.
                    </p>
                    <p className="text-sm ">
                      if you ever change your mind you can buy subscription
                      again.
                    </p>
                  </div>
                  <div className="mt-2 flex justify-evenly">
                    <button
                      type="button"
                      className="my-2 w-[150px] rounded-lg bg-gray-200 p-2 font-bold text-secondary"
                      onClick={onClose}
                    >
                      Go back
                    </button>
                    <button
                      type="button"
                      className="my-2 w-[150px] rounded-lg bg-red-800 p-2 font-bold text-white"
                      onClick={handleCancellation}
                    >
                      Cancel plan
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default ConfirmationDialog;
