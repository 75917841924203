import { Link } from "react-router-dom";
import Logo from "./Logo";
import { HashLink } from "react-router-hash-link";
import { HeartIcon } from "@heroicons/react/20/solid";
import { indianSite } from "../../helpers/site-info";

const Footer = () => {
  return (
    <footer>
      <div className="mx-auto my-5 max-w-6xl px-4 sm:px-6">
        {/* Top area: Blocks */}
        <div className="grid gap-8 border-t border-gray-500 py-8 sm:grid-cols-12 md:py-12">
          {/* 1st block */}
          <div className="sm:col-span-12 lg:col-span-3">
            <div className="mb-2">
              <Logo />
            </div>
            <div className="text-sm text-gray-600">
              <Link
                to="/terms-of-service"
                className="text-gray-600 transition duration-150 ease-in-out hover:text-gray-900 hover:underline"
              >
                Terms
              </Link>{" "}
              ·{" "}
              <Link
                to="/privacy-policy"
                className="text-gray-600 transition duration-150 ease-in-out hover:text-gray-900 hover:underline"
              >
                Privacy policy
              </Link>
            </div>
          </div>

          {/* 2nd block */}
          <div className="sm:col-span-6 md:col-span-3 lg:col-span-3">
            <h6 className="mb-2 font-medium text-gray-800">Products</h6>
            <ul className="text-sm">
              <li className="mb-2">
                <HashLink
                  to="/event-management"
                  className="text-gray-600 transition duration-150 ease-in-out hover:text-gray-900"
                >
                  Events management
                </HashLink>
              </li>
              {/* <li className="mb-2">
                <HashLink
                  to="/inspection-management"
                  className="text-gray-600 transition duration-150 ease-in-out hover:text-gray-900"
                >
                  Inspections management
                </HashLink>
              </li>
              <li className="mb-2">
                <HashLink
                  to="/#usage"
                  className="text-gray-600 transition duration-150 ease-in-out hover:text-gray-900"
                >
                  Appointments management
                </HashLink>
              </li> */}
            </ul>
          </div>

          {/* 3rd block */}
          <div className="sm:col-span-6 md:col-span-3 lg:col-span-3">
            <h6 className="mb-2 font-medium text-gray-800">Resources</h6>
            <ul className="text-sm">
              <li className="mb-2">
                <Link
                  to="/support"
                  className="text-gray-600 transition duration-150 ease-in-out hover:text-gray-900"
                >
                  Support center
                </Link>
              </li>
              <li className="mb-2">
                <Link
                  to="/terms-of-service"
                  className="text-gray-600 transition duration-150 ease-in-out hover:text-gray-900"
                >
                  Terms of service
                </Link>
              </li>
              <li className="mb-2">
                <Link
                  to="/privacy-policy"
                  className="text-gray-600 transition duration-150 ease-in-out hover:text-gray-900"
                >
                  Privacy policy
                </Link>
              </li>
              <li className="mb-2">
                <Link
                  to="/cancellation"
                  className="text-gray-600 transition duration-150 ease-in-out hover:text-gray-900"
                >
                  Cancellation & Refund Policy
                </Link>
              </li>
            </ul>
          </div>

          {/* 4th block */}
          <div className="sm:col-span-6 md:col-span-3 lg:col-span-3">
            <h6 className="mb-2 font-medium text-gray-800">Company</h6>
            <ul className="text-sm">
              <li className="mb-2">
                <Link
                  to="/"
                  className="text-gray-600 transition duration-150 ease-in-out hover:text-gray-900"
                >
                  Home
                </Link>
              </li>
              <li className="mb-2">
                <Link
                  to={"/contact"}
                  className="text-gray-600 transition duration-150 ease-in-out hover:text-gray-900"
                >
                  Contact us
                </Link>
              </li>
              <li className="mb-2">
                <Link
                  to="/pricing"
                  className="text-gray-600 transition duration-150 ease-in-out hover:text-gray-900"
                >
                  Pricing
                </Link>
              </li>
            </ul>
          </div>

          {/* 5th block */}
        </div>

        {/* Bottom area */}
        <div className="border-t border-gray-500 py-4 md:flex md:items-center md:justify-between md:py-8">
          {/* Social as */}
          {/* <ul className="flex mb-4 md:order-1 md:ml-4 md:mb-0">
            <li>
              <Link
                to="/"
                className="flex justify-center items-center text-gray-600 hover:text-gray-900 bg-white hover:bg-white-100 rounded-full shadow transition duration-150 ease-in-out"
                aria-label="Twitter"
              >
                <svg
                  className="w-8 h-8 fill-current"
                  viewBox="0 0 32 32"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="m13.063 9 3.495 4.475L20.601 9h2.454l-5.359 5.931L24 23h-4.938l-3.866-4.893L10.771 23H8.316l5.735-6.342L8 9h5.063Zm-.74 1.347h-1.457l8.875 11.232h1.36l-8.778-11.232Z" />
                </svg>
              </Link>
            </li>
            <li className="ml-4">
              <Link
                to="/"
                className="flex justify-center items-center text-gray-600 hover:text-gray-900 bg-white hover:bg-white-100 rounded-full shadow transition duration-150 ease-in-out"
                aria-label="Github"
              >
                <svg
                  className="w-8 h-8 fill-current"
                  viewBox="0 0 32 32"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M16 8.2c-4.4 0-8 3.6-8 8 0 3.5 2.3 6.5 5.5 7.6.4.1.5-.2.5-.4V22c-2.2.5-2.7-1-2.7-1-.4-.9-.9-1.2-.9-1.2-.7-.5.1-.5.1-.5.8.1 1.2.8 1.2.8.7 1.3 1.9.9 2.3.7.1-.5.3-.9.5-1.1-1.8-.2-3.6-.9-3.6-4 0-.9.3-1.6.8-2.1-.1-.2-.4-1 .1-2.1 0 0 .7-.2 2.2.8.6-.2 1.3-.3 2-.3s1.4.1 2 .3c1.5-1 2.2-.8 2.2-.8.4 1.1.2 1.9.1 2.1.5.6.8 1.3.8 2.1 0 3.1-1.9 3.7-3.7 3.9.3.4.6.9.6 1.6v2.2c0 .2.1.5.6.4 3.2-1.1 5.5-4.1 5.5-7.6-.1-4.4-3.7-8-8.1-8z" />
                </svg>
              </Link>
            </li>
            <li className="ml-4">
              <Link
                to="/"
                className="flex justify-center items-center text-gray-600 hover:text-gray-900 bg-white hover:bg-white-100 rounded-full shadow transition duration-150 ease-in-out"
                aria-label="Facebook"
              >
                <svg
                  className="w-8 h-8 fill-current"
                  viewBox="0 0 32 32"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M14.023 24L14 17h-3v-3h3v-2c0-2.7 1.672-4 4.08-4 1.153 0 2.144.086 2.433.124v2.821h-1.67c-1.31 0-1.563.623-1.563 1.536V14H21l-1 3h-2.72v7h-3.257z" />
                </svg>
              </Link>
            </li>
          </ul> */}

          {/* Copyrights note */}
          <div className="mr-4 flex w-full flex-col-reverse	text-sm text-gray-600 md:flex-row md:justify-between">
            <span className="flex items-center">
              {" "}
              &copy;
              <Link to={"/"} className="border-none p-0 mx-1 text-sm">
                bookable
              </Link>
              All rights reserved.{" "}
            </span>
            {!indianSite && (
              <span className="mb-2 flex items-center md:mb-0">
                Made with <HeartIcon className="mx-1 h-4 text-red-900" /> in
                Auckland,New Zealand
              </span>
            )}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
