import { gql } from "@apollo/client";

export const CREATE_INSPECTION_MUTATION = gql`
  mutation CreateInspectionTimings(
    $userId: Int!
    $entityId: String!
    $date: String!
    $slots: [InspectionSlotsInput]
  ) {
    createInspectionTimings(
      userId: $userId
      entityId: $entityId
      date: $date
      slots: $slots
    ) {
      slotId
      entityId
      date
      FromTime
      ToTime
      numberOfPeople
    }
  }
`;
