import React from "react";

const PrivacyPolicy: React.FC = () => {
  return (
    <section className="relative bg-white px-4 py-8">
      <div className="container mx-auto my-8">
        <h1 className="mb-4 text-center text-4xl font-bold">Privacy Policy</h1>
        <li className="mb-4 text-center text-gray-600">
          Last Updated: [22 Jan 2024]
        </li>

        <div className="mx-auto max-w-4xl">
          {/* Information We Collect */}
          <section className="mb-8">
            <h2 className="mb-4 text-2xl font-semibold">
              Information We Collect
            </h2>
            <li className="mb-2">
              We may collect personal information such as your name, email
              address, and contact details when you register on our site.
            </li>
            <li className="mb-2">
              We gather information about how you use our website, including
              pages visited, time spent, and your IP address.
            </li>
          </section>

          {/* How We Use Your Information */}
          <section className="mb-8">
            <h2 className="mb-4 text-2xl font-semibold">
              How We Use Your Information
            </h2>
            <li className="mb-2">
              We use your information to personalize your experience on our site
              and deliver relevant content.
            </li>
            <li className="mb-2">
              We may use your email address to send you updates, newsletters,
              and other relevant information.
            </li>
          </section>

          {/* Disclosure to Third Parties */}
          <section className="mb-8">
            <h2 className="mb-4 text-2xl font-semibold">
              Disclosure to Third Parties
            </h2>
            <li className="mb-2">
              We do not sell, trade, or transfer your personally identifiable
              information to third parties without your consent.
            </li>
            <li className="mb-2">
              We may share information with trusted third parties who assist us
              in operating our website, conducting our business, or serving you.
            </li>
          </section>

          {/* Security */}
          <section className="mb-8">
            <h2 className="mb-4 text-2xl font-semibold">Security</h2>
            <li className="mb-2">
              We implement security measures to protect your personal
              information, but we cannot guarantee its absolute security.
            </li>
            <li className="mb-2">
              You are responsible for keeping your login credentials
              confidential.
            </li>
          </section>

          {/* Your Choices */}
          <section className="mb-8">
            <h2 className="mb-4 text-2xl font-semibold">Your Choices</h2>
            <li className="mb-2">
              You can opt-out of receiving promotional emails from us by
              following the instructions in the emails.
            </li>
            <li className="mb-2">
              You can update or delete your personal information by contacting
              us.
            </li>
          </section>

          {/* Changes to This Privacy Policy */}
          <section className="mb-8">
            <h2 className="mb-4 text-2xl font-semibold">
              Changes to This Privacy Policy
            </h2>
            <li className="mb-2">
              We reserve the right to update this Privacy Policy. We will notify
              you of any changes by posting the new policy on this page.
            </li>
          </section>

          {/* Contact Us */}
          <section className="mb-8">
            <h2 className="mb-4 text-2xl font-semibold">Contact Us</h2>
            <li>
              If you have any questions or concerns about our Privacy Policy,
              please contact us at{" "}
              <a
                className="text-blue-500"
                href="mailto:anurag@blazecode.io"
              >
                anurag@blazecode.io
              </a>
              .
            </li>
          </section>
        </div>
      </div>
    </section>
  );
};

export default PrivacyPolicy;
