import React, { useState } from "react";
import PageTitle from "../../components/ui/PageTitle";
import {
  Button,
  Input,
  Textarea,
  Card,
  CardBody,
  CardFooter,
  Typography,
} from "@material-tailwind/react";
import ReCAPTCHA from "react-google-recaptcha";
import { useFormik } from "formik";
import { contactUsSchema } from "../../validations/UserValidation";
import UIInputWrapper from "../../components/ui/InputWrapper";
import { toast } from "react-toastify";
import { CONTACT_US } from "../../api/contact";
import { useMutation } from "@apollo/client";
import { ArrowRightIcon, CheckCircleIcon } from "@heroicons/react/20/solid";
import { Link } from "react-router-dom";

const Contact: React.FC = () => {
  const { REACT_APP_SITE_KEY } = process.env;
  const [captchaToken, setCaptchaToken] = useState("");
  const [card, setCard] = useState(false);

  const formData = {
    fullname: "",
    email: "",
    message: "",
  };
  const [sendContactMail] = useMutation(CONTACT_US);

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: formData,
      validationSchema: contactUsSchema,
      validateOnBlur: true,
      onSubmit: async (values, action) => {
        if (captchaToken.length > 0) {
          try {
            const res = await sendContactMail({ variables: values });
            if (res && res.data.contactUs.data) {
              toast.success("Your message has been sent.");
              setCard(true);
            } else {
              toast.error("Unable to send message.");
            }
          } catch (e: any) {
            toast.error("Unable to send message.");
            console.error(e.message);
          }
        } else {
          toast.error("Please verify ReCAPTCHA.");
        }
        action.resetForm();
      },
    });

  return (
    <section className="relative bg-white px-4 py-8">
      {card ? (
        <div className="container mx-auto flex flex-col justify-center">
          <PageTitle heading="Thank You!" />
          <Card className="mx-auto mt-6 w-72 items-center border border-secondary md:w-1/2">
            <CardBody className="text-center">
              <CheckCircleIcon className="text-green-400" />
              <Typography variant="h5" color="blue-gray" className="mb-2">
                Thank You for Contacting Us.
              </Typography>
              <Typography>We will get back to you in 24 hours.</Typography>
            </CardBody>
            <CardFooter className="pt-0">
              <Link to={"/"} className="flex justify-center">
                <Button
                  size="sm"
                  variant="text"
                  className="flex items-center gap-2"
                >
                  Back To Home
                  <ArrowRightIcon className="h-4 w-4" />
                </Button>
              </Link>
            </CardFooter>
          </Card>
        </div>
      ) : (
        <div className="container mx-auto">
          <PageTitle heading="Contact Us">
            Complete this form and we will get back to you in 24 hours.
          </PageTitle>
          <form
            className="mx-auto mt-12 w-full lg:w-5/12"
            onSubmit={handleSubmit}
          >
            <div className="flex flex-col gap-4 md:flex-row">
              <UIInputWrapper className="w-full">
                <Input
                  variant="outlined"
                  size="lg"
                  label="Full Name"
                  crossOrigin=""
                  onChange={handleChange}
                  value={values.fullname}
                  onBlur={handleBlur}
                  name="fullname"
                  required
                />
                {errors.fullname && touched.fullname && (
                  <span className="block text-sm text-red-600">
                    {errors.fullname}
                  </span>
                )}
              </UIInputWrapper>
              <UIInputWrapper className="w-full">
                <Input
                  variant="outlined"
                  size="lg"
                  label="Email Address"
                  crossOrigin=""
                  onChange={handleChange}
                  value={values.email}
                  onBlur={handleBlur}
                  name="email"
                  required
                />
                {errors.email && touched.email && (
                  <span className="block text-sm text-red-600">
                    {errors.email}
                  </span>
                )}
              </UIInputWrapper>
            </div>
            <Textarea
              variant="outlined"
              size="lg"
              label="Message"
              rows={8}
              onChange={handleChange}
              value={values.message}
              onBlur={handleBlur}
              name="message"
              required
            />
            {errors.message && touched.message && (
              <span className="block text-sm text-red-600">
                {errors.message}
              </span>
            )}
            <ReCAPTCHA
              className="mt-6"
              sitekey={`${REACT_APP_SITE_KEY}`}
              onChange={(e) => setCaptchaToken(e ? e : "")}
            />
            <Button
              type="submit"
              variant="gradient"
              size="md"
              className="mt-8"
              fullWidth
            >
              Send Message
            </Button>
          </form>
        </div>
      )}
    </section>
  );
};

export default Contact;
