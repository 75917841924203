import { gql } from "@apollo/client";

export const GET_ALL_SUBSCRIPTION_AVAILABLE = gql`
  query Sub_getAllSubscription_Available {
    sub_getAllSubscription_Available {
      id
      title
      description
      image
      cost
      costNZ
      frequency
      stripe_price_id
      stripe_product_id
      stripe_product_name
    }
  }
`;

export const GET_SINGLE_SUBSCRIPTION_AVAILABLE = gql`
  query Sub_getAllSubscription_Available($subscriptionId: Int) {
    sub_getAllSubscription_Available(subscriptionId: $subscriptionId) {
      id
      title
      description
      image
      cost
      costNZ
      frequency
      stripe_price_id
      stripe_product_id
      stripe_product_name
    }
  }
`;