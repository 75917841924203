import React from "react";
import UIInputWrapper from "../../../components/ui/InputWrapper";
import UILabel from "../../../components/ui/Label";
import UIInput from "../../../components/ui/Input";
import UIButton from "../../../components/ui/Button";
import { useMutation } from "@apollo/client";
import { CHANGE_PASSWORD } from "../../../api/password";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { passwordSchema } from "../../../validations/UserValidation";

interface ChangePasswordInterface {}

const ChangePassword: React.FC<ChangePasswordInterface> = () => {
  const userId = localStorage.getItem("userId");

  const formData = {
    oldPassword: "",
    newPassword: "",
    userId: Number(userId),
  };

  const [updatePassword] = useMutation(CHANGE_PASSWORD);

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: formData,
    validationSchema: passwordSchema,
    validateOnBlur: true,
    onSubmit: async (values, action) => {
      try {
        const res = await updatePassword({
          variables: { ...values },
        });

        if (res && res.data) {
          toast.success("Password changed successfully.", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2000,
          });
        }
      } catch (e: any) {
        const msg = e.message.toString();
        toast.error(msg)
      }
      action.resetForm();
    },
  });

  return (
    <section className="max-w-md pl-10">
      <h2 className="h3 mb-5 text-primary">Change Password</h2>
      <form onSubmit={handleSubmit}>
        <UIInputWrapper>
          <UILabel htmlFor="oldPassword">
            Old password <span className="text-red-600">*</span>
          </UILabel>
          <UIInput
            id="old_pass"
            type="password" 
            placeholder="Enter your old password"
            name="oldPassword"
            onChange={handleChange}
            value={values.oldPassword}
            onBlur={handleBlur}
          />
          {errors.oldPassword && touched.oldPassword && (
            <span className="block text-sm text-red-600">
              {errors.oldPassword}
            </span>
          )}
        </UIInputWrapper>
        <UIInputWrapper>
          <UILabel htmlFor="newPassword">
            New password <span className="text-red-600">*</span>
          </UILabel>
          <UIInput
            id="new_pass"
            type="password"
            placeholder="Enter your new password"
            name="newPassword"
            onChange={handleChange}
            value={values.newPassword}
            onBlur={handleBlur}
          />
          {errors.newPassword && touched.newPassword && (
            <span className="block text-sm text-red-600">
              {errors.newPassword}
            </span>
          )}
        </UIInputWrapper>
        <UIInputWrapper style={{ width: 200 }}>
          <UIButton className="px-2 py-3 text-sm" type="submit">
            Change Password
          </UIButton>
        </UIInputWrapper>
      </form>
    </section>
  );
};

export default ChangePassword;
