import { gql } from "@apollo/client";

export const USER_STRIPE_INFO_MUTATION = gql`
  mutation Stripe_userStripeInfo(
    $stripeSecretKey: String!
    $publishKey: String!
    $userid: Int!
    $update: Boolean!
  ) {
    stripe_userStripeInfo(
      stripe_secret_key: $stripeSecretKey
      publish_key: $publishKey
      userid: $userid
      update: $update
    )
  }
`;
