/* eslint-disable jsx-a11y/img-redundant-alt */
import { ChangeEvent, FC, useState, useRef, Fragment } from "react";
import {
  CloudArrowUpIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  XMarkIcon,
} from "@heroicons/react/20/solid";
import { Textarea } from "@material-tailwind/react";
import { Transition } from "@headlessui/react";
import { toast } from "react-toastify";

interface DetailsProps {
  formik: any;
  onImagesChange: any;
}

const Details: FC<DetailsProps> = ({ formik, onImagesChange }) => {
  const [uploadedImages, setUploadedImages] = useState<any>([]);
  const [previewImages, setPreviewImages] = useState<any>([]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const inputRef = useRef<HTMLInputElement>(null);
  const handleImageChange = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;

    if (files && files.length > 0) {
      // Check if total uploaded images exceed 3
      if (uploadedImages.length + files.length > 3) {
        toast.warning("You can only upload up to 3 images.");
        return;
      }

      const newUploadedImages = [...uploadedImages];
      const newPreviewImages = [...previewImages];

      // Loop through each selected file
      for (let i = 0; i < files.length; i++) {
        const file = files[i];

        // Check if the file size is larger than 10 MB
        if (file.size > 10 * 1024 * 1024) {
          toast.error("Each image must be less than 10 MB.");
          return;
        }

        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = () => {
          const img = new Image();
          img.src = reader.result as string;

          img.onload = () => {
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");

            const aspectRatio = 16 / 9;
            const targetWidth =
              img.width > img.height ? img.height * aspectRatio : img.width;
            const targetHeight =
              img.width > img.height ? img.height : img.width / aspectRatio;

            canvas.width = targetWidth;
            canvas.height = targetHeight;

            if (ctx) {
              ctx.drawImage(
                img,
                (img.width - targetWidth) / 2,
                (img.height - targetHeight) / 2,
                targetWidth,
                targetHeight,
                0,
                0,
                targetWidth,
                targetHeight,
              );
            }

            const croppedImage = canvas.toDataURL("image/jpeg");
            newUploadedImages.push(file);
            newPreviewImages.push(croppedImage);

            setUploadedImages(newUploadedImages);
            setPreviewImages(newPreviewImages);
            onImagesChange(newUploadedImages);
          };
        };
      }
    }
  };

  const handleDeleteImage = (index: number) => {
    const newUploadedImages = [...uploadedImages];
    const newPreviewImages = [...previewImages];

    newUploadedImages.splice(index, 1);
    newPreviewImages.splice(index, 1);

    setUploadedImages(newUploadedImages);
    setPreviewImages(newPreviewImages);
    onImagesChange(newUploadedImages);

    let newIndex = currentImageIndex;

    if (newPreviewImages.length === 0) {
      newIndex = 0;
    } else if (currentImageIndex >= newPreviewImages.length) {
      newIndex = newPreviewImages.length - 1;
    }
    setCurrentImageIndex(newIndex);
  };

  const nextImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === previewImages.length - 1 ? 0 : prevIndex + 1,
    );
  };

  const prevImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? previewImages.length - 1 : prevIndex - 1,
    );
  };
  return (
    <>
      <div className="w-full">
        <Textarea
          size="lg"
          className="w-full rounded-md border text-sm focus:border-gray-900"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.summary}
          label="Enter Event summary *"
          name="summary"
          maxLength={255}
        />
        {formik.touched.summary && formik.errors.summary && (
          <div className="text-xs text-red-600">{formik.errors.summary}</div>
        )}
      </div>

      <Fragment>
        <label className="mt-5 block text-left  text-sm font-medium text-gray-700">
          Upload Image:
        </label>
        <div className="mb-4">
          <label
            htmlFor="image-upload"
            className="flex cursor-pointer items-center gap-2"
          >
            <CloudArrowUpIcon className="h-5 w-5" />
            <span className="text-blue-500">Upload Image</span>
          </label>
          <input
            ref={inputRef}
            id="image-upload"
            name="image"
            type="file"
            accept="image/*"
            onChange={handleImageChange}
            multiple
            className="sr-only"
          />
        </div>
      </Fragment>

      {previewImages.length > 0 && (
        <div className="relative ">
          <Transition
            show={previewImages.length > 1}
            enter="transition-opacity duration-200"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <button
              onClick={prevImage}
              type="button"
              className="absolute left-1 top-1/2 z-10 -translate-y-1/2 transform rounded-full bg-white p-1 shadow-md"
            >
              <ChevronLeftIcon className="h-6 w-6 text-secondary" />
            </button>
          </Transition>

          <Transition
            show={previewImages.length > 1}
            enter="transition-opacity duration-200"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <button
              onClick={nextImage}
              type="button"
              className="absolute right-1 top-1/2 z-10 -translate-y-1/2 transform rounded-full bg-white p-1 shadow-md"
            >
              <ChevronRightIcon className="h-6 w-6 text-secondary" />
            </button>
          </Transition>

          <div className="absolute bottom-6 left-0 right-0 flex justify-center">
            {previewImages.map((_: any, index: any) => (
              <button
                key={index}
                onClick={() => setCurrentImageIndex(index)}
                type="button"
                className={`mx-1 h-3 w-3 rounded-full ${
                  currentImageIndex === index ? "bg-secondary" : "bg-gray-300"
                }`}
              ></button>
            ))}
          </div>

          <img
            src={previewImages[currentImageIndex]}
            alt={`Preview ${currentImageIndex}`}
            className="h-full w-full rounded-lg border object-cover"
          />
        </div>
      )}

      <div className="mt-2 flex flex-wrap gap-4">
        {previewImages.map((preview: any, index: any) => (
          <div key={index} className="relative rounded-lg">
            <img
              src={preview}
              alt={`Preview ${index}`}
              className="h-24 w-24 rounded-lg border object-cover"
            />
            <button
              onClick={() => handleDeleteImage(index)}
              type="button"
              className="absolute right-1 top-1 rounded-full bg-secondary p-0.5 text-white"
            >
              <XMarkIcon className="h-4 w-4" />
            </button>
          </div>
        ))}
      </div>
    </>
  );
};

export default Details;
