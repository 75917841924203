import React, { createElement, useEffect, useState } from "react";
import { useFormik } from "formik";
import FormFields from "./FormFields/FormFields";
import Details from "./Details";
import AddTicketsSection from "./AddTicketsSection";
import { CREATE_EVENT_MUTATION } from "../../api/create-event";
import { useMutation } from "@apollo/client";
import EventComponent from "../../components/common/EventComponent/EventComponent";
import { useNavigate } from "react-router-dom";
import { UPLOAD_EVENT_IMAGE } from "../../api/event-image-upload";
import { toast } from "react-toastify";
import { CreateEventSection } from "./validation";
import { CreateEventVariables } from "../../types/CreateEventForm";
import { validationSchema, initialValues } from "./validation";
import { useNavigateSearch } from "../../hooks/useNavigateSearch";

const PlanYourEvent: React.FC = () => {
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: onSubmitHandler,
  });

  const [uploadedImages, setUploadedImages] = useState<string[]>([]);
  const [formattedStartDate, setFormattedStartDate] = useState<string>("");
  const [formattedEndDate, setFormattedEndDate] = useState<string>("");

  const [duration, setDuration] = useState<{
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
  } | null>(null);

  const navigate = useNavigate();
  const navigateSearch = useNavigateSearch();

  useEffect(() => {
    const token = localStorage.getItem("token");

    if (!token) {
      navigate("/signin");
    }

    if (formik.values.activeTab === "save" && !formik.isValid) {
      formik.setFieldValue("activeTab", "basicInfo");
    }
  }, [formik.values.activeTab, formik.isValid]);

  const handleDetailsImagesChange = (images: string[]) => {
    setUploadedImages(images);
  };

  const [createEventMutation] = useMutation(CREATE_EVENT_MUTATION);

  const [uploadImageUrlMutation] = useMutation(UPLOAD_EVENT_IMAGE);
  async function onSubmitHandler(values: CreateEventVariables) {
    try {
      formik.setFieldValue("_error", "");
      const userId = localStorage.getItem("userId");
      const uploadedImageUrls = await uploadImages(uploadedImages);

      const apiRequestValues: any = {
        ...values,
        address: formik.values.location,
        userId: Number(userId),
      };
      if (!values.free) {
        apiRequestValues.ticketInfo = values.ticketInfo.map((ticket) => ({
          label: ticket.label,
          description: ticket.description,
          ticketQuantity: ticket.ticketQuantity,
          ticketWarningOn: ticket.ticketWarningOn,
          currency: ticket.currency,
          price: ticket.price,
        }));
      }

      const response = await createEventMutation({
        variables: apiRequestValues,
      });

      if (response.data && response.data?.event_createEvent) {
        const eventId = response.data.event_createEvent;

        for (const image of uploadedImageUrls) {
          await uploadImageUrlMutation({
            variables: {
              userid: Number(userId),
              eventid: eventId,
              imageUrl: image,
            },
          });
        }
        if (eventId) {
          toast.success("Event created successfully!");
          navigateSearch({
            pathname: "/dashboard",
            params: { section: "events", tab: "draft" },
          });
        }
      }
    } catch (error) {
      if (error instanceof Error) {
        if (error.message.startsWith("No stripe info found.")) {
          toast.error("Add your Stripe secret and publish key to continue.");
        } else {
          toast.error("Check all fields, something went wrong.");
        }
      }
      formik.setFieldValue(
        "_error",
        "An error occurred while submitting the form.",
      );
    }
  }

  async function uploadImages(images: string[]): Promise<string[]> {
    const url = `${process.env.REACT_APP_API_URL}/file/upload`;

    const resultUrls: string[] = [];

    const uploadPromises = images?.map(async (image) => {
      const formData = new FormData();
      formData.append("file", image);

      const response = await fetch(url, {
        method: "POST",
        body: formData,
      });

      if (!response.ok) {
        throw new Error("Failed to upload image");
      }

      const result = await response.json();

      resultUrls?.push(result?.image);
    });

    await Promise.all(uploadPromises);
    return resultUrls;
  }

  const [previewData, setPreviewData] = useState<any | null>(null);
  const updatePreviewData = () => {
    setPreviewData({ ...formik.values, uploadedImages });
  };
  useEffect(() => {
    updatePreviewData();
  }, [formik.values, uploadedImages]);

  const handleNext = () => {
    const currentIndex = CreateEventSection.findIndex(
      (section) => section.value === formik.values.activeTab,
    );

    if (currentIndex !== -1 && currentIndex < CreateEventSection.length - 1) {
      const nextTab = CreateEventSection[currentIndex + 1].value;
      if (nextTab === "save" && !formik.isValid) {
        return;
      }
      formik.setFieldValue("activeTab", nextTab);
    }
  };

  const handlePrevious = () => {
    const currentIndex = CreateEventSection.findIndex(
      (section) => section.value === formik.values.activeTab,
    );
    if (currentIndex !== -1 && currentIndex > 0) {
      const prevTab = CreateEventSection[currentIndex - 1].value;
      formik.setFieldValue("activeTab", prevTab);
    }
  };

  const handleFormSubmit = async () => {
    try {
      formik.setFieldValue("_error", "");
      const userId = localStorage.getItem("userId");

      const uploadedImageUrls = await uploadImages(uploadedImages);

      const { data } = await createEventMutation({
        variables: {
          title: formik.values.title,
          description: formik.values.description,
          organizer: formik.values.organizer,
          userId: Number(userId),
          category: formik.values.category,
          location: formik.values.location,
          address: formik.values.location,
          startDate: formik.values.startDate,
          endDate: formik.values.endDate,
          timeZone: formik.values.timeZone,
          language: formik.values.language,
          free: formik.values.free,
          paid: formik.values.paid,
          ticketSaleStartDate: formik.values.ticketSaleStartDate,
          ticketSaleEndDate: formik.values.ticketSaleEndDate,
          summary: formik.values.summary,
          freeTicket: formik.values.freeTicket,
          eventPattern: formik.values.eventPattern,
          ticketInfo: formik.values.ticketInfo,
        },
      });

      if (data && data?.event_createEvent) {
        const eventId = data.event_createEvent;

        for (const image of uploadedImageUrls) {
          await uploadImageUrlMutation({
            variables: {
              userid: Number(userId),
              eventid: eventId,
              imageUrl: image,
            },
          });
        }

        toast.success("Event created successfully!", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
          onClose: () => navigate("/dashboard"),
        });
      }
    } catch (error) {
      toast.error(
        error instanceof Error ? error.message : "Something went wrong",
      );
      formik.setFieldValue(
        "_error",
        "An error occurred while submitting the form.",
      );
    }
  };

  return (
    <div className="mx-auto max-w-5xl px-5 sm:px-6">
      <h1 className="mb-2 text-center text-3xl font-bold">
        Build Your Signature Event, Effortlessly
      </h1>

      <p className="m-auto mb-5 w-2/3 justify-center text-center text-sm text-gray-700">
        Elevate your event experience and create signature conferences,
        workshops, and more with our seamless platform. Designed to empower you
        to craft impactful events effortlessly, our platform guides you through
        every step to tailor your event to perfection. Let's embark on this
        journey together and bring your vision to life.
      </p>

      <form onSubmit={handleFormSubmit}>
        <div className="grid grid-cols-1 md:grid-cols-4">
          <div className="md:col-span-1">
            <div className="grid grid-cols-1 gap-2 rounded-lg bg-gray-100 p-2">
              {CreateEventSection.map(({ label, value, icon }) => (
                <button
                  key={value}
                  onClick={(e) => {
                    e.preventDefault();
                    if (value === "save" && !formik.isValid) {
                      return;
                    }
                    formik.setFieldValue("activeTab", value);
                  }}
                  className={`text-md mb-2 flex items-center rounded-md px-3 py-4 font-medium transition-colors duration-300 hover:bg-white focus:outline-none ${
                    formik.values.activeTab === value
                      ? "bg-white text-gray-800 shadow-sm"
                      : "text-gray-600"
                  }`}
                >
                  {createElement(icon, { className: "w-5 h-5 mr-2" })}
                  <span>{label}</span>
                </button>
              ))}
            </div>
          </div>

          <div className="px-4 md:col-span-3">
            {CreateEventSection.map(({ value }) => (
              <div
                key={value}
                className={`${
                  formik.values.activeTab === value ? "block" : "hidden"
                }`}
              >
                {value === "basicInfo" && <FormFields formik={formik} />}
                {value === "details" && (
                  <Details
                    formik={formik}
                    onImagesChange={handleDetailsImagesChange}
                  />
                )}
                {value === "addTickets" && (
                  <AddTicketsSection formik={formik} />
                )}
                {value === "save" && (
                  <EventComponent
                    previewData={previewData}
                    formattedStartDate={formattedStartDate}
                    formattedEndDate={formattedEndDate}
                    duration={duration}
                  />
                )}
              </div>
            ))}
            <div className="mt-5 flex justify-end">
              <button
                type="button"
                onClick={handlePrevious}
                className="mr-2 rounded-md bg-gray-500 px-4 py-2 text-white shadow"
              >
                Previous
              </button>
              {formik.values.activeTab !== "save" && (
                <button
                  type="button"
                  onClick={handleNext}
                  className="rounded-md bg-primary px-4 py-2 text-white shadow"
                >
                  Next
                </button>
              )}
              {formik.values.activeTab === "save" && (
                <>
                  <button
                    type="button"
                    onClick={() => {
                      formik.handleSubmit();
                    }}
                    className="rounded-md bg-primary px-4 py-2 text-white shadow hover:bg-green-600"
                    disabled={!formik.isValid || formik.isSubmitting}
                  >
                    Submit
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default PlanYourEvent;
