import React from "react";
import { cn } from "../../helpers/cn";
import { Link, LinkProps } from "react-router-dom";

interface UILinkProps extends LinkProps {}

const UILink: React.FC<UILinkProps> = ({
  children,
  className,
  ...rest
}:UILinkProps) => {
  return (
    <Link
      className={cn(
        "btn w-full uppercase font-bold bg-primary border-2 border-primary text-white",
        className
      )}
      {...rest}
    >
      {children}
    </Link>
  );
};

export default UILink;
