import { useState } from "react";
import "../../styles/user/UserInspections.css";

const SingleInspection: React.FC = () => (
  <div className="single_inspection">
    <div className="inspection_item">
      <div className="inspection_date_time">
        <span className="inspection_date">Thursday 26 October</span>
        <span className="inspection_time">7:00 pm-7:30 pm</span>
      </div>
      <div className="inspection_save">
        <button className="book_inspection">Book Now</button>
      </div>
    </div>
  </div>
);

const arr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

export const UserInspections: React.FC = () => {
  const [moreItemsShown, setMoreItemsShown] = useState(false);
  const numberOfItemsShown = moreItemsShown ? arr.length : 4;
  const toggleShow = () => setMoreItemsShown((prevState) => !prevState);
  return (
    <div className="user_inspections">
      <h2>Inspections</h2>
      <div className="items_card">
        {arr.slice(0, numberOfItemsShown).map((i) => (
          <SingleInspection key={i} />
        ))}
      </div>
      <button onClick={toggleShow} className="show_more">
        <strong>Show {moreItemsShown ? " fewer" : "more"} items</strong>
        <span className={`arrow ${moreItemsShown ? "up" : "down"}`}></span>
      </button>
    </div>
  );
};
