import { useMutation } from "@apollo/client";
import React, { FormEvent, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { SET_NEW_PASSWORD } from "../../../api/password";
import { toast } from "react-toastify";
import UIInputWrapper from "../../../components/ui/InputWrapper";
import UILabel from "../../../components/ui/Label";
import UIInput from "../../../components/ui/Input";
import UIButton from "../../../components/ui/Button";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/20/solid";

interface SetNewPasswordInterface {}
const SetNewPassword: React.FC<SetNewPasswordInterface> = () => {
  const { userId, uid } = useParams();
  const [newPass, setNewPass] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  const [passWordsMatch, setPasswordsMatch] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    setPasswordsMatch(newPass === confirmPass);
  }, [newPass, confirmPass]);

  const [ChangePassword] = useMutation(SET_NEW_PASSWORD, {
    variables: {
      newPassword: newPass,
      userId: Number(userId),
      uid: uid,
    },
  });

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    if (passWordsMatch) {
      try {
        const { data } = await ChangePassword();

        if (data) {
          toast.success("Password Changed");
          setNewPass("");
          setConfirmPass("");
        }
      } catch (error: any) {
        if (error.graphQLErrors && error.graphQLErrors.length > 0) {
          const errorMessage = error.graphQLErrors[0].message;
          toast.error(`Error: ${errorMessage}`);
        }
      }
    }
  };
  return (
    <section className="flex flex-col items-center">
      <div className="max-w-[1000px]">
        <h1 className="my-4 mb-4 text-center text-3xl font-semibold">
          Set New Password
        </h1>
        <p className="my-6 max-w-lg text-center text-gray-600">
          Welcome to the password reset page. Please enter your new password
          below to complete the password reset process.
        </p>
        <form className="mt-20 max-w-sm" onSubmit={handleSubmit}>
          <UIInputWrapper>
            <UILabel htmlFor="new-password">New Password: </UILabel>
            <div className="relative flex">
              <UIInput
                id="new-pass"
                type="password"
                placeholder="********"
                value={newPass}
                onChange={(e) => setNewPass(e.target.value)}
              />
              {/* <button
                type="button"
                onClick={togglePasswordVisibility}
                className="absolute right-2 top-1/4 transform focus:outline-none"
              >
                {showPassword ? (
                  <EyeSlashIcon className="h-5 w-5 text-gray-500" />
                ) : (
                  <EyeIcon className="h-5 w-5 text-gray-500" />
                )}
              </button> */}
            </div>
          </UIInputWrapper>
          <UIInputWrapper>
            <UILabel htmlFor="confirm-password">Confirm Password: </UILabel>
            <div className="realtive">
              <UIInput
                id="confirm-pass"
                type="password"
                placeholder="********"
                value={confirmPass}
                onChange={(e) => setConfirmPass(e.target.value)}
              />
              {/* <button
                type="button"
                onClick={togglePasswordVisibility}
                style={{ top: "19rem", right }}
                className="absolute right-2 transform focus:outline-none"
              >
                {showPassword ? (
                  <EyeSlashIcon className="h-5 w-5 text-gray-500" />
                ) : (
                  <EyeIcon className="h-5 w-5 text-gray-500" />
                )}
              </button> */}
            </div>
          </UIInputWrapper>
          <UIInputWrapper>
            <UIButton className="max-w-[150px] px-1 text-xs" type="submit">
              Reset Password
            </UIButton>
          </UIInputWrapper>
        </form>
      </div>
    </section>
  );
};

export default SetNewPassword;
