import { gql } from "@apollo/client";

export const USER_EVENT_PUBLISH = gql`
  mutation Event_updateEventPublishStatus(
    $userid: Int!
    $eventid: Int!
    $planId: Int!
    $status: Boolean!
  ) {
    event_updateEventPublishStatus(
      userid: $userid
      eventid: $eventid
      planId: $planId
      status: $status
    )
  }
`;
