import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { GET_CUSTOMER_TICKET_DETAIL } from "../../../api/Event/getCustomerTicketDetails";
import EventCustomerTicketListing from "../../../types/EventCustomerTicketListing";
import { useQuery } from "@apollo/client";
import { GET_EVENT } from "../../../api/get-event";
import Event from "../../../types/Events";
import moment from "moment-mini";
import {
  CalendarIcon,
  ClockIcon,
  MapPinIcon,
} from "@heroicons/react/24/outline";
import { TicketCardSkeleton } from "../../../components/common/ticketCardSkeleton";
import clockLogo from "../../../assets/images/clocklogo.png";

const Tickets: React.FC = () => {
  const { customerId } = useParams();
  const decodedCustomerId = atob(customerId || "");
  const [loading, setLoading] = useState(true);

  // Fetching ticket details
  const {
    error: ticketDetailError,
    data: ticketDetailData,
    loading: ticketDetailsLoading,
  } = useQuery<{
    event_getCustomerTicketDetails: EventCustomerTicketListing;
  }>(GET_CUSTOMER_TICKET_DETAIL, {
    variables: {
      customerId: Number(decodedCustomerId),
    },
    skip: !decodedCustomerId,
    onCompleted: () => setLoading(false),
  });

  // Fetching event details
  const {
    data: eventDetailsData,
    error: eventDetailsError,
    loading: eventDetailsLoading,
  } = useQuery<{
    iframe_getEventByid: Event;
  }>(GET_EVENT, {
    variables: {
      eventId: Number(ticketDetailData?.event_getCustomerTicketDetails.eventId),
    },
    skip: !ticketDetailData,
    onCompleted: () => setLoading(false),
  });

  // Formatting date and time
  const startTiming = moment(
    new Date(Number(eventDetailsData?.iframe_getEventByid.startDate)),
  ).format("H:mm");
  const endTiming = moment(
    new Date(Number(eventDetailsData?.iframe_getEventByid.endDate)),
  ).format("H:mm");
  const startDate = moment(
    new Date(Number(eventDetailsData?.iframe_getEventByid.startDate)),
  ).format("ddd, D MMM");
  const endDate = moment(
    new Date(Number(eventDetailsData?.iframe_getEventByid.endDate)),
  ).format("ddd, D MMM");

  // Loading state
  if (loading || ticketDetailsLoading || eventDetailsLoading) {
    return <TicketCardSkeleton />;
  }

  // Render ticket details and event information
  return ticketDetailData && eventDetailsData ? (
    <section className="flex flex-col items-center pt-4 md:pt-8">
      <span className="h3 text-secondary">Ticket Details</span>

      <div className="mt-6 max-w-xs items-center rounded-xl shadow-lg md:max-w-md lg:max-w-xl">
        <div className="rounded-t-xl bg-gray-100">
          <img
            className="mx-auto h-36 w-auto py-4"
            alt="event-image"
            src={clockLogo}
          />
        </div>
        <div className="p-6">
          <p className="mb-4 text-center text-2xl font-bold text-secondary">
            {ticketDetailData.event_getCustomerTicketDetails.eventName}
          </p>
          <p className="mb-4 text-center text-xs italic text-gray-800">
            Ticket ID: #
            {ticketDetailData.event_getCustomerTicketDetails.ticketId}
          </p>
          <div className="text-xs font-bold md:text-sm">
            <p className="mb-2 text-secondary">
              Attendee:{" "}
              <span className="text-gray-800">
                {ticketDetailData.event_getCustomerTicketDetails.firstName}{" "}
                {ticketDetailData.event_getCustomerTicketDetails.lastName}
              </span>
            </p>
            <p className="mb-2 text-secondary">
              Email:{" "}
              <span className="text-gray-800">
                {ticketDetailData.event_getCustomerTicketDetails.email}
              </span>
            </p>
            <p className="mb-2 flex text-xs text-gray-700">
              <CalendarIcon className="h-auto w-4" />
              <span className="ml-1">
                Date: {startDate} - {endDate}
              </span>
            </p>
            <p className="mb-2 flex text-xs text-gray-700">
              <ClockIcon className="h-auto w-4" />
              <span className="ml-1">
                Time: {startTiming} - {endTiming}
              </span>
            </p>
            <p className="mb-2 flex text-xs text-gray-700">
              <MapPinIcon className="h-auto w-4" />
              <span className="ml-1">
                Venue: {eventDetailsData?.iframe_getEventByid.address}
              </span>
            </p>
          </div>
          <div className="mt-4">
            {ticketDetailData?.event_getCustomerTicketDetails?.ticketDetails?.map(
              (ticket, index) => (
                <div key={index} className="border-t border-gray-200">
                  <div className="p-4">
                    {ticket.currency !== null ||
                    ticket.label !== null ||
                    ticket.perTicketPrice !== null ||
                    ticket.ticketbooked !== null ||
                    ticket.totalChargespaid !== null ? (
                      <>
                        <p className="text-sm font-medium text-gray-900">
                          {ticket.label || "Ticket Type"}
                        </p>
                        <p className="text-sm text-gray-500">
                          Booked:{" "}
                          {ticket.ticketbooked ||
                            ticketDetailData.event_getCustomerTicketDetails
                              .totalTicketBooked}
                        </p>
                        <p className="text-sm text-gray-500">
                          Price per Ticket: {ticket.perTicketPrice || "0"}{" "}
                          {ticket.currency || "USD"}
                        </p>
                        <p className="text-sm text-gray-500">
                          Total Paid: {ticket.totalChargespaid || "0"}{" "}
                          {ticket.currency || "USD"}
                        </p>
                      </>
                    ) : (
                      <div className="m-auto flex justify-between">
                        <p className="text-base text-gray-500">
                          Booked:{" "}
                          {
                            ticketDetailData.event_getCustomerTicketDetails
                              .totalTicketBooked
                          }
                        </p>
                        <p className="text-base text-gray-500">
                          Total Price:{" "}
                          {
                            ticketDetailData.event_getCustomerTicketDetails
                              .totalPrice
                          }
                        </p>
                        <p className="text-base text-gray-500">
                          Total Paid:{" "}
                          {ticketDetailData.event_getCustomerTicketDetails
                            .totalPrice || "0"}
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              ),
            )}
          </div>
        </div>
      </div>
    </section>
  ) : (
    <p className="flex h-full items-center justify-center text-xl font-bold">
      Oops! Something went wrong. Please try again later.
    </p>
  );
};

export default Tickets;
