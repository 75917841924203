import { useState, useEffect, useCallback } from "react";
import { getUserDetails } from "../helpers/users";

const useStripeStatus = (): {
  stripeStatus: boolean;
  updateLocalStorage: (paymentId: string, customerId: string) => void;
} => {
  const { stripeInfo } = getUserDetails();
  const [stripeStatus, setStripeStatus] = useState<boolean>(false);
  const updateLocalStorage = useCallback(
    (paymentId: string, customerId: string) => {
      const isStripeInfoPresent = paymentId !== "" && customerId !== "";
      localStorage.setItem("stripeInfo", JSON.stringify(isStripeInfoPresent));
      setStripeStatus(isStripeInfoPresent);
    },
    [],
  );

  useEffect(() => {
    if (stripeInfo) {
      setStripeStatus(JSON.parse(stripeInfo));
    }
  }, []);

  return { stripeStatus, updateLocalStorage };
};

export default useStripeStatus;
