import React, { useState } from "react";
import DatePicker from "tailwind-datepicker-react";
import { IOptions } from "tailwind-datepicker-react/types/Options";
import { ISelect } from "../../types/Select";

const CommonDatePicker:React.FC<ISelect> = ({selected,setSelected}) => {
  const [show, setShow] = useState<boolean>(false);

  const options: IOptions = {
    autoHide: true,
    todayBtn: true,
    clearBtn: true,
    inputDateFormatProp: {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    },
    theme: {
      background: "",
      todayBtn: "",
      clearBtn: "",
      icons: "",
      text: "",
      disabledText: "",
      input: "",
      inputIcon: "",
      selected: "",
    },
  };

  return (
    <DatePicker
      show={show}
      value={selected}
      onChange={(date) => setSelected(date)}
      setShow={(state) => setShow(state)}
      options={options}
    />
  );
};

export default CommonDatePicker;
