import React from "react";

const TermsAndConditions: React.FC = () => {
  return (
    <section className="relative bg-white px-4 py-8">
      <div className="container mx-auto my-8">
        <h1 className="mb-4 text-center text-4xl font-bold">
          Terms of Service
        </h1>
        <li className="mb-4 text-center text-gray-600">
          Last Updated: [22 Jan 2024]
        </li>
        <div className="m-auto max-w-4xl justify-center p-0">
          {/* Section 1 */}
          <section className="mb-6">
            <h2 className="mb-4 text-2xl font-semibold">Use of the Website</h2>
            <ul className="ml-6 list-disc">
              <li>You must be at least 18 years old to use this website.</li>
              <li>
                You agree to provide accurate and complete information when
                using our services.
              </li>
            </ul>
          </section>

          {/* Section 2 */}
          <section className="mb-6">
            <h2 className="mb-4 text-2xl font-semibold">
              Intellectual Property
            </h2>
            <ul className="ml-6 list-disc">
              <li>
                All content on this website, including text, graphics, logos,
                and images, is the property of Bookable Online and is protected
                by copyright.
              </li>
              <li>
                You may not use our intellectual property without our prior
                written consent.
              </li>
            </ul>
          </section>

          {/* Section 3 */}
          <section className="mb-6">
            <h2 className="mb-4 text-2xl font-semibold">
              Prohibited Activities
            </h2>
            <ul className="ml-6 list-disc">
              <li>
                You agree not to engage in any illegal or unauthorized
                activities on our website.
              </li>
              <li>
                Any attempt to interfere with the proper functioning of the site
                is strictly prohibited.
              </li>
            </ul>
          </section>

          {/* Section 4 */}
          <section className="mb-6">
            <h2 className="mb-4 text-2xl font-semibold">
              Limitation of Liability
            </h2>
            <ul className="ml-6 list-disc">
              <li>
                We are not liable for any direct, indirect, incidental, or
                consequential damages resulting from your use of our website.
              </li>
              <li>
                We do not guarantee the accuracy or completeness of the
                information on our site.
              </li>
            </ul>
          </section>

          {/* Section 5 */}
          <section className="mb-6">
            <h2 className="mb-4 text-2xl font-semibold">Governing Law</h2>
            <li className="mb-4">
              These terms and conditions are governed by and construed in
              accordance with the laws of Mohali, India.
            </li>
          </section>

          {/* Section 6 */}
          <section className="mb-6">
            <h2 className="mb-4 text-2xl font-semibold">Contact Us</h2>
            <li>
              If you have any questions or concerns about our Terms of Service,
              please contact us at{" "}
              <a
                className="text-blue-500"
                href="mailto:abhinav.workmail@gmail.com"
              >
                abhinav.workmail@gmail.com
              </a>
              .
            </li>
          </section>
        </div>
      </div>
    </section>
  );
};

export default TermsAndConditions;
