import React, { LabelHTMLAttributes } from "react";
import { cn } from "../../helpers/cn";

interface LapelProps extends LabelHTMLAttributes<HTMLLabelElement> {}

const UILabel: React.FC<LapelProps> = ({
  children,
  className = "",
  ...rest
}) => {
  return (
    <label
      className={cn("text-md mb-2 block font-medium text-gray-900 ", className)}
      {...rest}
    >
      {children}
    </label>
  );
};

export default UILabel;
