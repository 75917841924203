import { gql } from "@apollo/client";

export const GET_BOOKING_CUSTOMER_DETAIL = gql`
  query Event_getBookingCustomersList(
    $eventid: Int!
    $limit: Int!
    $offset: Int!
    $presence: Boolean
  ) {
    event_getBookingCustomersList(
      eventid: $eventid
      limit: $limit
      offset: $offset
      presence: $presence
    ) {
      customerId
      firstName
      lastName
      email
      phoneNumber
      eventId
      eventName
      ticketId
      totalTicketBooked
      totalPrice
      presence
      paymentTime
      ticketDetails {
        label
        ticketbooked
        perTicketPrice
        totalChargespaid
        currency
      }
    }
  }
`;

export const GET_BOOKING_CUSTOMER_TOTAL_COUNT = gql`
  query Query($eventId: Int!) {
    event_countEventBookingCustomer(eventId: $eventId)
  }
`;
