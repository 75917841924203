import React from "react";
import HeroLanding from "./HeroLanding";
import Slides from "./Slides";
import StartCards from "./StartCards";
import UILink from "../../components/ui/Link";

const Landing: React.FC = () => {
  return (
    <>
      <HeroLanding />
      <StartCards />
      <div className="flex items-center justify-center pb-10">
        <UILink
          to={"/pricing"}
          type="button"
          className="border-secondary bg-secondary px-4 py-2 hover:text-secondary hover:bg-white max-w-xs mx-1"
        >
          Get Started
        </UILink>
      </div>
      {/* <Slides /> */}
    </>
  );
};

export default Landing;
