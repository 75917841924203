import moment from "moment-mini";

export const getUserDetails = () => {
  const loginTime = localStorage.getItem("loginTime");
  let token = null;
  let userId = null;
  let name = null;
  let email = null;
  let phoneNumber = null;
  let totalEvent = null;
  let liveEvent = null;
  let draftEvent = null;
  let stripeInfo = null;
  const isUserAuthorised = moreThanOneDayAgo(loginTime);
  if (isUserAuthorised) {
    token = localStorage.getItem("token");
    userId = localStorage.getItem("userId");
    name = localStorage.getItem("name");
    email = localStorage.getItem("email");
    phoneNumber = localStorage.getItem("phoneNumber");

    totalEvent = parseInt(localStorage.getItem("totalEvent") || "0");
    liveEvent = parseInt(localStorage.getItem("liveEvent") || "0");
    draftEvent = parseInt(localStorage.getItem("draftEvent") || "0");
    stripeInfo = localStorage.getItem("stripeInfo");
  } else {
    localStorage.clear();
  }
  return {
    token,
    userId,
    name,
    email,
    phoneNumber,
    totalEvent,
    liveEvent,
    draftEvent,
    stripeInfo,
  };
};

export const moreThanOneDayAgo = (loginTime: string | null) => {
  if (loginTime) {
    const DAY = 24;
    const now = moment();
    const differenceInLoginTime = now.diff(loginTime, "hours");
    return differenceInLoginTime < DAY;
  } else {
    return false;
  }
};

export const getAvatarName = (name: string): string => {
  const nameArray = name.split(" ");
  const firstName = nameArray[0];
  const lastName = nameArray.length > 1 ? nameArray[nameArray.length - 1] : "";
  return `${firstName.charAt(0)}${lastName.charAt(0)}`;
};
