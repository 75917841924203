/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import Payment from "./Payment";
import YourEvent from "./YourEvent";
import Stats from "./Stats";
import {
  CalendarDaysIcon,
  CreditCardIcon,
  CurrencyDollarIcon,
  PlusCircleIcon,
} from "@heroicons/react/20/solid";
import { getAvatarName, getUserDetails } from "../../helpers/users";
import ChangePassword from "../Auth/ChangePassword";
import Subscription from "../subscription";
import { SubscriptionListing } from "../../types/SubscriptionListing";
import { useQuery } from "@apollo/client";
import { GET_ALL_SUBSCRIPTION_SUBSCRIBED } from "../../api/Payment/getAllSubscriptionSubscribed";
import { useCustomQuery } from "../../hooks/useCustomQuery";
import { useNavigateSearch } from "../../hooks/useNavigateSearch";
import WebIntegration from "./WebIntegration";

interface User {
  userName: string;
}

interface MenuItem {
  id: number;
  name: string;
  section: string;
  icon: JSX.Element;
}

const Dashboard: React.FC = () => {
  const pathName = "/dashboard";
  const { name } = getUserDetails();
  const user: User = {
    userName: name || "No Data",
  };
  const navigateSearch = useNavigateSearch();
  const query = useCustomQuery();
  const section = query.get("section") || "";
  const tab = query.get("tab") || "";

  const menuItems: MenuItem[] = [
    {
      id: 1,
      name: "Payment",
      section: "payment",
      icon: <CurrencyDollarIcon className="h-6 w-6" />,
    },
    {
      id: 2,
      name: "Integration",
      section: "integration",
      icon: <PlusCircleIcon className="h-6 w-6" />,
    },
    {
      id: 3,
      name: "Events",
      section: "events",
      icon: <CalendarDaysIcon className="h-6 w-6" />,
    },
    {
      id: 4,
      name: "Change Password",
      section: "change-password",
      icon: <img src="/change-password-icon.svg" className="h-6 w-6" />,
    },
    {
      id: 5,
      name: "Subscription",
      section: "subscription",
      icon: <CreditCardIcon className="h-6 w-6" />,
    },
  ];

  const handleMenuItemChange = (menuItem: MenuItem) => {
    navigateSearch({
      pathname: pathName,
      params: { section: menuItem.section },
    });
  };

  const getMenuItemClass = (menuItem: MenuItem) =>
    `text-gray-900 hover:text-gray-900 ${
      section === menuItem.section ? "border-b-2 border-primary " : ""
    }`;

  const [subscriptionData, setSubscriptionData] = useState<
    SubscriptionListing[] | null
  >(null);
  const userId = Number(localStorage.getItem("userId"));
  const { data, error, loading } = useQuery<{
    sub_getSubscriptionSubscribedList: SubscriptionListing[];
  }>(GET_ALL_SUBSCRIPTION_SUBSCRIBED, {
    variables: {
      limit: 100,
      offset: 0,
      userId: userId,
    },
  });

  useEffect(() => {
    if (data) {
      setSubscriptionData(data.sub_getSubscriptionSubscribedList);
    }
  }, [data]);

  const completedSubscriptions =
    data &&
    data.sub_getSubscriptionSubscribedList.filter(
      (subscription) => subscription.status === "active",
    );
  const userPlanId =
    completedSubscriptions &&
    completedSubscriptions?.map((subscription) => subscription?.planId);

  return (
    <>
      <div
        className="bg-gray-0 m-auto  mx-auto max-w-7xl px-5 sm:flex  sm:px-6"
        style={{ height: "auto" }}
      >
        <div className="xs:w-full mb-7 rounded border bg-white shadow-sm md:h-screen md:w-64">
          <div className="mt-0 text-center shadow-sm ">
            <div className="mt-10 flex justify-center">
              {" "}
              <div className="xs:h-12 xs:w-12 flex h-20 w-20 items-center justify-center rounded-full bg-primary font-bold text-white">
                <p className="text-2xl">
                  {getAvatarName(name || "Not Available")}
                </p>
              </div>
            </div>
            <p className="mt-2 pb-5 text-lg font-semibold text-black">
              {user.userName}
            </p>
          </div>
          <nav className="mb-10 ml-5 flex flex-col md:mt-10 md:items-center">
            <ul>
              {menuItems.map((menuItem: MenuItem) => (
                <li key={menuItem.id} className="my-5">
                  <a
                    href="#"
                    className={getMenuItemClass(menuItem)}
                    onClick={() => handleMenuItemChange(menuItem)}
                  >
                    <span className="flex md:items-center">
                      <div
                        className={`flex items-center ${
                          section === menuItem.section ? "text-primary" : ""
                        }`}
                      >
                        <span className="mx-2">{menuItem.icon}</span>
                        <span className=" text-lg">{menuItem.name}</span>
                      </div>
                    </span>
                  </a>
                </li>
              ))}
            </ul>
          </nav>
        </div>

        <div className="xs:p-2 flex-grow">
          {(() => {
            switch (section) {
              case "payment":
                return <Payment />;
              case "integration":
                return (
                  <WebIntegration
                  pathName={pathName}
                  section={section}
                  tab={tab}
                  />
                );
              case "events":
                return (
                  <YourEvent
                    pathName={pathName}
                    section={section}
                    tab={tab}
                    userPlanId={userPlanId}
                  />
                );
              case "change-password":
                return <ChangePassword />;
              case "subscription":
                return (
                  <Subscription
                    data={subscriptionData}
                    error={error}
                    loading={loading}
                  />
                );
              default:
                return <Stats />;
            }
          })()}
        </div>
      </div>
    </>
  );
};

export default Dashboard;
