import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
} from "@apollo/client";

const unAuthorizedPaths = [
  "event",
  "embedded-event",
  "contact",
  "success",
  "events",
  "forgot-password",
  "tickets",
  "set-new-password"
]

const { REACT_APP_API_URL, REACT_APP_TOKEN } = process.env;

const httpLink = new HttpLink({ uri: `${REACT_APP_API_URL}/graphql` });

const authLink = new ApolloLink((operation, forward) => {

const pathname = window.location.pathname;
  // Retrieve the authorization token from local storage.
  const token = unAuthorizedPaths.includes(pathname.split('/')[1]) ? REACT_APP_TOKEN : localStorage.getItem("token");

  // Use the setContext method to set the HTTP headers.
  operation.setContext({
    headers: {
      authorization: token && token.length > 0 ? `${token.toString()}` : "",
    },
  });

  // Call the next link in the middleware chain.
  return forward(operation);
});

export const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});
