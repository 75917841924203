import React, { useMemo, useState, useEffect } from "react";
import { OrderItem, OrderSummaryProps } from "../../types/OrderSummary";

const OrderSummary: React.FC<OrderSummaryProps> = ({
  eventData,
  orderDetails,
  totalPriceFinal,
  minTicketSelected,
}) => {
  const formattedStartDate = useMemo(() => {
    return new Date(eventData.startDate * 1000).toLocaleString("en", {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  }, [eventData.startDate]);

  const currencies = orderDetails[0]?.currency;

  const subtotal = useMemo(() => {
    return `${currencies} ${totalPriceFinal.toFixed(2)}`;
  }, [currencies, totalPriceFinal]);
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  return (
    <div className={`bg-gray-50 md:p-5 md:pt-10 ${isMounted ? "fade-in" : ""}`}>
      <div className="m-auto rounded p-2 text-center">
        {eventData.imageUrls?.length > 0 && (
          <img
            className="rounded md:max-w-xs"
            src={`${process.env.REACT_APP_EVENT_IMAGE}/${eventData.imageUrls[0]?.url}`}
            alt="event"
            onError={(e) => {
              e.currentTarget.onerror = null;
              e.currentTarget.src =
                "https://img.freepik.com/free-vector/flat-design-no-photo-sign_23-2149272417.jpg?w=740&t=st=1705735041~exp=1705735641~hmac=7f0dbe96496657ecd3156c10388d2a11771fbed93e8b715f3d72cf2f221526e0";
            }}
          />
        )}

        <h6 className="mt-2 font-semibold text-gray-900 sm:text-xl md:text-2xl">
          {eventData.title}
        </h6>
        <p className="text-sm text-gray-700">{formattedStartDate}</p>
      </div>

      {eventData && !minTicketSelected && orderDetails.length < 1 && (
        <div className="mt-4 border-t border-gray-200 pt-4">
          {eventData.free !== 1 && (
            <p className="text-center text-red-600">
              Please select at least one Ticket.
            </p>
          )}
        </div>
      )}
      {eventData && orderDetails.length > 0 && (
        <div className="mt-4 border-t border-gray-200  p-4">
          <h4 className="mb-3 text-lg font-semibold text-gray-800">
            Order Summary
          </h4>
          <ul className="list-disc space-y-2">
            {orderDetails.map((item: OrderItem, index: number) => (
              <li key={index} className="flex justify-between text-base">
                <span>{`${item.quantity} x ${item.label}`}</span>
                <span className="text-gray-700">{`${item.currency} ${(
                  item.quantity * item.pricePerTicket
                ).toFixed(2)}`}</span>
              </li>
            ))}
          </ul>
          <div className="mt-3 flex justify-between border-t-2 pb-2 pt-4 md:mt-6">
            <span className="text-gray-700">Subtotal</span>
            <span className="text-gray-700">{subtotal}</span>
          </div>
          <div className="flex justify-between border-b-2 pb-2">
            <span className="text-gray-700">Fees</span>
            <span className="text-gray-700">
              {orderDetails[0]?.currency} 0.00
            </span>
          </div>
          <div className="mt-5 flex justify-between pt-2 text-lg font-semibold">
            <span>Total</span>
            <span className="text-gray-900">{subtotal}</span>
          </div>
          <p className="text-right text-sm text-gray-600">Price includes tax</p>
        </div>
      )}
    </div>
  );
};

OrderSummary.defaultProps = {
  eventData: {
    title: "",
    free: 1,
    startDate: 0,
    imageUrls: [{ url: "" }],
  },
  orderDetails: [],
  totalPriceFinal: 0,
};

export default OrderSummary;
