import React from "react";
import timeSaving from "../../assets/images/howItWorks/timeSaving.png";
import trial from "../../assets/images/howItWorks/trial.png";
import eventPricing from "../../assets/images/howItWorks/eventPricing.png";
import paymentGateway from "../../assets/images/howItWorks/paymentGateWay.png";
import websiteIntegration from "../../assets/images/howItWorks/WebsiteIntegration.png";
import ListingCard from "../../components/common/ListingCard";
import realEstateInspectionBG from "../../assets/images/howItWorks/real-estate-inspections.png";
import sessionsBG from "../../assets/images/howItWorks/session.png";
import appointmentManagementBG from "../../assets/images/howItWorks/appointment-management.png";
import { Link } from "react-router-dom";
import { indianSite } from "../../helpers/site-info";

const HowItWorks: React.FC = () => {
  const howItWorksSlides = [
    {
      step: "Time-saving",
      desc: "Automate tasks and streamline processes with a single tool, eliminating the need for multiple platforms.",
      url: timeSaving,
      alt: "time-saving",
    },
    {
      step: "Trial Plans and Customization",
      desc: "Tailor your event management experience with our trial plans (your first two events are on us). Customise even the smallest details to suit your unique requirements, ensuring a bespoke event that reflects your vision.",
      url: trial,
      alt: "trials and customization",
    },
    {
      step: "No Per-Ticket Charges",
      desc: "We believe in transparent pricing.One time payment and no restriction on event size. Our commitment to you means no additional charges per ticket.",
      url: eventPricing,
      alt: "transparent pricing",
    },
    {
      step: "Personalised Payment Gateways",
      desc: "Each client receives their own dedicated payment gateway, ensuring a seamless and secure transaction experience for both you and your attendees. Integrate your existing payment account (Stripe)",
      url: paymentGateway,
      alt: "secure payment gateway",
    },
    {
      step: "Website Integration",
      desc: "Effortlessly integrate our event management capabilities into your existing website. Enjoy the convenience of event management functionalities without any hassle, creating a cohesive online experience for your audience.",
      url: websiteIntegration,
      alt: "simple web integration",
    },
  ];

  const howItWorksCards = [
    {
      bgImage: realEstateInspectionBG,
      step: "Real estate inspections",
      url: "/event-management",
    },
    {
      bgImage: sessionsBG,
      step: "Book a session/hobby class",
      url: "#",
    },
    {
      bgImage: appointmentManagementBG,
      step: "Online/offline appointments",
      url: "#",
    },
  ];

  const introLine = indianSite
    ? " Bookable proudly provides an all-in-one platform that makes event planning easy."
    : "Bookable made in Auckland provides a trustworthy all-in-one Event planning solution.";
  return (
    <>
      <p className="sm: my-4 pt-8 text-center text-4xl font-bold text-secondary">
        How it <span className="text-primary">works</span>
      </p>
      <p className="mx-auto max-w-[300px] text-center text-gray-700 sm:max-w-[800px] sm:text-lg">
        {introLine} Built from the ground up, it sets itself apart by avoiding
        the need of multiple third party products or filling gaps with
        integrations. Every feature seamlessly collaborates, offering a truly
        cohesive and powerful event management experience tailored to the needs
        of event planners.
      </p>
      <section>
        {howItWorksSlides.map((item, index) => (
          <div
            key={index}
            className={`mx-auto my-2 h-auto  max-w-[1000px] flex-none px-4 sm:flex 
            ${index % 2 === 0 ? "flex-row" : "flex-row-reverse"}`}
          >
            <span className="mx-4 w-3/5">
              <img
                src={item.url}
                className="h-full max-h-[300px] w-auto rounded-xl"
                alt={item.alt}
                loading="lazy"
              />
            </span>
            <span className="sm: flex w-2/5 w-full flex-col justify-center">
              <span className="text-md mb-4 px-2 text-left font-bold text-primary sm:text-3xl">
                {item.step}
              </span>
              <p className="px-2 text-left text-sm leading-6 text-gray-700 sm:text-lg">
                {item.desc}
              </p>
            </span>
          </div>
        ))}
      </section>
      <p className="mx-auto mt-8 w-2/3 text-center text-sm text-gray-700 sm:text-lg">
        Choose Bookable Event Management for a partnership that goes beyond
        events – we're here to elevate your experiences and leave a lasting
        impression on your audience.
      </p>

      {/* <div className="px-4">
        <p className="my-8 text-center text-3xl font-bold text-primary">
          Upcoming Feature
        </p>
        <div className="flex flex-col items-center sm:flex-row sm:justify-evenly">
          {howItWorksCards.map((items, index) => (
            <Link key={index} to={items.url}>
              <ListingCard
                key={index}
                name={items.step}
                bgImage={items.bgImage}
                comming={true}
              />
            </Link>
          ))}
        </div>
      </div> */}
    </>
  );
};

export default HowItWorks;
