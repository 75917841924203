import React, { useEffect, useState } from "react";
import PageTitle from "../../components/ui/PageTitle";
import { Card, CardFooter, Button, Spinner } from "@material-tailwind/react";
import { ArrowRightIcon } from "@heroicons/react/20/solid";
import { Link } from "react-router-dom";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  FREE_TICKET_PAYMENT_STATUS,
  STATUS_PAYMENT,
} from "../../api/book-ticket";
import { toast } from "react-toastify";
import { GET_EVENT } from "../../api/get-event";
import { GET_CUSTOMER_TICKET_DETAIL } from "../../api/Event/getCustomerTicketDetails";
import { useCustomQuery } from "../../hooks/useCustomQuery";
import QrCode from "./Qrcode";

const Success: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(false);

  const query = useCustomQuery();

  const paymentStatus = query.get("paymentstatus");
  const customerid = query?.get("customerid");
  const [pngImageUrl, setPngImageUrl] = useState("");

  const eventId = query?.get("eventid");
  const totalPrice = query?.get("totalPrice");
  const [paidPaymentStatus] = useMutation(STATUS_PAYMENT);
  const [freePaymentStatus] = useMutation(FREE_TICKET_PAYMENT_STATUS);
  const [
    getCustomerBookingData,
    { data: customerBookingData, error: customerBookingError },
  ] = useLazyQuery(GET_CUSTOMER_TICKET_DETAIL, {
    variables: {
      customerId: Number(customerid),
    },
  });

  const setPaymentStatus = async () => {
    setLoading(true);

    try {
      if (
        customerid &&
        paymentStatus &&
        totalPrice &&
        Number(totalPrice) === 0
      ) {
        const res = await freePaymentStatus({
          variables: {
            customerid: Number(customerid),
            totalPrice: totalPrice,
            paymentStatus: true,
          },
        });
        if (
          res?.data?.event_updateCustomerPaymentStatusForFreeEvent ===
          "payment status successfully updated"
        ) {
          setStatus(true);
          getCustomerBookingData();
        } else {
          setStatus(false);
        }
      } else {
        if (
          customerid &&
          paymentStatus &&
          totalPrice &&
          Number(totalPrice) !== 0
        ) {
          const res = await paidPaymentStatus({
            variables: {
              customerid: Number(customerid),
              totalPrice: totalPrice,
              paymentStatus: Boolean(paymentStatus),
            },
          });
          if (
            res?.data?.event_updateCustomerPaymentStatus ===
            "payment status successfully updated"
          ) {
            setStatus(true);
            getCustomerBookingData();
          } else {
            setStatus(false);
          }
        }
      }
    } catch (error) {
      console.error("Error occurred while setting payment status:", error);
      toast.error("Unable to book tickets. Please try again later.");
      setStatus(false);
    }

    setLoading(false);
  };

  useEffect(() => {
    setPaymentStatus();
  }, []);

  const { data: eventDetailsData, error: eventDetailsError } = useQuery(
    GET_EVENT,
    {
      variables: { eventId: Number(eventId) },
    },
  );

  if (loading) {
    return (
      <section className="px-4 py-10 text-center">
        <div className="flex h-20 w-full justify-center">
          <Spinner className="h-12 w-12" />
        </div>
      </section>
    );
  } else if (status) {
    return (
      <section className="px-4 py-10 text-center">
        <div className="container mx-auto flex flex-col justify-center">
          <PageTitle section="Thank you" heading="Payment successful" />

          <div className="mt-6"></div>
          <Card className="mx-auto mt-6 w-72 items-center rounded-xl bg-gray-100 shadow-lg md:w-96">
            <QrCode
              eventDetailsData={eventDetailsData}
              customerid={customerid}
              setPngImageUrl={setPngImageUrl}
              customerBookingData={customerBookingData}
            />
            <CardFooter className="pt-0">
              <Link to={"/"} className="inline-block">
                <Button
                  size="sm"
                  variant="text"
                  className="flex items-center gap-2"
                >
                  Back to home
                  <ArrowRightIcon className="h-4 w-4" />
                </Button>
              </Link>
            </CardFooter>
          </Card>
        </div>
      </section>
    );
  } else {
    return (
      <section className="flex min-h-[40vh] items-center px-4 py-10 text-center">
        <PageTitle heading="Oops! Something went wrong. Please try again later." />
      </section>
    );
  }
};

export default Success;
