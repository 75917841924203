import * as yup from "yup";

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

// add global method to check password strength

export const userSchema = yup.object().shape({
  name: yup.string().min(2, "Too Short").required("Name is a required field"),
  userName: yup
    .string()
    .min(2, "Too Short")
    .required("User Name is a required field"),
  email: yup
    .string()
    .email("Email is not valid")
    .required("Email is a required field"),
  phoneNumber: yup
    .string()
    .min(8, "Phone Number must be at least 8 characters")
    .max(10, "Phone Number must be at most 10 characters")
    .matches(phoneRegExp, "Phone Number is not valid")
    .required("Phone Number is a required field"),
  password: yup
    .string()
    .required("Please Enter your password")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character",
    ),
  confirm: yup
    .string()
    .oneOf([yup.ref("password")], "Passwords don't match")
    .required("Please confirm your password"),

});

export const contactUsSchema = yup.object().shape({
  fullname: yup
    .string()
    .min(2, "Too Short")
    .required("Name is a required field"),
  email: yup
    .string()
    .email("Email is not valid")
    .required("Email is a required field"),
  message: yup
    .string()
    .min(10, "Minimum 10 characters")
    .max(1000, "Max 1000 characters")
    .required("Text Message is a required field"),
});

export const pricingSchema = yup.object().shape({
  name: yup.string().min(2, "Too Short").required("Name is a required field"),
  email: yup
    .string()
    .email("Email is not valid")
    .required("Email is a required field"),
  phone: yup
    .string()
    .min(10, "Phone Number must be at least 10 characters")
    .matches(phoneRegExp, "Phone Number is not valid")
    .required("Phone Number is a required field"),
});

export const ticketUserSchema = yup.object().shape({
  firstName: yup
    .string()
    .min(2, "Too Short")
    .required("First Name is a required field"),
  lastName: yup
    .string()
    .min(2, "Too Short")
    .required("Last Name is a required field"),
  email: yup
    .string()
    .email("Email is not valid")
    .required("Email is a required field"),
  phoneNumber: yup
    .string()
    .min(8, "Phone Number must be at least 8 characters")
    .max(10, "Phone Number must be at most 10 characters")
    .matches(phoneRegExp, "Phone Number is not valid")
    .required("Phone Number is a required field"),
});

export const passwordSchema = yup.object().shape({
  oldPassword: yup
    .string()
    .required("Please Enter your old password")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character",
    ),
  newPassword: yup
    .string()
    .required("Please Enter your new password")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character",
    ),
});
