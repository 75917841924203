import React from "react";
import { useQuery } from "@apollo/client";
import { GET_SINGLE_SUBSCRIPTION_AVAILABLE } from "../../api/Payment/getAvailableSubscription";
import { indianSite, siteCurrency } from "../../helpers/site-info";

const SubscriptionDetails: React.FC = () => {
  const subscriptionId = localStorage.getItem('subscription_id')
  const { loading, error, data } = useQuery(GET_SINGLE_SUBSCRIPTION_AVAILABLE, {
    variables: {
      subscriptionId: Number(subscriptionId),
    },
  });  
  if (loading) {
    return (
      <>
        <h1 className="mb-5 font-sans text-xl font-semibold">Subscription details</h1>
        <div className="flex md:min-w-[14rem] w-full animate-pulse flex-col justify-start rounded-2xl border-[1px] border-blue-100 bg-white p-4 shadow-lg">
          <h1 className="mb-3 font-sans text-2xl font-semibold text-secondary">
            Plan
          </h1>
          <div className="mx-auto mb-5 h-5 w-40 rounded-xl bg-gray-200"></div>
          <div className="mt-3 block text-center">
            <div className="mx-auto mb-5 h-5 w-40 rounded-xl bg-gray-200"></div>
          </div>
        </div>
      </>
    );
  } else if (error) {
    return (
      <div className="flex md:min-w-[14rem] w-full flex-col justify-start rounded-2xl border-[1px] border-blue-100 bg-white p-4 shadow-lg">
        <h1 className="text-center font-sans text-2xl font-semibold text-secondary">
          Something went wrong. Unable to fetch plan details.
        </h1>
      </div>
    );
  } else if (
    data &&
    data.sub_getAllSubscription_Available &&
    data.sub_getAllSubscription_Available.length > 0
  ) {
    const details = data.sub_getAllSubscription_Available[0];
    return (
      <>
        <h1 className="mb-5 font-sans text-xl font-semibold">Subscription details</h1>
        <div className="flex md:min-w-[14rem] w-full flex-col justify-start rounded-2xl border-[1px] border-blue-100 bg-white p-4 shadow-lg">
          <h1 className="mb-3 font-sans text-2xl font-semibold  text-secondary">
            Plan
          </h1>
          <h5 className="h5 rounded-lg bg-primary px-4 py-1 text-center font-bold text-white shadow">
            {details.title}
          </h5>
          <div className="mt-3 block text-center">
            <p className="text-gray-500">
              {`${siteCurrency} ${indianSite ? details.cost : details.costNZ}`}{" "}
              / {details.frequency}
            </p>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <div className="flex w-56 flex-col justify-start rounded-2xl border-[1px] border-blue-100 bg-white p-4 shadow-lg">
        <h1 className="text-center font-sans text-2xl font-semibold text-secondary">
          Something went wrong. Unable to fetch plan details.
        </h1>
      </div>
    );
  }
};

export default SubscriptionDetails;
