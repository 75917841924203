import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import React, { useState } from "react";

interface Image {
  id: number | string;
  url: string;
}

interface EventImageCarouselProps {
  images?: Image[];
}

const EventImageCarousel: React.FC<EventImageCarouselProps> = ({
  images = [],
}) => {
  const finalImage = images?.length;
  const [currentIndex, setCurrentIndex] = useState<number>(0);

  const goToPrevious = (): void => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? finalImage - 1 : prevIndex - 1,
    );
  };

  const goToNext = (): void => {
    setCurrentIndex((prevIndex) =>
      prevIndex === finalImage - 1 ? 0 : prevIndex + 1,
    );
  };

  return (
    <div className="relative h-[180px] w-full overflow-hidden">
      {finalImage > 1 && (
        <>
          <button
            className="absolute left-2 top-1/2 z-10 -translate-y-1/2 rounded-full bg-gray-100 bg-opacity-60 p-1 transition hover:bg-gray-200"
            onClick={goToPrevious}
          >
            <ChevronLeftIcon className="h-4 w-4 text-black" />
          </button>

          <button
            className="absolute right-2 top-1/2 z-10 -translate-y-1/2 rounded-full bg-gray-100 bg-opacity-60 p-1 transition hover:bg-gray-200"
            onClick={goToNext}
          >
            <ChevronRightIcon className="h-4 w-4 text-black" />
          </button>
        </>
      )}

      <div
        className="flex h-full w-full transition-transform duration-500 ease-in-out"
        style={{
          transform: `translateX(-${currentIndex * 100}%)`,
          width: `${finalImage * 100}%`,
        }}
      >
        {finalImage > 0 ? (
          images.map((image, idx) => (
            <img
              key={image.id}
              src={process.env.REACT_APP_EVENT_IMAGE + "/" + image.url}
              alt={`carousel-${idx}`}
              className="h-[180px] w-full object-cover"
              style={{ flexShrink: 0, width: "100%" }} 
              loading="lazy" 
              onError={(e) => {
                e.currentTarget.onerror = null;
                e.currentTarget.src =
                  "https://img.freepik.com/free-vector/flat-design-no-photo-sign_23-2149272417.jpg?w=740&t=st=1705735041~exp=1705735641~hmac=7f0dbe96496657ecd3156c10388d2a11771fbed93e8b715f3d72cf2f221526e0";
              }}
            />
          ))
        ) : (
          <img
            src="https://img.freepik.com/free-vector/flat-design-no-photo-sign_23-2149272417.jpg?w=740&t=st=1705735041~exp=1705735641~hmac=7f0dbe96496657ecd3156c10388d2a11771fbed93e8b715f3d72cf2f221526e0"
            alt="default"
            className="h-[180px] w-full object-cover"
          />
        )}
      </div>

      {finalImage > 1 && (
        <div className="absolute bottom-2 left-0 right-0 flex justify-center space-x-2">
          {images.map((_, idx) => (
            <div
              key={idx}
              className={`h-2 w-2 rounded-full ${
                idx === currentIndex ? "bg-blue-500" : "bg-gray-300"
              } cursor-pointer`}
              onClick={() => setCurrentIndex(idx)}
            ></div>
          ))}
        </div>
      )}
    </div>
  );
};

export default EventImageCarousel;
