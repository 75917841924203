import { Link } from "react-router-dom";

const PageNotFound = () => {
  return (
    <div className="flex min-h-screen items-center justify-center ">
      <div className="rounded-lg bg-white p-6 text-center ">
        <h1 className="text-6xl font-bold text-gray-800">404</h1>
        <p className="mt-4 text-lg text-gray-600">
          Oops! The page you're looking for doesn't exist.
        </p>
        <Link
          to="/"
          className="mt-6 inline-block rounded bg-secondary px-6 py-3 text-white "
        >
          Go Home
        </Link>
      </div>
    </div>
  );
};

export default PageNotFound;
