import { Card, CardBody, IconButton, Typography } from "@material-tailwind/react";
import { color } from "@material-tailwind/react/types/components/button";

interface IFeatureCard {
  color?: color;
  icon?: React.ReactElement;
  title?: string;
  description?: string;
}
const FeatureCard: React.FC<IFeatureCard> = ({
  color,
  icon,
  title,
  description,
}) => {
  return (
    <Card className="rounded-lg border shadow-lg shadow-gray-900/10">
      <CardBody className="px-8 text-center">
        {icon && color && <IconButton
          variant="gradient"
          size="lg"
          color={color}
          className="pointer-events-none mb-6 rounded-full"
        >
          {icon}
        </IconButton>}
        {title && <Typography variant="h5" className="mb-2" color="blue-gray">
          {title}
        </Typography>}
        {description && <Typography className="font-normal text-blue-gray-600">
          {description}
        </Typography>}
      </CardBody>
    </Card>
  );
};

export default FeatureCard;