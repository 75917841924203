import React, { FC, ReactNode } from "react";
import { ArrowTrendingUpIcon, ClockIcon, StarIcon } from "@heroicons/react/20/solid";
import ReactApexChart from "react-apexcharts";
import { getUserDetails } from "../../helpers/users";

interface StatData {
  id: number;
  heading: string;
  icon: ReactNode;
  count: number;
}
interface ChartData {
  series: {
    name: string;
    data: number[];
  }[];
  options: any;
}
const Stats: FC = () => {
  const { totalEvent, liveEvent, draftEvent } = getUserDetails();

  const statsData: StatData[] = [
    {
      id: 1,
      heading: "Total Event",
      icon: <StarIcon className="h-6 w-6 text-gray-800" />,
      count: totalEvent !== null ? totalEvent : 0,
    },
    {
      id: 2,
      heading: "Live Event",
      icon: <ArrowTrendingUpIcon className="h-6 w-6 text-gray-800" />,
      count: liveEvent !== null ? liveEvent : 0,
    },
    {
      id: 3,
      heading: "Events in Draft",
      icon: <ClockIcon className="h-6 w-6 text-gray-800" />,
      count: draftEvent !== null ? draftEvent : 0,
    },
  ];

  const chartData: ChartData = {
    series: [
      {
        name: "",
        data: [
          totalEvent !== null ? totalEvent : 0,
          liveEvent !== null ? liveEvent : 0,
          draftEvent !== null ? draftEvent : 0,
        ],
      },
    ],
    options: {
      chart: {
        type: "area",
        height: 350,
      },
      xaxis: {
        categories: ["Total Events", "Live Events", "Events in Draft"],
      },
      colors: ["#F98E54"],
    },
  };

  return (
    <div className="items-center justify-center">
      <div className="grid grid-cols-1 gap-4 md:grid-cols-3">
        {statsData.map(({ id, heading, icon, count }) => (
          <div
            key={id}
            className="rounded-lg bg-white px-12 py-5 text-center"
            style={{
              boxShadow:
                "0 4px 8px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.06), 0 4px 4px -2px #F98E54",
            }}
          >
            <h2 className="mb-4 text-xl font-semibold">{heading}</h2>
            <div className="mb-4 flex flex-col items-center justify-center">
              <p className="text-xl">{icon}</p>
              <p className="text-xl font-semibold">{count}</p>
            </div>
          </div>
        ))}
      </div>
      <ReactApexChart
        className="mt-14"
        options={chartData.options}
        series={chartData.series}
        type="area"
        height={350}
      />
    </div>
  );
};

export default Stats;
