import React, { useState } from "react";
import UIInput from "../../../components/ui/Input";
import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";

interface Ticket {
  id: number;
  label: string;
  description: string;
  ticketQuantity: number;
  ticketWarningOn: number;
  currency: string;
  price: number;
}

interface PaidTicketProps {
  onCreateTicket: (ticket: Ticket) => void;
  onEditTicket: (editedTicket: Ticket) => void;
  ticketCount: number;
  tickets: Ticket[];
  handleEditOptionsClick: (id: number) => void;
  showEditOptions: number | null;
  handleDeleteTicket: (id: number) => void;
}

const PaidTicket: React.FC<PaidTicketProps> = ({
  onCreateTicket,
  onEditTicket,
  ticketCount,
  tickets,
  handleEditOptionsClick,
  showEditOptions,
  handleDeleteTicket,
}) => {
  const [showDialog, setShowDialog] = useState(false);
  const [labelError, setLabelError] = useState<string | null>(null);
  const [descriptionError, setDescriptionError] = useState<string | null>(null);
  const [ticketQuantityError, setTicketQuantityError] = useState<string | null>(
    null,
  );
  const [ticketWarningError, setTicketWarningError] = useState<any>(null);
  const [currencyError, setCurrencyError] = useState<string | null>(null);
  const [priceError, setPriceError] = useState<string | null>(null);

  const [ticketLabel, setTicketLabel] = useState("");
  const [description, setDescription] = useState("");
  const [ticketQuantity, setTicketQuantity] = useState(0);
  const [ticketWarning, setTicketWarning] = useState<any>("");
  const [currency, setCurrency] = useState("");
  const [price, setPrice] = useState(0);
  const [editingTicket, setEditingTicket] = useState<Ticket | null>(null);

  const handleLabelChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTicketLabel(event.target.value);
    setLabelError(null);
  };

  const handleDescriptionChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setDescription(event.target.value);
    setDescriptionError(null);
  };

  const handleTicketQuantityChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setTicketQuantity(Number(event.target.value));
    setTicketQuantityError(null);
  };

  const handleTicketWarningChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setTicketWarning(event.target.value);
    // setTicketWarningError(null);
  };

  const handleCurrencyChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    setCurrency(event.target.value);
    setCurrencyError(null);
  };

  const handlePriceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPrice(Number(event.target.value));
    setPriceError(null);
  };

  const handleDialogClose = () => {
    setShowDialog(false);
    setLabelError(null);
    setDescriptionError(null);
    setTicketQuantityError(null);
    setTicketWarningError(null);
    setCurrencyError(null);
    setPriceError(null);
    setTicketLabel("");
    setDescription("");
    setTicketQuantity(0);
    setTicketWarning("");
    setCurrency("");
    setPrice(0);
    setEditingTicket(null);
  };

  const handleCreateOrUpdateTicket = () => {
    let valid = true;

    // Validation checks
    if (ticketLabel.trim() === "") {
      setLabelError("Label cannot be empty");
      valid = false;
    } else {
      setLabelError(null);
    }

    if (description.trim() === "") {
      setDescriptionError("Description cannot be empty");
      valid = false;
    } else {
      setDescriptionError(null);
    }

    if (ticketQuantity <= 0) {
      setTicketQuantityError("Ticket quantity must be greater than 0");
      valid = false;
    } else {
      setTicketQuantityError(null);
    }

    // if (ticketWarning?.trim() === "") {
    //   setTicketWarningError("Ticket warning cannot be empty");
    //   valid = false;
    // } else {
    //   setTicketWarningError(null);
    // }

    if (currency.trim() === "") {
      setCurrencyError("Currency cannot be empty");
      valid = false;
    } else {
      setCurrencyError(null);
    }

    // if (price <= 0) {
    //   setPriceError("Price must be greater than 0");
    //   valid = false;
    // } else {
    //   setPriceError(null);
    // }

    if (!valid) {
      return;
    }

    if (editingTicket) {
      const updatedTicket: Ticket = {
        ...editingTicket,
        label: ticketLabel,
        description: description,
        ticketQuantity: ticketQuantity,
        ticketWarningOn: Number(ticketWarning),
        currency: currency,
        price: price,
      };
      onEditTicket(updatedTicket);
    } else {
      // If creating a new ticket
      const newTicket: Ticket = {
        id: ticketCount,
        label: ticketLabel,
        description: description,
        ticketQuantity: ticketQuantity,
        ticketWarningOn: Number(ticketWarning),
        currency: currency,
        price: price,
      };
      onCreateTicket(newTicket);
    }

    // Close the dialog after ticket creation or update
    handleDialogClose();
  };

  const openDialogForEdit = (ticket: Ticket) => {
    setEditingTicket(ticket);
    setTicketLabel(ticket.label);
    setDescription(ticket.description);
    setTicketQuantity(ticket.ticketQuantity);
    setTicketWarning(Number(ticket.ticketWarningOn));
    setCurrency(ticket.currency);
    setPrice(ticket.price);
    setShowDialog(true);
  };

  const openDialogForCreate = () => {
    setEditingTicket(null);
    setShowDialog(true);
  };

  return (
    <div className=" bg-gray-100 p-4">
      <h2 className="text-lg font-semibold">Generate Ticket Variants</h2>
      <p className="text-gray-800">
        Establish or modify paid ticket categories for your event to offer
        premium access and advantages for participants.
      </p>

      {showDialog && (
        <div className="fixed  inset-0 left-0 top-0 z-50 flex h-full w-full items-center justify-center bg-gray-800 bg-opacity-50">
          <div className="max-w-full rounded-md bg-white px-5  py-5 shadow-md">
            <h3 className="mb-2 text-lg font-semibold">
              {editingTicket ? "Edit Ticket" : "Create Ticket"}
            </h3>
            <div className="mb-4">
              <label
                htmlFor="ticketLabel"
                className="block text-sm font-medium text-gray-700"
              >
                Ticket Label *
              </label>
              <UIInput
                type="text"
                id="ticketLabel"
                value={ticketLabel}
                maxLength={200}
                onChange={handleLabelChange}
                className={`
                    mt-1 block w-full rounded-md border
                    ${labelError ? "border-red-500" : "border-gray-300"}
                    shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm
                  `}
              />
              {labelError && (
                <p className="mt-1 text-sm text-red-500">{labelError}</p>
              )}
            </div>
            <div className="mb-4">
              <label
                htmlFor="description"
                className="block text-sm font-medium text-gray-700"
              >
                Description *
              </label>
              <UIInput
                type="text"
                id="description"
                value={description}
                maxLength={200}
                onChange={handleDescriptionChange}
                className={`
                    mt-1 block w-full rounded-md border
                    ${descriptionError ? "border-red-500" : "border-gray-300"}
                    shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm
                  `}
              />
              {descriptionError && (
                <p className="mt-1 text-sm text-red-500">{descriptionError}</p>
              )}
            </div>
            <div className="mb-4">
              <label
                htmlFor="ticketQuantity"
                className="block text-sm font-medium text-gray-700"
              >
                Ticket Quantity *
              </label>
              <UIInput
                type="number"
                id="ticketQuantity"
                value={ticketQuantity}
                onChange={handleTicketQuantityChange}
                className={`
                    mt-1 block w-full rounded-md border
                    ${
                      ticketQuantityError ? "border-red-500" : "border-gray-300"
                    }
                    shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm
                  `}
              />
              {ticketQuantityError && (
                <p className="mt-1 text-sm text-red-500">
                  {ticketQuantityError}
                </p>
              )}
            </div>

            <div className="mb-4">
              <label
                htmlFor="ticketWarning"
                className="block text-sm font-medium text-gray-700"
              >
                Ticket Alert Threshold
              </label>
              <UIInput
                type="number"
                id="ticketWarning"
                value={ticketWarning}
                onChange={handleTicketWarningChange}
                className={`
                    mt-1 block w-full rounded-md border
                     border-gray-300
                    shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm
                  `}
              />
              {/* {ticketWarningError && (
                <p className="mt-1 text-sm text-red-500">
                  {ticketWarningError}
                </p>
              )} */}
              <i className="text-xs text-gray-700">
                Set the threshold for alerting users about remaining tickets.
              </i>
            </div>
            <div className="flex ">
              <div className="mb-4 mr-2">
                <label
                  htmlFor="currency"
                  className="block text-sm font-medium text-gray-700"
                >
                  Currency *
                </label>
                <select
                  id="currency"
                  value={currency}
                  onChange={handleCurrencyChange}
                  className={`
                  form-input mt-1    border-gray-300 bg-gray-50 text-sm
                  ${currencyError ? "border-red-500" : "border-gray-300"}
                  shadow-sm  sm:text-sm 
                `}
                >
                  <option value="">Select Currency</option>
                  <option value="NZD">NZD</option>
                  <option value="INR">INR</option>
                </select>
                {currencyError && (
                  <p className="mt-1  text-sm text-red-500">{currencyError}</p>
                )}
              </div>
              <div className="mb-4">
                <label
                  htmlFor="price"
                  className="block text-sm font-medium text-gray-700"
                >
                  Price *
                </label>
                <UIInput
                  type="number"
                  id="price"
                  value={price}
                  onChange={handlePriceChange}
                  className={`
                    mt-1 block w-full rounded-md border
                    ${priceError ? "border-red-500" : "border-gray-300"}
                    shadow-sm  sm:text-sm
                  `}
                />
                {priceError && (
                  <p className="mt-1 text-sm text-red-500">{priceError}</p>
                )}
              </div>
            </div>
            <button
              className="my-5 rounded-md bg-secondary px-4 py-2 text-white"
              type="button"
              onClick={handleCreateOrUpdateTicket}
            >
              {editingTicket ? "Save" : "Create Ticket"}
            </button>
            <button
              className="ml-2 rounded-md  bg-gray-300 px-4 py-2"
              type="button"
              onClick={handleDialogClose}
            >
              Cancel
            </button>
          </div>
        </div>
      )}
      <div className="my-3 flex flex-row items-center justify-between">
        {" "}
        <h4 className="text-base  font-semibold">Ticket Variants</h4>
        <button
          type="button"
          className="rounded border-2 border-secondary  px-2 py-1 text-sm  font-semibold uppercase text-secondary hover:bg-secondary hover:text-white"
          onClick={editingTicket ? handleDialogClose : openDialogForCreate}
        >
          {editingTicket ? "Edit Dialog" : "Create"}
        </button>
      </div>

      <div className="roun border bg-white">
        {tickets.map((ticket, index) => (
          <div
            key={ticket.id}
            className={`flex w-full max-w-md flex-col rounded-md bg-white p-5 ${
              index !== 0 ? "border-t border-gray-200" : ""
            }`}
          >
            <div className="flex w-full flex-row items-center justify-between">
              <h3 className="text-xl font-bold">{ticket.label}</h3>
              <div className="text-xs">
                <div className="relative inline-block text-left">
                  <button
                    type="button"
                    className="text-gray-500 hover:text-secondary"
                    onClick={() => handleEditOptionsClick(ticket.id)}
                  >
                    <EllipsisVerticalIcon className="h-5 w-5" />
                  </button>
                  {showEditOptions === ticket.id && (
                    <div className="absolute left-0 mt-2 w-32 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5">
                      <div className="py-1">
                        <button
                          type="button"
                          className="block w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-100"
                          onClick={() => openDialogForEdit(ticket)}
                        >
                          Edit
                        </button>
                      </div>
                      <div className="py-1">
                        <button
                          type="button"
                          className="block w-full px-4 py-2 text-left text-sm text-red-700 hover:bg-gray-100"
                          onClick={() => handleDeleteTicket(ticket.id)}
                        >
                          Delete
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="mt-2 text-sm text-gray-600">
              {ticket.description}
            </div>
            <p className="text-sm text-gray-700">
              {ticket.ticketWarningOn > 0 &&
                `Alert message: Only ${ticket.ticketWarningOn} tickets left`}
            </p>
            <div className="mt-4 flex flex-row items-center justify-between">
              <div className="flex items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="mr-1 h-5 w-5 text-gray-700"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 3a7 7 0 100 14 7 7 0 000-14zM2 10a8 8 0 1116 0 8 8 0 01-16 0z"
                    clipRule="evenodd"
                  />
                  <path
                    fillRule="evenodd"
                    d="M10 1a9 9 0 100 18 9 9 0 000-18zM2 10a8 8 0 1116 0 8 8 0 01-16 0z"
                    clipRule="evenodd"
                  />
                  <path
                    fillRule="evenodd"
                    d="M10 7a3 3 0 100 6 3 3 0 000-6zM7 10a1 1 0 112 0 1 1 0 01-2 0z"
                    clipRule="evenodd"
                  />
                </svg>
                <p className="text-sm text-gray-700">
                  No of tickets: {ticket.ticketQuantity}
                </p>
              </div>
              <p className="text-base font-semibold text-gray-800">
                {ticket.currency} {ticket.price}
              </p>

              <div className="flex items-center"></div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PaidTicket;
