import { FC } from "react";
import DatePicker from "react-datepicker";
import { CalendarDaysIcon } from "@heroicons/react/20/solid";
import TicketTypeSelector from "./TicketTypeSelector/TicketTypeSelector";
import moment from "moment-mini";
import "react-datepicker/dist/react-datepicker.css";

const AddTicketsSection: FC<any> = ({ formik }) => {
  const eventEndDate = formik.values.endDate;
  const maxAllowedEndDate = eventEndDate
    ? moment.unix(eventEndDate).toDate()
    : null;

  const ticketStartDateChange = (date: Date | null) => {
    if (date) {
      const ticketStartDate = moment(date).unix();
      formik.setFieldValue("ticketSaleStartDate", ticketStartDate);
    }
  };

  const ticketEndDateChange = (date: Date | null) => {
    if (date) {
      const ticketEndDate = moment(date).unix();
      formik.setFieldValue("ticketSaleEndDate", ticketEndDate);
    }
  };

  const commonInputStyles =
    "w-full rounded-md border bg-white px-3 py-3 text-sm";
  const commonBorderStyle = {
    border: "1px solid rgb(176, 190, 197)",
    padding: "0.6rem",
    color: "#607D8B",
  };

  return (
    <div className="flex flex-col space-y-5 md:flex-row md:space-x-5 md:space-y-0">
      <div className="md:w-1/2 w-full">
        <TicketTypeSelector formik={formik} />

      
      </div>

      <div className="w-full max-w-full md:w-1/2">
        <div
          style={{
            background: "#f8f7fa",
            boxShadow:
              "rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px",
          }}
          className="relative rounded-md bg-white bg-clip-border p-4 text-gray-700 shadow-md"
        >
          <div className="flex h-10 w-10 items-center justify-center rounded-full bg-gray-800">
            <CalendarDaysIcon className="h-8 w-8 text-white" />
          </div>
          <h3 className="mt-2 text-lg font-semibold leading-6 text-gray-800 antialiased">
            Pick Sale Date
          </h3>
          <p className="text-sm text-gray-600">
            Please select the start and end date for sale.
          </p>

          <div className="mb-4 mt-4 flex flex-col">
            <label
              htmlFor="startDate"
              className="text-sm font-semibold text-gray-700"
            >
              Start Date:
            </label>
            <DatePicker
              selected={
                formik.values.ticketSaleStartDate
                  ? moment.unix(formik.values.ticketSaleStartDate).toDate()
                  : new Date()
              }
              onChange={ticketStartDateChange}
              minDate={new Date()}
              maxDate={maxAllowedEndDate}
              dateFormat="yyyy-MM-dd HH:mm"
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={15}
              className="min-w-full p-2"
              calendarClassName="custom-calendar"
            />
          </div>

          <div className="mb-4 mt-4 flex flex-col">
            <label
              htmlFor="endDate"
              className="text-sm font-semibold text-gray-700 flex flex-col"
            >
              End Date:
            </label>
            <DatePicker
              selected={
                formik.values.ticketSaleEndDate
                  ? moment.unix(formik.values.ticketSaleEndDate).toDate()
                  : maxAllowedEndDate
              }
              onChange={ticketEndDateChange}
              minDate={
                formik.values.ticketSaleStartDate
                  ? moment.unix(formik.values.ticketSaleStartDate).toDate()
                  : new Date()
              }
              maxDate={maxAllowedEndDate}
              dateFormat="yyyy-MM-dd HH:mm"
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={15}
              className="min-w-full p-2"
              calendarClassName="custom-calendar"
            />
          </div>
        </div>
        <div className="py-2 pr-2">
          <label
            htmlFor="refundPolicy"
            className="block text-sm font-medium text-gray-700"
          >
            Refund Policy
          </label>
          <textarea
            id="refundPolicy"
            name="refundPolicy"
            className={commonInputStyles}
            style={commonBorderStyle}
            value={formik.values.refundPolicy}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            minLength={10}
            maxLength={500}
          />
          {formik.touched.refundPolicy && formik.errors.refundPolicy ? (
            <div className="text-sm text-red-500">
              {formik.errors.refundPolicy}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default AddTicketsSection;
