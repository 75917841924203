import { gql } from "@apollo/client";

export const GET_CUSTOMER_TICKET_DETAIL = gql`
  query Event_getCustomerTicketDetails($customerId: Int!) {
    event_getCustomerTicketDetails(customerId: $customerId) {
      customerId
      firstName
      lastName
      email
      phoneNumber
      eventId
      eventName
      ticketId
      totalTicketBooked
      totalPrice
      presence
      paymentTime
      ticketDetails {
        label
        ticketbooked
        perTicketPrice
        totalChargespaid
        currency
      }
    }
  }
`;
