
export const convertEpochToReadableDate = (epochTime?: string): string => {
  const milliseconds: any | undefined = epochTime
    ? Number(epochTime) * 1000
    : undefined;
  const date = new Date(milliseconds);
  const options: Intl.DateTimeFormatOptions = {
    day: "numeric",
    month: "short",
    year: "numeric",
  };
  return date.toLocaleDateString("en-GB", options);
};
