import { gql } from "@apollo/client";

export const GET_USER_STRIPE_INFO_QUERY = gql`
  query Stripe_getUserStripeInfo($userid: Int!) {
    stripe_getUserStripeInfo(userid: $userid) {
      id
      stripe_secret_key
      publish_key
      createdAt
      updatedAt
    }
  }
`;
