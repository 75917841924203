/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import copyLink from "../../../assets/images/stepsInfo.jpg";
import { useNavigateSearch } from "../../../hooks/useNavigateSearch";

const WebIntegration: React.FC<any> = ({ pathName, section, tab }) => {
  const navigateSearch = useNavigateSearch();

  const handleNavigateToDraftEvent = () => {
    navigateSearch({
      pathname: pathName,
      params: { section: "events", tab: "live" },
    });
  };

  return (
    <div className="mx-0 max-w-4xl bg-gray-100 px-0 py-8">
      <div className="container mx-auto px-4">
        <div className="flex flex-col gap-8 lg:flex-row-reverse">
          <div className="flex flex-col items-center">
            <div className="mb-4 h-72 w-full max-w-md rounded-lg bg-gray-300 shadow-md">
              <img
                className="h-full w-full rounded-lg object-cover"
                src={copyLink}
                alt="Step-by-Step Guide Image"
              />
            </div>
            <button
              onClick={handleNavigateToDraftEvent}
              className="w-full max-w-md rounded-md bg-secondary px-6 py-3 font-bold text-white"
            >
              Go to the Event
            </button>
          </div>

          <div className="flex-1">
            <div className=" text-gray-800">
              <h2 className="mb-2 text-2xl  font-bold">
                Integrate the Event on Your Website
              </h2>
              <p className="mb-6 text-base">
                Follow these simple steps to integrate an event on your website.
                This process is quick and beneficial from a business
                perspective.
              </p>

              <div className="mb-6">
                <h3 className="mb-2 text-xl font-bold">How to integrate:</h3>
                <ol className="list-decimal space-y-2 pl-6">
                  <li>
                    <span className="text-gray-600">Click </span>
                    <span
                      className="cursor-pointer font-bold text-secondary underline hover:text-secondary"
                      onClick={handleNavigateToDraftEvent}
                    >
                      here
                    </span>
                    <span className="text-gray-600">
                      {" "}
                      to go to the live events section.
                    </span>
                  </li>
                  <li className="text-gray-600">
                    Select your desired event from the list of live events.
                  </li>
                  <li className="text-gray-600">
                    Choose your preferred integration method:{" "}
                    <span className="font-bold">Button</span> or{" "}
                    <span className="font-bold">Iframe</span>.
                  </li>
                  <li className="text-gray-600">
                    Copy the provided code snippet.
                  </li>
                  <li className="text-gray-600">
                    Paste the code into your website's HTML at the desired
                    location.
                  </li>
                  <li className="text-gray-600">
                    Refresh your website to see the live integration in action!
                  </li>
                </ol>
              </div>

              <div className="mb-6">
                <h3 className="mb-2 text-xl font-bold">
                  Benefits of Integrating Events:
                </h3>
                <ul className="list-disc space-y-2 pl-6">
                  <li className="text-gray-600">
                    <span className="font-bold">Increased Engagement:</span>{" "}
                    Events can attract more visitors to your website.
                  </li>
                  <li className="text-gray-600">
                    <span className="font-bold">Better User Experience:</span>{" "}
                    Users can easily access and participate in live events
                    without leaving your site.
                  </li>
                  <li className="text-gray-600">
                    <span className="font-bold">Business Growth:</span>{" "}
                    Integrating events can lead to higher conversion rates and
                    sales.
                  </li>
                  <li className="text-gray-600">
                    <span className="font-bold">Brand Loyalty:</span> Regularly
                    hosted events can enhance customer loyalty and trust.
                  </li>
                </ul>
              </div>

              <p className="text-gray-600">
                For more detailed instructions, refer to our comprehensive guide
                or contact our support team.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WebIntegration;

// import { useState } from "react";
// import LinkCopied from "../../../images/copiedLink.png";
// import IframeIntegrationSteps from "./IframeIntegrationSteps";
// import ButtonIntegrationSteps from "./ButtonIntegrationSteps";
// import { useNavigateSearch } from "../../../hooks/useNavigateSearch";
// import DashboardChildrenProps from "../../../types/DashboardChildren";

// const Integration: React.FC<DashboardChildrenProps> = ({
//   pathName,
//   section,
//   tab,
// }) => {
//   const [selectedIntegration, setSelectedIntegration] = useState(
//     tab === "" ? "iframe" : tab,
//   );

//   const navigateSearch = useNavigateSearch();

//   const handleIntegrationChange = (integration: string) => {
//     navigateSearch({
//       pathname: pathName,
//       params: { section: section, tab: integration },
//     });
//     setSelectedIntegration(integration);
//   };

//   const handleNavigateToDraftEvent = () => {
//     navigateSearch({
//       pathname: pathName,
//       params: { section: "events", tab: "live" },
//     });
//   };

//   const iframeCss = `
//   <style>
//   .event {
//     max-width: 90vw;
//     width: 100%;
//     height: 100%;
//     min-height: 100vh;
//     display: flex;
//     margin: 20px auto;
//     border: 1px solid #bdbdbd;
//     border-radius: 5px;
//   }
//   @media (max-width:767px) {
//     .event{
//       max-width: 95vw;
//       margin: 10px auto;
//     }
//   }
// </style>
//   `;

//   const iframeJs = `<script type='text/javascript'>
//   window.addEventListener('message', (event) => {
//     if (event.data.type === 'REQUEST_PARENT_URL') {
//       const parentUrl = window.location.href;
//       event.source.postMessage({ type: 'PARENT_URL', url: parentUrl }, event.origin);
//     }
//   });
// </script>`;

//   const steps = [
//     {
//       title: "HTML",
//       content: (
//         <div>
//           <div className="text-md p-0">
//             <li>
//               Go to{" "}
//               <button
//                 onClick={handleNavigateToDraftEvent}
//                 className="font-bold  underline"
//               >
//                 your event.
//               </button>
//             </li>
//             <li>Copy the desired event for embedding.</li>
//             <li>Return here to proceed to the next integration step.</li>
//           </div>
//           <div className="mb-4  md:w-96 ">
//             {" "}
//             <img src={LinkCopied} />
//           </div>
//         </div>
//       ),
//       component: <div className="ml-3"></div>,
//     },
//     {
//       title: "CSS",
//       content: "",
//       component: (
//         <div className="ml-3">
//           <div className="mb-4">
//             <label className="mb-2 block text-lg font-semibold"></label>
//             <div className="flex">
//               <textarea
//                 className="w-full rounded bg-gray-900 p-2 text-white"
//                 rows={10}
//                 value={iframeCss}
//                 readOnly
//               />
//               <button
//                 onClick={() => copyToClipboard("Css  Copied!", iframeCss)}
//               >
//                 <span
//                   style={{
//                     color: "white",
//                     position: "relative",
//                     bottom: 110,
//                     right: 50,
//                   }}
//                 >
//                   Copy
//                 </span>
//               </button>
//             </div>
//           </div>
//         </div>
//       ),
//     },
//     {
//       title: "Javascript",
//       content:
//         "Include the following script to your codebase; it is advisable to integrate it globally for optimal functionality.",
//       component: (
//         <div className="ml-3">
//           <div className="mb-4">
//             <label className="mb-2 block text-lg font-semibold">
//               Javascript Code:
//             </label>
//             <div className="flex">
//               <textarea
//                 className="w-full rounded bg-gray-900 p-2 text-white "
//                 rows={10}
//                 value={iframeJs}
//                 readOnly
//               />
//               <button
//                 onClick={() =>
//                   copyToClipboard("Javascript Code Copied!", iframeJs)
//                 }
//               >
//                 <span
//                   style={{
//                     color: "white",
//                     position: "relative",
//                     bottom: 110,
//                     right: 50,
//                   }}
//                 >
//                   Copy
//                 </span>
//               </button>
//             </div>
//           </div>
//         </div>
//       ),
//     },
//     {
//       title: "Go Live",
//       content:
//         "After make changes on your site you should be able to observe the events platform on your website",
//       component: <div className="ml-3"></div>,
//     },
//   ];

//   const btnCss = `
// <style>
// .bookableWrapper {
//   width: 100%;
//   margin: 10px auto;
//   display: flex;
//   justify-content: center;
//   align-items: center;
// }
// .bookableBtnClass {
//   background-color: #3498db;
//   color: #ffffff;
//   padding: 10px 20px;
//   border: none;
//   border-radius: 5px;
//   cursor: pointer;
//   min-width: 150px;
// }
// .bookableBtnClass:hover {
//   background-color: #2980b9;
// }
// .bookableModal {
//   display: none;
//   position: fixed;
//   z-index: 999999;
//   left: 0;
//   top: 0;
//   bottom: 0;
//   right: 0;
//   width: 100vw;
//   height: 100vh;
//   overflow: hidden;
//   background-color: rgba(0, 0, 0, 0.4);
// }

// .bookableModal-content {
//   position: absolute;
//   background-color: #fefefe;
//   margin: auto;
//   padding: 10px;
//   border-radius: 10px;
//   top: 54%;
//   left: 50%;
//   transform: translate(-50%, -50%);
//   width: 80%;
//   height: 79%;
//   z-index: 99999;
// }

// @media (max-width: 767px) {
//   .bookableModal {
//     padding-top: 50px;
//     padding-bottom: 50px;
//   }
//   .bookableModal-content {
//     padding: 10px;
//     width: 90%;
//     top: 47%;
//     height: 72%;
//   }
// }

// .bookableClose {
//   color: #aaaaaa;
//   float: right;
//   font-size: 28px;
//   font-weight: bold;
//   margin: 5px auto;
// }

// .bookableClose:hover,
// .bookableClose:focus {
//   color: #000;
//   text-decoration: none;
//   cursor: pointer;
// }
// .bookableIframeStyle {
//   width: 100%;
//   height: calc(100% - 60px);
//   display: block;
//   margin: auto;
//   border: 1px solid #bdbdbd;
//   border-radius: 5px;
// }
// </style>
// `;

//   const btnJS = `
// <script type='text/javascript'>

// const bookableModal = document.getElementById("bookableEventModal");

// const btn = document.getElementById("bookableBtn");

// const span = document.getElementsByClassName("bookableClose")[0];

// btn.onclick = function () {
//   bookableModal.style.display = "block";
// };
// span.onclick = function () {
//   bookableModal.style.display = "none";
// };

// window.onclick = function (event) {
//   if (event.target == bookableModal) {
//     bookableModal.style.display = "none";
//   }
// };
// window.addEventListener('message', (event) => {
// if (event.data.type === 'REQUEST_PARENT_URL') {
// const parentUrl = window.location.href;
// event.source.postMessage({ type: 'PARENT_URL', url: parentUrl }, event.origin);
// }
// });
// </script>
// `;
//   const ButtonSteps = [
//     {
//       title: "HTML",
//       content: (
//         <div>
//           <div className="text-md p-0">
//             <li>
//               Go to{" "}
//               <button
//                 onClick={handleNavigateToDraftEvent}
//                 className="font-bold  underline"
//               >
//                 your event.
//               </button>
//             </li>
//             <li>Copy the desired event for button.</li>
//             <li>Return here to proceed to the next integration step.</li>
//           </div>
//           <div className="mb-4  md:w-96">
//             {" "}
//             <img src={LinkCopied} />
//           </div>
//         </div>
//       ),
//       component: <div className="ml-3"></div>,
//     },
//     {
//       title: "CSS",
//       content: "",
//       component: (
//         <div className="ml-3">
//           <div className="mb-4">
//             <label className="mb-2 block text-lg font-semibold"></label>
//             <div className="flex">
//               <textarea
//                 className="w-full rounded bg-gray-900 p-2 text-white"
//                 rows={10}
//                 value={btnCss}
//                 readOnly
//               />
//               <button onClick={() => copyToClipboard("Css  Copied!", btnCss)}>
//                 <span
//                   style={{
//                     color: "white",
//                     position: "relative",
//                     bottom: 110,
//                     right: 50,
//                   }}
//                 >
//                   Copy
//                 </span>
//               </button>
//             </div>
//           </div>
//         </div>
//       ),
//     },
//     {
//       title: "Javascript",
//       content:
//         "Include the following script to your codebase; it is advisable to integrate it globally for optimal functionality.",
//       component: (
//         <div className="ml-3">
//           <div className="mb-4">
//             <label className="mb-2 block text-lg font-semibold">
//               Javascript Code:
//             </label>
//             <div className="flex">
//               <textarea
//                 className="w-full rounded bg-gray-900 p-2 text-white "
//                 rows={12}
//                 value={btnJS}
//                 readOnly
//               />
//               <button
//                 onClick={() =>
//                   copyToClipboard("Javascript Code Copied!", btnJS)
//                 }
//               >
//                 <span
//                   style={{
//                     color: "white",
//                     position: "relative",
//                     bottom: 110,
//                     right: 50,
//                   }}
//                 >
//                   Copy
//                 </span>
//               </button>
//             </div>
//           </div>
//         </div>
//       ),
//     },

//     {
//       title: "Go Live",
//       content:
//         "After make changes on your site you should be able to observe the events platform on your website",
//       component: <div className="ml-3"></div>,
//     },
//   ];

//   const copyToClipboard = (successMessage: string, textToCopy: string) => {
//     const textarea = document.createElement("textarea");
//     textarea.value = textToCopy;
//     document.body.appendChild(textarea);
//     textarea.select();
//     document.execCommand("copy");
//     document.body.removeChild(textarea);
//     alert(successMessage);
//   };

//   return (
//     <div className="mx-0 max-w-4xl px-0">
//       <div className="mb-0 bg-gray-100">
//         <ul className="flex border-b">
//           <li className="ml-auto mr-1">
//             <button
//               onClick={() => handleIntegrationChange("iframe")}
//               className={`${
//                 selectedIntegration === "iframe"
//                   ? "bg-primary text-white"
//                   : "bg-gray-100 text-gray-900"
//               }  md:text-md  px-6 py-3`}
//             >
//               Iframe Integration
//             </button>
//           </li>
//           <li className="mr-1">
//             <button
//               onClick={() => handleIntegrationChange("button")}
//               className={`${
//                 selectedIntegration === "button"
//                   ? "bg-primary text-white"
//                   : "bg-gray-100 text-gray-900"
//               } text-md px-4 py-3`}
//             >
//               Button Integration
//             </button>
//           </li>
//         </ul>
//       </div>

//       <div className="rounded-md bg-white p-6 shadow-md">
//         {selectedIntegration === "iframe" && (
//           <IframeIntegrationSteps steps={steps} />
//         )}
//         {selectedIntegration === "button" && (
//           <ButtonIntegrationSteps steps={ButtonSteps} />
//         )}
//       </div>
//     </div>
//   );
// };

// export default Integration;
