import React from "react";
import QRCode from "react-qr-code";
import {
  Document,
  Page,
  Text,
  View,
  Image,
  StyleSheet,
  PDFDownloadLink,
  Font,
} from "@react-pdf/renderer";
import { CardBody } from "@material-tailwind/react";
import { ArrowDownTrayIcon } from "@heroicons/react/24/outline";
import { formatEventDuration } from "../../utils/formatEventDuration";

Font.register({
  family: "Roboto",
  fonts: [
    { src: "https://fonts.gstatic.com/s/roboto/v20/KFOmCnqEu92Fr1Mu4mxP.ttf" },
    {
      src: "https://fonts.gstatic.com/s/roboto/v20/KFOlCnqEu92Fr1MmEU9fBBc9.ttf",
      fontWeight: 900,
    },
    {
      src: "https://fonts.gstatic.com/s/roboto/v20/KFOlCnqEu92Fr1MmEU9fBBc9.ttf",
      fontWeight: 300,
    },
  ],
});

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#ffffff",
    paddingTop: 20,
    paddingBottom: 20,
    fontFamily: "Roboto",
  },
  card: {
    width: 600,
    padding: 20,
    backgroundColor: "#f2f2f2",
    borderRadius: 10,
    boxShadow: "0 10px 20px rgba(0, 0, 0, 0.1)",
  },
  cardAlign: {
    textAlign: "left",
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
  },
  pricingDetail: {
    marginBottom: 10,
    borderBottomWidth: 1,
    borderBottomColor: "#ccc",
  },
  pricingLabel: {
    fontSize: 14,
    fontWeight: "bold",
    marginBottom: 5,
  },
  pricingDescription: {
    fontSize: 12,
  },
  qrCodeContainer: {
    marginRight: 20,
    alignSelf: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  qrCode: {
    width: 150,
    height: 150,
  },
  contentContainer: {
    flex: 1,
    marginLeft: 20,
    alignSelf: "center",
  },
  header: {
    fontSize: 24,
    fontWeight: "bold",
    marginTop: 10,
    textDecoration: "underline",
    textAlign: "center",
    marginBottom: 30,
  },
  label: {
    fontSize: 16,
    fontWeight: "bold",
    marginBottom: 5,
  },
  infoText: {
    fontSize: 14,
    marginBottom: 10,
  },

  row: {
    flexDirection: "row",
    marginBottom: 10,
  },
  rowItem: {
    flex: 1,
    marginRight: 20,
  },
  confirmationMessageSection: {
    borderTop: "1px solid black",
    width: 500,
    margin: "auto",
    marginTop: "30px",
    paddingTop: "5px",
    textAlign: "center",
  },
  confirmationMessage: {
    fontSize: 10,
    color: "#212121",
    width: 300,
    margin: "auto",
  },
  ticketId: {
    marginTop: 0,
    fontSize: 12,
    textAlign: "center",
  },
});

const QrCode: React.FC<any> = ({
  eventDetailsData,
  customerid,
  customerBookingData,
}) => {
  const eventDetail = eventDetailsData?.iframe_getEventByid[0];

  const generatePdf = () => {
    const qrCodeValue = `${window.location.origin}/tickets/${btoa(
      customerid || "",
    )}`;
    const qrCodeImage = `https://api.qrserver.com/v1/create-qr-code/?data=${encodeURIComponent(
      qrCodeValue,
    )}&size=200x200`;

    const eventStartTime =
      (eventDetail?.recurringType as any)?.start_time ||
      (eventDetail?.singleDay as any)?.start_time;
    const eventEndTime =
      (eventDetail?.recurringType as any)?.end_time ||
      (eventDetail?.singleDay as any)?.end_time;

    return (
      <Document>
        <Page size="A4" style={styles.page}>
          <Text
            style={{
              fontSize: 20,
              marginBottom: 5,
              fontWeight: 700,
              textAlign: "center",
            }}
          >
            Ticket Details
          </Text>
          <Text style={{ fontSize: 14, marginBottom: 20, textAlign: "center" }}>
            Present this at the event entrance
          </Text>
          <View style={styles.card}>
            <Text style={styles.header}>{eventDetail?.title}</Text>
            <View style={styles.cardAlign}>
              <View style={styles.qrCodeContainer}>
                <Image src={qrCodeImage} style={styles.qrCode} />
                <Text style={styles.ticketId}>
                  #
                  {
                    customerBookingData?.event_getCustomerTicketDetails
                      ?.ticketId
                  }
                </Text>
              </View>
              <View style={styles.contentContainer}>
                <View style={styles.row}>
                  <View style={styles.rowItem}>
                    <Text style={styles.label}>Location:</Text>
                    <Text style={styles.infoText}>{eventDetail?.address}</Text>
                  </View>
                  <View style={styles.rowItem}>
                    <Text style={styles.label}>Duration:</Text>
                    <Text style={styles.infoText}>
                      {formatEventDuration(eventStartTime, eventEndTime)}
                    </Text>
                  </View>
                </View>
                <View style={styles.row}>
                  <View style={styles.rowItem}>
                    <Text style={styles.label}>Start:</Text>
                    <Text style={styles.infoText}>
                      {eventStartTime
                        ? new Date(eventStartTime * 1000).toLocaleString()
                        : "N/A"}
                    </Text>
                  </View>
                  <View style={styles.rowItem}>
                    <Text style={styles.label}>End:</Text>
                    <Text style={styles.infoText}>
                      {eventEndTime
                        ? new Date(eventEndTime * 1000).toLocaleString()
                        : "N/A"}
                    </Text>
                  </View>
                </View>
                <Text style={styles.label}>Price Details:</Text>
                {eventDetail?.pricingDetails !== null &&
                eventDetail?.pricingDetails?.length > 0
                  ? eventDetail?.pricingDetails.map(
                      (detail: any, index: any) => (
                        <View key={index} style={styles.pricingDetail}>
                          <Text style={styles.pricingLabel}>
                            {detail.label}: {detail.price} {detail.currency}
                          </Text>
                          <Text style={styles.pricingDescription}>
                            {detail.description}
                          </Text>
                        </View>
                      ),
                    )
                  : eventDetail?.free === 1 && (
                      <Text style={styles.infoText}>Free</Text>
                    )}
              </View>
            </View>
            <View style={styles.confirmationMessageSection}>
              <Text style={styles.confirmationMessage}>
                A confirmation email with your booking details has been sent to
                you Powered by Bookable
              </Text>
            </View>
          </View>
        </Page>
      </Document>
    );
  };

  const handleDownloadPDF = () => {
    const pdfBlob = generatePdf();
    const pdfName = "event_ticket.pdf";
    return { document: pdfBlob, fileName: pdfName };
  };

  return (
    <CardBody>
      <div className="mb-4 flex flex-col ">
        <span className="ml-5 flex justify-end">
          <PDFDownloadLink {...handleDownloadPDF()}>
            {({ loading }) => (
              <button
                type="button"
                className="flex w-10 justify-center rounded-md bg-secondary p-1 "
                disabled={loading}
              >
                {loading ? (
                  "..."
                ) : (
                  <ArrowDownTrayIcon className="h-6 w-6" color="white" />
                )}
              </button>
            )}
          </PDFDownloadLink>
        </span>{" "}
        <span className="mt-5 text-xl">Scan Below For Tickets</span>
      </div>
      <div
        className="mx-auto mb-3 flex items-center justify-center border-4 border-solid border-white"
        style={{ width: "200px", height: "200px" }}
      >
        <QRCode
          value={`${window.location.origin}/tickets/${btoa(customerid || "")}`}
          size={190}
          bgColor="#ffffff"
          fgColor="#000000"
        />
      </div>

      <div className="mt-4 text-lg">
        <p className="mb-2">
          Thank You! Please check your email for the ticket.
        </p>
        <p>Please proceed back to home.</p>
      </div>
    </CardBody>
  );
};

export default QrCode;
