import React, { ButtonHTMLAttributes } from "react";
import { cn } from "../../helpers/cn";

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> { }

const UIButton: React.FC<ButtonProps> = ({
  children,
  className,
  ...rest
}:ButtonProps) => {
  return (
    <button
      className={cn(
        "btn w-full uppercase font-bold bg-primary border-2 border-primary text-white hover:bg-white hover:text-primary",
        className
      )}
      {...rest}
    >
      {children}
    </button>
  );
};

export default UIButton;
