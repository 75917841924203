import React from "react";
import { Navigate,Outlet } from "react-router-dom";

const ProtectedRoutes: React.FC = () => {
  const storedToken = localStorage.getItem("token");
    
  if (storedToken && storedToken.length > 0) {
    return <Outlet/>;
  } else{
    return <Navigate to={"/"} />;
  }
};

export default ProtectedRoutes;
