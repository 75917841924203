import moment from "moment-mini";

export const formatEventDuration = (startTime: number, endTime: number) => {
  const startTimeInMilliseconds = startTime * 1000;
  const endTimeInMilliseconds = endTime * 1000;
  const duration = moment.duration(
    endTimeInMilliseconds - startTimeInMilliseconds,
  );

  let formattedDuration = "";
  if (duration.hours() > 0) {
    formattedDuration += `${duration.hours()}h `;
  }
  if (duration.minutes() > 0) {
    formattedDuration += `${duration.minutes()}m `;
  }

  return formattedDuration.trim();
};
