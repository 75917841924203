import React, { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import { SETUP_INTENT_AND_CREATE_SUBSCRIPTION } from "../../api/Payment/setupIntentAndCreateSubscription";
import { toast } from "react-toastify";
import {
  ArrowRightIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/20/solid";
import PageTitle from "../../components/ui/PageTitle";
import UIButton from "../../components/ui/Button";
import { useCustomQuery } from "../../hooks/useCustomQuery";
import LoadingSpinner from "../../components/common/LoadingSpinner/LoadingSpinner";

const Completion: React.FC = () => {
  const query = useCustomQuery();
  const setupIntentId = query.get("setup_intent");
  const subscriptionId = localStorage.getItem("subscription_id");
  const customerId = localStorage.getItem("customerId");
  const userId = localStorage.getItem("userId");
  const priceId = localStorage.getItem("stripe_price_id");
  const [loading, setLoading] = useState<boolean>(true);
  const [invoiceUrl, setInvoiceUrl] = useState<string | null>(null);

  const [setupIntentAndCreateSubscription, { error }] = useMutation(
    SETUP_INTENT_AND_CREATE_SUBSCRIPTION,
  );

  const createSubscription = async () => {
    try {
      setLoading(true);
      const { data } = await setupIntentAndCreateSubscription({
        variables: {
          subscriptionId: subscriptionId ? parseInt(subscriptionId) : 0,
          userId: userId ? parseInt(userId) : 0,
          customerid: customerId ? customerId : "",
          setupIntentId: setupIntentId ? setupIntentId : "",
          priceid: priceId ? priceId.trim() : "",
        },
      });
      if (
        data &&
        data.payment_retrieveSetupIntentAndCreateSubscription.message ===
          "success" &&
        data.payment_retrieveSetupIntentAndCreateSubscription.subscription
          .invoice.length > 0
      ) {
        setInvoiceUrl(
          data.payment_retrieveSetupIntentAndCreateSubscription.subscription
            .invoice,
        );
        localStorage.removeItem("customerId");
        localStorage.removeItem("stripe_price_id");
        localStorage.removeItem("subscription_id");
      }
      setLoading(false);
      // Handle success
    } catch (error) {
      // Handle error
      console.error(
        "Error creating subscription:",
        error instanceof Error ? error.message : "Unknown error",
      );
      toast.error("Failed to create subscription");
      setLoading(false);
    }
  };

  useEffect(() => {
    createSubscription();
  }, []);

  if (loading)
    return (
      <div className="bg-gray-0 mx-auto flex h-screen max-w-7xl animate-pulse flex-row items-center justify-center  px-5 align-middle sm:px-6">
        <section className="px-4 py-10 text-center">
          <div className="flex h-20 w-full justify-center">
            <LoadingSpinner />
          </div>
        </section>
      </div>
    );
  if (error)
    return (
      <div className="bg-gray-0 mx-auto flex h-screen max-w-7xl flex-col items-center justify-center  px-5 align-middle sm:px-6">
        <p className="text-lg font-semibold">
          <ExclamationTriangleIcon className="h-24 w-24 text-center " />
        </p>
        <p className="text-center text-lg font-medium">
          {error instanceof Error &&
            "We apologize, there's been an issue. Please attempt this again in a bit"}
        </p>
      </div>
    );
  if (invoiceUrl) {
    return (
      <section className="min-h-[40vh] items-center px-4 py-10 text-center">
        <PageTitle heading="Thank you for subscribing!">
          Kindly pay your first invoice to start your subscription.
        </PageTitle>
        <a
          href={invoiceUrl || "/"}
          className="mt-5 inline-block"
          target="_blank"
        >
          <UIButton className="flex items-center gap-3 border-secondary bg-secondary text-white hover:text-secondary">
            Pay Now
            <ArrowRightIcon className="h-5" />
          </UIButton>
        </a>
      </section>
    );
  } else {
    return (
      <div className="bg-gray-0 mx-auto flex h-screen max-w-7xl flex-col items-center justify-center  px-5 align-middle sm:px-6">
        <p className="text-lg font-semibold">
          <ExclamationTriangleIcon className="h-24 w-24 text-center " />
        </p>
        <p className="text-center text-lg font-medium">
          We apologize, there's been an issue fetching invoice.
        </p>
      </div>
    );
  }
};

export default Completion;
