import React, { useEffect, useState } from "react";
import { ApolloError, useMutation, useQuery } from "@apollo/client";
import {
  GET_BOOKING_CUSTOMER_DETAIL,
  GET_BOOKING_CUSTOMER_TOTAL_COUNT,
} from "../../api/Event/getBookingCustomerDetails";
import { useLocation, useParams } from "react-router-dom";
import TicketDetailsModal from "./eventBookingCustomerModal/ticketDetailsModal";
import Listing from "../../types/EventCustomerListing";
import AttendeeStatusTab from "../../components/common/attendeesStatusTab";
import { toast } from "react-toastify";
import { UPDATE_CUSTOMER_ATTENDENCE } from "../../api/Event/updateCustomAttendenceStatus";
import { capitalizeFirstLetter } from "../../helpers/messages-helper";
import UIButton from "../../components/ui/Button";
import LoadingSpinner from "../../components/common/LoadingSpinner/LoadingSpinner";

interface ListingProps {}
interface TicketDetails {
  ticketbooked: number;
  label: string;
  totalChargespaid?: number;
}
const BookingCustomerList: React.FC<ListingProps> = () => {
  const { eventID } = useParams<{ eventID: string }>();
  const location = useLocation();
  const organizer = location.state.organizer;
  const eventTitle = location.state.title;

  const [selectedListing, setSelectedListing] = useState<Listing | null>(null);
  const [presence, setPresence] = useState<boolean | null>(null);
  const [customer_id, setCustomerID] = useState<number | null>(null);
  const [attendanceStatuses, setAttendanceStatuses] = useState<
    Record<number, boolean>
  >({});
  const [errorMsg, setErrorMsg] = useState<ApolloError | null>();
  const [disable, setDisable] = useState<boolean>(false);
  const [limit, setLimit] = useState<number>(5);
  const [offset, setOffset] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageCount, setPageCount] = useState<number>(0);

  const { data, loading, error, refetch } = useQuery<{
    event_getBookingCustomersList: Listing[];
  }>(GET_BOOKING_CUSTOMER_DETAIL, {
    variables: {
      eventid: Number(eventID),
      limit: limit,
      offset: offset,
      presence: presence,
    },
  });

  const { data: count, error: countError } = useQuery<{
    event_countEventBookingCustomer: number;
  }>(GET_BOOKING_CUSTOMER_TOTAL_COUNT, {
    variables: { eventId: Number(eventID) },
  });

  useEffect(() => {
    if (count && count.event_countEventBookingCustomer) {
      const totalPages = Math.ceil(
        count.event_countEventBookingCustomer / limit,
      );
      setPageCount(totalPages);
    }
  }, [count, limit]);

  useEffect(() => {
    if (data && data.event_getBookingCustomersList !== null) {
      const newAttendanceStatuses: Record<number, boolean> = {};
      data.event_getBookingCustomersList?.map((listing) => {
        newAttendanceStatuses[listing.customerId] = listing.presence || false;
      });
      setAttendanceStatuses(newAttendanceStatuses);
    }
  }, [data]);

  const [updateAttendanceStatus] = useMutation(UPDATE_CUSTOMER_ATTENDENCE, {
    onError: (error) => {
      toast.error(`Error updating attendance: ${error.message}`, {
        autoClose: 2000,
      });
    },
  });

  const openModal = (listing: Listing) => {
    setSelectedListing(listing);
  };

  const closeModal = () => {
    setSelectedListing(null);
  };

  const handleChange = async (
    customer_id: number,
    attendanceStatus: boolean,
  ) => {
    setAttendanceStatuses((prevAttendanceStatuses) => ({
      ...prevAttendanceStatuses,
      [customer_id]: attendanceStatus,
    }));

    setCustomerID(customer_id);

    try {
      const { data: attendanceData } = await updateAttendanceStatus({
        variables: {
          customerid: customer_id,
          present: attendanceStatus,
        },
      });

      if (attendanceData && attendanceData.event_updateCustomerAttendence) {
        setAttendanceStatuses((prevAttendanceStatuses) => ({
          ...prevAttendanceStatuses,
          [customer_id]: attendanceStatus,
        }));
        toast.success(attendanceData?.event_updateCustomerAttendence);
      } else {
        toast.error("Failed to update attendance", { autoClose: 2000 });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
    setOffset((pageNumber - 1) * limit);
  };
  const headers = [
    "S.no",
    "Name",
    "E-mail",
    // "Ph. no",
    "No. of tickets",
    "Tickets",
    "Attendance",
  ];

  return (
    <>
      <div className="mx-auto my-8 min-h-[400px] max-w-[300px] md:max-w-[700px] lg:max-w-[1000px]">
        <div className="mx-auto flex min-h-[100px] flex-col items-center sm:flex-row sm:justify-between ">
          <span className="my-4 text-xl sm:text-3xl">{eventTitle}</span>
          <span className="w-[300px] sm:w-[400px] sm:max-w-[450px]">
            <AttendeeStatusTab
              setPresence={setPresence}
              refetch={refetch}
              setErrorMsg={setErrorMsg}
              setDisable={setDisable}
            />
          </span>
        </div>
        <div className="mx-auto mt-8 flex max-w-[1000px] flex-row justify-around border-b border-gray-700 py-4">
          {headers &&
            headers.map((title, index) => (
              <span
                key={index}
                className={`${
                  index === 0 ? "w-1/12" : "w-1/6"
                } text-xs font-bold text-gray-800 sm:text-center sm:text-sm lg:text-xl`}
              >
                {title}
              </span>
            ))}
        </div>
        <div className="mx-auto min-h-[390px]  max-w-[1000px]">
          {loading ? (
            <div className="mx-auto mt-20 h-12 w-12">
              <LoadingSpinner />
            </div>
          ) : error ? (
            <div className="mt-2 text-center text-2xl font-bold">
              {capitalizeFirstLetter(error?.message ? 'No data found' : "")}
            </div>
          ) : (
            data &&
            data?.event_getBookingCustomersList !== null &&
            data?.event_getBookingCustomersList?.map(
              (listing: Listing, index) => (
                <div
                  className={`flex   flex-row items-center justify-around py-2 
                  ${index % 2 === 0 ? "bg-gray-100" : ""}`}
                  key={listing.customerId}
                >
                  <span
                    className={`text-xs sm:w-1/12 sm:text-center sm:text-sm lg:text-sm`}
                  >
                    {offset + index + 1}.
                  </span>
                  <span className="text-xs sm:w-1/6 sm:text-center sm:text-sm lg:text-sm">
                    {listing.firstName} {listing.lastName}
                  </span>
                  <a
                    title={listing.email}
                    href={`mailto:${listing.email}`}
                    className="overflow-x-hidden text-xs sm:w-1/6 sm:text-center sm:text-sm lg:text-sm"
                  >
                    {listing.email.slice(0, 10).concat("...")}
                  </a>

                  {/* <span className="overflow-x-hidden text-xs sm:w-1/6 sm:text-center sm:text-sm lg:text-sm">
                    {listing.phone_number !== null
                      ? listing.phone_number
                      : "n/a"}
                  </span> */}
                  <span className="text-xs sm:w-1/6 sm:text-center sm:text-sm lg:text-sm">
                    {listing.ticketDetails.length > 0 &&
                      listing.ticketDetails.map(
                        (item: TicketDetails, id: number) => {
                          return (
                            <ul key={id}>
                              <li>
                                {item.ticketbooked}
                                <span className="ml-3">{item.label}</span>{" "}
                              </li>
                            </ul>
                          );
                        },
                      )}
                    {listing.ticketDetails[0].label === null &&
                      listing.totalTicketBooked}
                  </span>

                  <span className="flex justify-center sm:w-1/6">
                    <button
                      className="text-xs underline hover:text-primary hover:no-underline sm:text-sm lg:text-sm"
                      onClick={() => openModal(listing)}
                    >
                      View
                    </button>
                  </span>
                  <span className="text-xs sm:w-1/6 sm:text-center sm:text-sm lg:text-sm">
                    <input
                      className="h-6 w-6 accent-green-600"
                      type="checkbox"
                      name={`attendance-${listing.customerId}`}
                      checked={attendanceStatuses[listing.customerId] || false}
                      disabled={disable}
                      onChange={() =>
                        handleChange(
                          listing.customerId,
                          !attendanceStatuses[listing.customerId],
                        )
                      }
                    />
                  </span>
                </div>
              ),
            )
          )}
        </div>

        <div className="mx-auto my-4 flex justify-center space-x-2 md:justify-end lg:justify-end">
          {pageCount > 0 &&
            Array.from({ length: pageCount }, (_, index) => index + 1).map(
              (pageNumber) => (
                <UIButton
                  key={pageNumber}
                  className={`  ${
                    pageNumber === currentPage
                      ? "bg-primary text-white"
                      : "bg-gray-200 text-primary"
                  } w-8 rounded-md p-1 text-xs lg:text-sm shadow-sm`}
                  onClick={() => handlePageChange(pageNumber)}
                >
                  {pageNumber}
                </UIButton>
              ),
            )}
        </div>
      </div>

      {selectedListing && (
        <TicketDetailsModal
          organizer={organizer}
          listing={selectedListing}
          closeModal={closeModal}
          isOpen={selectedListing !== null}
        />
      )}
    </>
  );
};

export default BookingCustomerList;
