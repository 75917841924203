/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, FC } from "react";
import { Link } from "react-router-dom";
import {
  ArrowRightOnRectangleIcon,
  CurrencyDollarIcon,
  UserCircleIcon,
} from "@heroicons/react/20/solid";
import { getAvatarName, getUserDetails } from "../../helpers/users";

interface UserDropDownProps {
  signOut: () => void;
}

const UserDropDown: FC<UserDropDownProps> = ({ signOut }) => {
  const { name } = getUserDetails();
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);

  const toggleDropdown = () => {
    setIsDropdownOpen((prevIsOpen) => !prevIsOpen);
  };

  const closeDropdown = () => {
    setIsDropdownOpen(false);
  };

  return (
    <div className="relative">
      <button
        id="UserDropDownButton"
        data-dropdown-toggle="dropdownAvatar"
        className="dark:focus:ring-gray-600 flex rounded text-sm focus:ring-4 focus:ring-primary md:me-0"
        type="button"
        onClick={toggleDropdown}
      >
        <span className="sr-only">Open user menu</span>
        <div className="flex h-11 w-14 items-center justify-center rounded bg-primary font-bold text-white">
          <p className="text-lg"> {getAvatarName(name || "Not Available")}</p>
        </div>
      </button>

      <div
        id="dropdownAvatar"
        className={`${
          isDropdownOpen ? "" : "hidden"
        } dark:bg-gray-700 dark:divide-gray-600 absolute right-0 top-12 z-10 w-44 divide-y divide-gray-100 rounded-lg bg-white shadow-md`}
        onBlur={closeDropdown}
      >
        <div className="dark:text-white text-md px-4 py-3 text-gray-900">
          <div className="text-center text-lg font-bold">{name}</div>
        </div>
        <ul
          className="py-2 text-sm text-gray-700"
          aria-labelledby="UserDropDownButton"
        >
          <li>
            <Link
              to="/dashboard"
              className="block px-4 py-2 text-lg hover:bg-gray-100"
            >
              <UserCircleIcon className="mr-2 inline-block h-5 w-5" /> Dashboard
            </Link>
          </li>
        </ul>
        <div className="py-2">
          <a
            onClick={signOut}
            className="block cursor-pointer px-4 py-2 text-lg  text-gray-700 hover:bg-gray-100"
          >
            <ArrowRightOnRectangleIcon className="mr-2 inline-block h-5 w-5" />{" "}
            Sign out
          </a>
        </div>
      </div>
    </div>
  );
};

export default UserDropDown;
