export const getCoordinates = async (
  address: string,
): Promise<{ lat: number; lon: number } | null> => {
  try {
    const response = await fetch(
      `https://nominatim.openstreetmap.org/search?q=${encodeURIComponent(
        address,
      )}&format=json&addressdetails=1&limit=1`,
    );
    if (!response.ok) {
      const errorText = await response.text();
      console.error("Error fetching coordinates:", response.status, errorText);
      return null;
    }
    const data = await response.json();
    if (data.length > 0) {
      const { lat, lon } = data[0];
      return { lat: parseFloat(lat), lon: parseFloat(lon) };
    }
  } catch (error) {
    console.error("Error fetching coordinates:", error);
  }
  return null;
};
