"use client";

import { useState, useEffect } from "react";
import Logo from "./Logo";
import MobileMenu from "./MobileMenu";
import UILink from "../ui/Link";
import { ArrowRightIcon } from "@heroicons/react/20/solid";
import { getUserDetails } from "../../helpers/users";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
// import { HashLink } from "react-router-hash-link";
import UserDropDown from "./UserDropDown";

const Header = () => {
  const navigate= useNavigate()
  const [top, setTop] = useState<boolean>(true);

  const { token } = getUserDetails();

  // detect whether user has scrolled the page down by 10px
  const scrollHandler = () => {
    window.scrollY > 10 ? setTop(false) : setTop(true);
  };

  useEffect(() => {
    scrollHandler();
    window.addEventListener("scroll", scrollHandler);
    return () => window.removeEventListener("scroll", scrollHandler);
  }, [top]);

  const signOut = () => {
    navigate('/signin')
    localStorage.clear();

    toast.success('Successfully signed out.', {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 2000, 
    });

  };

  return (
    <header
      className={`relative top-0 w-full bg-white p-2 transition duration-300 ease-in-out ${!top ? "shadow-lg backdrop-blur-sm" : ""
        }`}
    >
      <div className="mx-auto max-w-7xl px-5 sm:px-6">
        <div className="flex h-10 items-center justify-between md:h-16">
          {/* Site branding */}
          <div className="mr-4 shrink-0">
            <Logo />
          </div>

          {/* Desktop navigation */}
          <nav className="hidden md:flex md:grow">
            <ul className="text-md flex grow flex-wrap items-center gap-4">
              <li className="ml-2">
                
                <Link
                  to="/event-management"
                  className="text-gray-600 transition duration-150 ease-in-out hover:text-gray-900"
                >
                  Features
                </Link>
              </li>
              {/* <li className="ml-2">
                
                <Link
                  to="/how-it-works"
                  className="text-gray-600 transition duration-150 ease-in-out hover:text-gray-900"
                >
                  About
                </Link>
              </li> */}
              <li className="ml-2">
                <Link
                  to="/pricing"
                  className="text-gray-600 transition duration-150 ease-in-out hover:text-gray-900"
                >
                  Pricing
                </Link>
              </li>
              <li className="ml-2">
                <Link
                  to="/plan-your-event"
                  className="text-gray-600 transition duration-150 ease-in-out hover:text-gray-900"
                >
                  Plan your event
                </Link>
              </li>
              <li className="ml-2">
                <Link
                  to="/events"
                  className="text-gray-600 transition duration-150 ease-in-out hover:text-gray-900"
                >
                  Events
                </Link>
              </li>
            </ul>
            {/* Desktop sign in links */}
            {token && token?.length > 0 ? (
              <ul className="flex grow flex-wrap items-center justify-end gap-2">
                <li>
                  <UserDropDown signOut={signOut} />
                </li>
              </ul>
            ) : (
              <ul className="flex grow flex-wrap items-center justify-end gap-2">
                <li>
                  <UILink to="/signin" className="bg-transparent text-primary">
                    Sign in
                  </UILink>
                </li>
                <li>
                  <UILink to="/signup">
                    <span>Sign up</span>
                    <ArrowRightIcon className="-mr-1 ml-2 h-4 w-4 shrink-0" />
                  </UILink>
                </li>
              </ul>
            )}
          </nav>

          <MobileMenu token={token} signOut={signOut} />
        </div>
      </div>
    </header>
  );
};

export default Header;
