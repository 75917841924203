import { UserInspections } from "../../package";

const UserInspectionsPage = () => {
  return (
    <section>
      <div className="mx-auto max-w-6xl px-4 sm:px-6">
        <div className="pb-12 pt-20 md:pb-20">
          {/* Form */}
          <UserInspections />
        </div>
      </div>
    </section>
  );
};

export default UserInspectionsPage;
