/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, ChangeEvent, Fragment } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { USER_STRIPE_INFO_MUTATION } from "../../api/UserStripeInfo/UserStripeInfo";
import { GET_USER_STRIPE_INFO_QUERY } from "../../api/UserStripeInfo/GetStripeInfo";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/20/solid";
import { toast } from "react-toastify";
import useStripeStatus from "../../hooks/useStripeStatus";
import StripeInstructions from "./StripeInstructions";

interface UserStripeInfo {
  id: number;
  stripe_secret_key: string;
  publish_key: string;
  createdAt: string;
  updatedAt: string;
  __typename: string;
}

interface PaymentProps {}

const Payment: React.FC<PaymentProps> = () => {
  const { updateLocalStorage } = useStripeStatus();
  const [paymentId, setPaymentId] = useState<string>("");
  const [customerId, setCustomerId] = useState<string>("");
  const [showSecret, setShowSecret] = useState<boolean>(false);
  const [showPublish, setShowPublish] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<string>("payment");

  const userId = localStorage.getItem("userId");

  const handlePaymentIdChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setPaymentId(newValue);
  };

  const handleCustomerIdChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setCustomerId(newValue);
  };

  const handleToggleSecret = () => {
    setShowSecret((prevShowSecret) => !prevShowSecret);
  };

  const handleTogglePublish = () => {
    setShowPublish((prevShowPublish) => !prevShowPublish);
  };

  const [stripeUserStripeInfo] = useMutation(USER_STRIPE_INFO_MUTATION);
  const { data: userStripeData, loading } = useQuery<{
    stripe_getUserStripeInfo: UserStripeInfo;
  }>(GET_USER_STRIPE_INFO_QUERY, {
    variables: { userid: Number(userId) },
    skip: !userId,
  });

  useEffect(() => {
    if (userStripeData) {
      const { stripe_secret_key, publish_key } =
        userStripeData.stripe_getUserStripeInfo;
      setPaymentId(stripe_secret_key);
      setCustomerId(publish_key);
    }
  }, [userStripeData]);

  const handlePayment = async () => {
    try {
      let updateValue = false;
      if (
        userStripeData &&
        userStripeData.stripe_getUserStripeInfo &&
        userStripeData.stripe_getUserStripeInfo.id
      ) {
        updateValue = true;
      }
      await stripeUserStripeInfo({
        variables: {
          stripeSecretKey: paymentId,
          publishKey: customerId,
          userid: Number(userId),
          update: updateValue,
        },
      });
      updateLocalStorage(paymentId, customerId);
      if (updateValue) {
        toast.success(
          "🎉 Congratulations! Your Stripe Secret Key and Publish Key have been successfully updated!",
        );
      } else {
        toast.success(
          "🎉 Congratulations! Your Stripe Secret Key and Publish Key have been successfully saved!",
        );
      }
    } catch (error) {
      console.error("Mutation Error:", error);
    }
  };

  return (
    <div className="mx-0 max-w-4xl px-0">
      <div className="mb-0 bg-gray-100 pl-4">
        <div className=" border-b border-gray-200 text-center text-sm font-medium text-gray-500">
          <ul className="flex flex-row">
            <li className="me-2">
              <a
                href="#"
                className={`active inline-block  rounded-t-lg  p-3  ${
                  activeTab === "payment"
                    ? "border-b-2  border-secondary font-bold text-secondary"
                    : ""
                }`}
                onClick={() => setActiveTab("payment")}
              >
                Payment Info
              </a>
            </li>

            <li className="me-2">
              <a
                href="#"
                className={` inline-block rounded-t-lg   p-3  ${
                  activeTab === "instructions"
                    ? " border-b-2  border-secondary  font-bold text-secondary"
                    : ""
                }`}
                onClick={() => setActiveTab("instructions")}
              >
                How to Use Stripe
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="max-w-[900px] p-6 md:mt-0">
        {activeTab === "payment" && (
          <Fragment>
            <h2 className="text-2xl font-bold text-gray-800">
              Make Event Payments Easy with Stripe
            </h2>

            <p className="mb-1 text-left text-base text-gray-700">
              Create smooth events and collect payments easily with Stripe.
              Manage your revenue, track transactions, and improve your event
              planning experience.
            </p>
            <img
              src="/stripe-logo.svg"
              alt="Stripe Logo"
              className="mb-4 w-48 md:mb-8 md:w-64 lg:w-56"
            />
            <div className="w-full md:w-3/4 lg:w-2/3">
              <div className="mb-6">
                <label
                  htmlFor="paymentId"
                  className="block text-sm font-medium text-gray-700 md:text-base"
                >
                  Stripe Secret Key
                </label>
                <div className="relative">
                  <input
                    type={showSecret ? "text" : "password"}
                    id="paymentId"
                    value={paymentId}
                    onChange={handlePaymentIdChange}
                    readOnly={loading}
                    className="mt-1 w-full rounded-md border py-3 pl-3 pr-10 text-sm md:py-4 md:pl-5 md:pr-12 md:text-base"
                    placeholder="Enter your Stripe Secret Key"
                  />
                  <span
                    className="absolute right-2 top-2 cursor-pointer md:right-4 md:top-3"
                    onClick={handleToggleSecret}
                  >
                    {showSecret ? (
                      <EyeSlashIcon className="mt-1 h-5 w-5 text-gray-600 md:mt-2 md:h-6 md:w-6" />
                    ) : (
                      <EyeIcon className="mt-1 h-5 w-5 text-gray-600 md:mt-2 md:h-6 md:w-6" />
                    )}
                  </span>
                </div>
              </div>
              <div className="mb-6">
                <label
                  htmlFor="customerId"
                  className="block text-sm font-medium text-gray-700 md:text-base"
                >
                  Stripe Publishable Key
                </label>
                <div className="relative">
                  <input
                    type={showPublish ? "text" : "password"}
                    id="customerId"
                    value={customerId}
                    onChange={handleCustomerIdChange}
                    readOnly={loading}
                    className="mt-1 w-full rounded-md border py-3 pl-3 pr-10 text-sm md:py-4 md:pl-5 md:pr-12 md:text-base"
                    placeholder="Enter your Stripe Publishable Key"
                  />
                  <span
                    className="absolute right-2 top-2 cursor-pointer md:right-4 md:top-3"
                    onClick={handleTogglePublish}
                  >
                    {showPublish ? (
                      <EyeSlashIcon className="mt-1 h-5 w-5 text-gray-600 md:mt-2 md:h-6 md:w-6" />
                    ) : (
                      <EyeIcon className="mt-1 h-5 w-5 text-gray-600 md:mt-2 md:h-6 md:w-6" />
                    )}
                  </span>
                </div>
              </div>
            </div>
            <button
              type="submit"
              onClick={handlePayment}
              className="hover:bg-primary-dark w-full rounded-md bg-primary px-4 py-2 text-sm text-white md:w-1/4 md:px-6 md:py-3 md:text-base lg:w-32"
            >
              Update
            </button>
            <div className="mt-6 text-left md:mt-8">
              <p className="text-xs text-gray-700 md:text-sm">
                Don't have a Stripe account? Sign up now to get started.
              </p>
              <a
                href="https://stripe.com"
                target="_blank"
                rel="noopener noreferrer"
                className="hover:text-primary-dark mt-2 block text-xs text-primary underline transition duration-300 ease-in-out md:text-sm"
              >
                Create a Free Stripe Account
              </a>
            </div>
            <div className="mt-6 text-xs text-gray-700 md:mt-8 md:text-sm">
              <p className="mb-2">
                <span className="font-semibold">Fees:</span> Credit / Debit:
                Standard or negotiated Stripe fees, ACH: Standard or negotiated
                Stripe fees
              </p>
              <p className="mb-2">
                <span className="font-semibold">Platform fee:</span> 0.5% of the
                transaction amount per transaction
              </p>
              <p className="mt-4 font-bold">
                Note: Please use a business account for Stripe, not an
                individual account.
              </p>
            </div>
          </Fragment>
        )}
        {activeTab === "instructions" && (
          <Fragment>
            <StripeInstructions />
          </Fragment>
        )}
      </div>
    </div>
  );
};

export default Payment;
