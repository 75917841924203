import React, { useLayoutEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Header from "./components/common/Header";
import SignUp from "./screens/Auth/Signup";
import SignIn from "./screens/Auth/Signin";
import CreateInspection from "./screens/CreateInspection";
import ForgetPassword from "./screens/Auth/ForgetPassword";
import UserInspectionsPage from "./screens/UserInspectionsPage";
import Landing from "./screens/Landing";
import { Slide, ToastContainer } from "react-toastify";
import ProtectedRoutes from "./components/wrappers/ProtectedRoutes";
import "react-toastify/dist/ReactToastify.css";
import Footer from "./components/common/Footer";
import PlanYourEvent from "./screens/PlanYourEvent";
import SingleEvent from "./screens/Events/SingleEvent";
import Pricing from "./screens/Pricing";
import Contact from "./screens/Contact";
import TermsAndConditions from "./screens/TermsAndConditions.tsx";
import PrivacyPolicy from "./screens/PrivacyPolicy";
import Success from "./screens/Success";
import Dashboard from "./screens/Dashboard";
import Events from "./screens/Events";
import BookingCustomerList from "./screens/Dashboard/BookingCustomerListing";
import CancelRefund from "./screens/CancelRefund";
import Verify from "./screens/Auth/Verify";
import StripePaymentForm from "./screens/StripePaymentForm";
import Completion from "./screens/Completion";
import Tickets from "./screens/Events/Tickets";
import SetNewPassword from "./screens/Auth/SetNewPassword";
import HowItWorks from "./screens/HowItWorks";
import { embeddablePaths } from "./helpers/embedded-paths";
import EventManagement from "./screens/EventManagement";
import InspectionManagement from "./screens/InspectionManagement";
import NotificationBanner from "./components/ui/NotificationBanner";
import useStripeStatus from "./hooks/useStripeStatus";
import { getUserDetails } from "./helpers/users";
import { siteCurrency } from "./helpers/site-info";
import PageNotFound from "./screens/PageNotFound";

const App: React.FC = () => {
  const { stripeStatus } = useStripeStatus();
  const { token } = getUserDetails();
  const { pathname } = useLocation();
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      {!stripeStatus && token && <NotificationBanner />}
      {/* <div className="flex flex-col min-h-screen overflow-hidden supports-[overflow:clip]:overflow-clip"> */}
      {!embeddablePaths.includes(pathname.split("/")[1]) && <Header />}
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="*" element={<PageNotFound />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/signin" element={<SignIn />} />
        <Route path="/how-it-works" element={<HowItWorks />} />
        <Route path="/event-management" element={<EventManagement />} />{" "}
        <Route
          path="/inspection-management"
          element={<InspectionManagement />}
        />
        <Route path="/forgot-password" element={<ForgetPassword />} />
        <Route
          path="/set-new-password/:userId/:uid"
          element={<SetNewPassword />}
        />
        <Route path="/events" element={<Events />} />
        {[
          "/event/:title/:encodedEventId",
          "/embedded-event/:title/:encodedEventId",
        ].map((path, index) => (
          <Route path={path} element={<SingleEvent />} key={index} />
        ))}
        <Route path="/pricing" element={<Pricing />} />
        {["/contact", "/support"].map((path, index) => (
          <Route path={path} element={<Contact />} key={index} />
        ))}
        <Route path="/user-inspections" element={<UserInspectionsPage />} />
        <Route path="/terms-of-service" element={<TermsAndConditions />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/cancellation" element={<CancelRefund />} />
        <Route path="/success" element={<Success />} />
        <Route path="/tickets/:customerId" element={<Tickets />} />
        <Route path="/verify/:uid/userId/:userId" element={<Verify />} />
        <Route path="/payment" element={<StripePaymentForm />} />
        <Route path="/completion" element={<Completion />} />
        {/* protected routes */}
        <Route element={<ProtectedRoutes />}>
          <Route
            path="/customer-listing/:eventID"
            element={<BookingCustomerList />}
          />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/create-inspection" element={<CreateInspection />} />
          <Route path="/plan-your-event" element={<PlanYourEvent />} />
        </Route>
      </Routes>

      {!embeddablePaths.includes(pathname.split("/")[1]) && <Footer />}
      {/* </div> */}
      <ToastContainer
        style={{ zIndex: 10000 }}
        position={"top-center"}
        autoClose={1500}
        transition={Slide}
        theme="colored"
        hideProgressBar={true}
      />
    </>
  );
};

export default App;
