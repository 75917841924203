import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FormikProps } from "formik";
import "./DatePickerStyles.css";

type EventTypeOption = "SingleDay" | "WEEKLY" | "MONTHLY";

interface FormValues {
  eventPattern: {
    singleDay?: boolean | null;
    recurring?: EventTypeOption | "";
    weekDays?: string[];
    monthDate?: number | null;
  };
  startDate: number | null;
  endDate: number | null;
  startTime: number | null;
  endTime: number | null;
}

interface EventTypeProps {
  formik: FormikProps<FormValues>;
}

const EventType: React.FC<EventTypeProps> = ({ formik }) => {
  const currentDate = new Date();
  const [selectedWeekDays, setSelectedWeekDays] = useState<string[]>(
    formik.values.eventPattern.weekDays || [],
  );
  const [selectedMonthDate, setSelectedMonthDate] = useState<number | null>(
    formik.values.eventPattern.monthDate || null,
  );

  const weekdaysOptions: { [key: string]: string } = {
    MONDAY: "MONDAY",
    TUESDAY: "TUESDAY",
    WEDNESDAY: "WEDNESDAY",
    THURSDAY: "THURSDAY",
    FRIDAY: "FRIDAY",
    SATURDAY: "SATURDAY",
    SUNDAY: "SUNDAY",
  };

  useEffect(() => {
    if (formik.values.eventPattern.recurring === "WEEKLY") {
      if (
        JSON.stringify(formik.values.eventPattern.weekDays) !==
        JSON.stringify(selectedWeekDays)
      ) {
        formik.setFieldValue("eventPattern.weekDays", selectedWeekDays);
      }
    } else if (formik.values.eventPattern.recurring === "MONTHLY") {
      if (formik.values.eventPattern.monthDate !== selectedMonthDate) {
        formik.setFieldValue("eventPattern.monthDate", selectedMonthDate);
      }
    }
  }, [
    selectedWeekDays,
    selectedMonthDate,
    formik.values.eventPattern.recurring,
    formik,
  ]);

  const handleRecurringChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    const newEventType = event.target.value as EventTypeOption;

    const newEventPattern = {
      recurring: newEventType !== "SingleDay" ? newEventType : undefined,
      singleDay: newEventType === "SingleDay" ? true : undefined,
      weekDays: newEventType === "WEEKLY" ? selectedWeekDays : undefined,
      monthDate: newEventType === "MONTHLY" ? selectedMonthDate : undefined,
    };

    formik.setFieldValue("eventPattern", newEventPattern);

    const currentTimestamp = Math.floor(currentDate.getTime() / 1000); // Convert to seconds
    formik.setFieldValue("startDate", currentTimestamp);

    if (newEventType === "SingleDay") {
      formik.setFieldValue("endDate", currentTimestamp);
    }

    formik.setFieldValue("startTime", currentTimestamp);
    formik.setFieldValue("endTime", currentTimestamp);
  };

  const handleStartDateChange = (date: Date | null) => {
    const timestamp = date ? Math.floor(date.getTime() / 1000) : null; // Convert to seconds
    formik.setFieldValue("startDate", timestamp);
    if (formik.values.eventPattern.singleDay) {
      formik.setFieldValue("endDate", timestamp);
    }
  };

  const handleEndDateChange = (date: Date | null) => {
    if (formik.values.eventPattern.singleDay) {
      return;
    }

    const timestamp = date ? Math.floor(date.getTime() / 1000) : null; // Convert to seconds
    formik.setFieldValue("endDate", timestamp);
  };

  const handleStartTimeChange = (date: Date | null) => {
    const timestamp = date ? Math.floor(date.getTime() / 1000) : null; // Convert to seconds
    formik.setFieldValue("startTime", timestamp);

    const startTime = date ? date.getTime() : null;
    if (startTime) {
      const minEndTime = new Date(startTime + 30 * 60 * 1000); // 30 minutes
      formik.setFieldValue("endTime", Math.floor(minEndTime.getTime() / 1000)); // Convert to seconds
    }
  };

  const handleEndTimeChange = (date: Date | null) => {
    if (date) {
      const endTime = date.getTime();
      const startTime = formik.values.startTime || 0;
      const minEndTime = startTime + 30 * 60 * 1000; // 30 minutes

      if (endTime < minEndTime) {
        alert("End time must be at least 30 minutes after start time.");
        return;
      }
    }
    const timestamp = date ? Math.floor(date.getTime() / 1000) : null; // Convert to seconds
    formik.setFieldValue("endTime", timestamp);
  };

  const convertEpochToDate = (epoch: number | null) =>
    epoch ? new Date(epoch * 1000) : new Date(); // Convert from seconds to milliseconds

  const commonInputStyles =
    "w-full rounded-md border bg-white px-3 py-3 text-sm";
  const commonBorderStyle = {
    border: "1px solid rgb(176, 190, 197)",
    padding: "0.6rem",
    color: "#607D8B",
  };

  return (
    <div className="space-y-6"  >
      <div className="py-2 pr-2">
        <select
          id="eventPattern"
          name="eventPattern"
          value={
            formik.values.eventPattern.singleDay
              ? "SingleDay"
              : formik.values.eventPattern.recurring || ""
          }
          onChange={handleRecurringChange}
          onBlur={formik.handleBlur}
          className={`${commonInputStyles} focus:border-gray-900`}
          style={commonBorderStyle}
        >
          <option value="" disabled>
            Select event pattern *
          </option>
          {["SingleDay", "WEEKLY", "MONTHLY"].map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
      </div>

      {(formik.values.eventPattern.singleDay ||
        formik.values.eventPattern.recurring) && (
        <div>
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label
                htmlFor="startDate"
                className="block text-sm font-medium text-gray-700"
              >
                Start Date
              </label>
              <div className={commonInputStyles} style={commonBorderStyle}>
                <DatePicker
                  id="startDate"
                  name="startDate"
                  selected={convertEpochToDate(formik.values.startDate)}
                  onChange={handleStartDateChange}
                  minDate={currentDate}
                  calendarClassName="custom-calendar"
                  className="min-w-full"
                  
                />
              </div>
            </div>
            <div>
              <label
                htmlFor="endDate"
                className="block text-sm font-medium text-gray-700"
              >
                End Date
              </label>
              <div className={commonInputStyles} style={commonBorderStyle}>
                <DatePicker
                  id="endDate"
                  name="endDate"
                  selected={convertEpochToDate(formik.values.endDate)}
                  onChange={handleEndDateChange}
                  minDate={
                    convertEpochToDate(formik.values.startDate) || currentDate
                  }
                  calendarClassName="custom-calendar"
                />
              </div>
            </div>
          </div>
          <div className="grid grid-cols-2 gap-4 py-2">
            <div>
              <label
                htmlFor="startTime"
                className="block text-sm font-medium text-gray-700"
              >
                Start Time
              </label>
              <div className={commonInputStyles} style={commonBorderStyle}>
                <DatePicker
                  id="startTime"
                  name="startTime"
                  selected={convertEpochToDate(formik.values.startTime)}
                  onChange={handleStartTimeChange}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={15}
                  dateFormat="h:mm aa"
                  calendarClassName="custom-calendar"
                />
              </div>
            </div>
            <div>
              <label
                htmlFor="endTime"
                className="block text-sm font-medium text-gray-700"
              >
                End Time
              </label>
              <div className={commonInputStyles} style={commonBorderStyle}>
                <DatePicker
                  id="endTime"
                  name="endTime"
                  selected={convertEpochToDate(formik.values.endTime)}
                  onChange={handleEndTimeChange}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={15}
                  dateFormat="h:mm aa"
                  calendarClassName="custom-calendar"
                />
              </div>
            </div>
          </div>
        </div>
      )}

      {formik.values.eventPattern.recurring === "WEEKLY" && (
        <div className="grid grid-cols-7 gap-4 py-2">
          {Object.keys(weekdaysOptions).map((day) => (
            <div key={day} className="col-span-1">
              <input
                type="checkbox"
                id={day}
                name="weekDays"
                value={day}
                checked={selectedWeekDays.includes(day)}
                onChange={(e) => {
                  const selected = e.target.value;
                  const updatedDays = selectedWeekDays.includes(selected)
                    ? selectedWeekDays.filter((day) => day !== selected)
                    : [...selectedWeekDays, selected];
                  setSelectedWeekDays(updatedDays);
                }}
                style={{
                  width: "18px",
                  height: "18px",
                }}
              />
              <label
                htmlFor={day}
                className="m-auto text-sm font-medium text-gray-700 
                "
              >
                <p>
                  {" "}
                  {day.charAt(0).toUpperCase() + day.slice(1).toLowerCase()}
                </p>
              </label>
            </div>
          ))}
        </div>
      )}

      {formik.values.eventPattern.recurring === "MONTHLY" && (
        <div className="py-2 pr-2">
          <label
            htmlFor="monthDate"
            className="block text-sm font-medium text-gray-700"
          >
            Select Date of Month
          </label>
          <input
            type="number"
            id="monthDate"
            name="monthDate"
            min="1"
            max="31"
            value={selectedMonthDate || ""}
            onChange={(e) => setSelectedMonthDate(parseInt(e.target.value, 10))}
            className={`${commonInputStyles} focus:border-gray-900`}
            style={commonBorderStyle}
          />
        </div>
      )}
    </div>
  );
};

export default EventType;
