import React, { useState } from "react";
import PageTitle from "../../components/ui/PageTitle";
import ListingCard from "../../components/common/ListingCard";
import { Button } from "@material-tailwind/react";
import { useNavigate } from "react-router-dom";
import { getUserDetails } from "../../helpers/users";
import { DiscountArrow } from "../../assets/icons/icons/DiscountArrow";
import PricingDialog from "./PricingDialog";
import { GET_ALL_SUBSCRIPTION_AVAILABLE } from "../../api/Payment/getAvailableSubscription";
import { useQuery } from "@apollo/client";
import { host, indianSite, siteCurrency } from "../../helpers/site-info";
import { PricingCardSkeleton } from "../../components/common/PricingCardSkeleton";

const features0 = ["30 days free trial", "Create up to 2 events"];

const features1 = [
  "Host 6 events per month",
  "Access event ticketing tool",
  "Event for unlimited tickets",
  "Host larger events",
];

const featuresPremium1 = [...features1];
featuresPremium1[0] = "Host unlimited number of events";

const features2 = [
  ...features1,
  "Customized onboarding services",
  "Customized implementation",
  "1:1 consultations with team",
];

const featuresPremium2 = [
  ...featuresPremium1,
  "Customized onboarding services",
  "Customized implementation",
  "1:1 consultations with team",
];

const Pricing: React.FC = () => {
  const { token, userId } = getUserDetails();
  const [period, setPeriod] = useState("monthly");
  const [heading, setHeading] = useState("Basic month");
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(!open);
  const navigate = useNavigate();
  const { loading, error, data } = useQuery(GET_ALL_SUBSCRIPTION_AVAILABLE);

  if (loading) {
    return <PricingCardSkeleton />;
  }
  if (error)
    return (
      <section className="flex min-h-[40vh] items-center px-4 py-10 text-center">
        <PageTitle heading="Oops! Something went wrong. Please try again later." />
      </section>
    );

  const handleStartPlan = (type: number, subscription: any) => {
    localStorage.setItem("stripe_price_id", subscription.stripe_price_id);
    localStorage.setItem("subscription_id", subscription.id);
    setHeading(subscription.title);
    if (type === 0) {
      if (token && userId) {
        navigate("/plan-your-event");
      } else {
        navigate("/signup");
      }
    } else {
      handleOpen();
    }
  };

  const getList = (period: string, title: string) => {
    const isPremium = title.includes("Premium");
    const features = isPremium
      ? period === "Month"
        ? featuresPremium1
        : featuresPremium2
      : period === "Month"
      ? features1
      : features2;
    return features;
  };

  return (
    <section className="px-4 py-20">
      <div className="container mx-auto">
        <PageTitle heading="Plans that provide unlimited benefits">
          Get started only for {indianSite ? "INR 499" : "NZ$ 9"}
        </PageTitle>

        <div className="flex flex-col items-center justify-center py-3 ">
          <div className="flex w-auto flex-col px-6 text-center text-2xl sm:text-3xl md:text-4xl">
            <div className="mt-8 flex items-center justify-center gap-4 pl-5 text-base md:mt-12">
              <span>Monthly </span>
              <div className="flex items-center">
                <label
                  htmlFor="small-toggle"
                  className="relative inline-flex cursor-pointer"
                >
                  <input
                    type="checkbox"
                    id="small-toggle"
                    className="peer sr-only"
                    checked={period === "yearly"}
                    onChange={() =>
                      setPeriod(period === "monthly" ? "yearly" : "monthly")
                    }
                  />
                  <div className="peer h-5 w-9 flex-1 rounded-full bg-gray-200 align-middle after:absolute after:left-0.5 after:top-[2px] after:h-4 after:w-4 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-primary peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:outline-none"></div>
                </label>
              </div>
              <span>Yearly</span>
            </div>
          </div>
          <div className="flex w-[300px] justify-end pt-2 sm:w-[350px] md:w-[590px] md:pt-0">
            <DiscountArrow strokColor="#F98E54" />
            <span className="pr-2 pt-2 text-sm font-medium text-primary md:text-lg">
              Save 25%
            </span>
          </div>
        </div>
        <div className="flex items-center justify-center py-0">
          <div className="grid grid-cols-1 gap-6 sm:grid-cols-1 md:grid-cols-3">
            {data.sub_getAllSubscription_Available.map(
              (subscription: {
                id: React.Key | null | undefined;
                title: any;
                description: any;
                cost: any;
                costNZ: any;
                frequency: any;
                image: any;
                stripe_price_id: any;
              }) => {
                if (subscription.title === "Free") {
                  return (
                    <ListingCard
                      key={subscription.id}
                      name={subscription.title}
                      bgImage={subscription.image}
                      list={features0}
                      pricing={`${
                        indianSite ? subscription.cost : subscription.costNZ
                      }`}
                      pricingTag={subscription.frequency}
                    />
                  );
                } else if (period === "monthly") {
                  if (subscription.frequency === "Month") {
                    // if (subscription.title.includes("Premium")) {
                    return (
                      <ListingCard
                        key={subscription.id}
                        name={subscription.title}
                        bgImage={subscription.image}
                        list={getList(
                          subscription.frequency,
                          subscription.title,
                        )}
                        pricing={`${siteCurrency} ${
                          indianSite ? subscription.cost : subscription.costNZ
                        }`}
                        pricingTag={subscription.frequency}
                      >
                        <Button
                          className="bg-secondary"
                          onClick={() => handleStartPlan(1, subscription)}
                        >
                          Buy Now
                        </Button>{" "}
                        {/* Button for monthly subscription */}
                      </ListingCard>
                    );
                  }
                } else {
                  if (subscription.frequency === "Year") {
                    return (
                      <ListingCard
                        key={subscription.id}
                        name={subscription.title}
                        bgImage={subscription.image}
                        list={getList(
                          subscription.frequency,
                          subscription.title,
                        )}
                        pricing={`${siteCurrency} ${
                          indianSite ? subscription.cost : subscription.costNZ
                        }`}
                        pricingTag={subscription.frequency}
                      >
                        <Button
                          className="bg-secondary"
                          onClick={() => handleStartPlan(1, subscription)}
                        >
                          Buy Now
                        </Button>{" "}
                        {/* Button for yearly subscription */}
                      </ListingCard>
                    );
                  }
                }
                return null;
              },
            )}
          </div>
        </div>
      </div>
      <PricingDialog
        open={open}
        handleOpen={handleOpen}
        heading={heading}
        features1={features1}
      />
    </section>
  );
};

export default Pricing;
