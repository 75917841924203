import { gql } from "@apollo/client";

export const CHANGE_PASSWORD = gql`
  mutation Auth_ChangePassword(
    $oldPassword: String!
    $newPassword: String!
    $userId: Int!
  ) {
    auth_ChangePassword(
      oldPassword: $oldPassword
      newPassword: $newPassword
      userId: $userId
    )
  }
`;

export const FORGET_PASSWORD = gql`
  mutation Auth_ForgetPassword($email: String!) {
    auth_ForgetPassword(email: $email) {
      message
    }
  }
`;

export const SET_NEW_PASSWORD = gql`
  mutation Auth_changeForgetPassword(
    $newPassword: String
    $userId: Int
    $uid: String
  ) {
    auth_changeForgetPassword(
      newPassword: $newPassword
      userId: $userId
      uid: $uid
    )
  }
`;
