import React from "react";

const StripeInstructions: React.FC = () => {
  return (
    <div className="rounded-lg  bg-white md:pt-0">
      <div className="text-left text-gray-800">
        <h2 className="text-2xl font-bold text-gray-800">
          How to Get Your Stripe Keys
        </h2>
        <p className="mb-8 text-[17px] text-gray-700">
          To start accepting payments on your website, follow these steps to
          obtain your Stripe keys:
        </p>
        <ol className="mb-8 list-decimal pl-6">
          <li className="mb-4 text-[17px] text-gray-700">
            <strong>Sign up or Log in:</strong> Visit{" "}
            <a
              href="https://dashboard.stripe.com/register"
              target="_blank"
              rel="noopener noreferrer"
              className="text-primary underline hover:text-primary"
            >
              Stripe Dashboard
            </a>{" "}
            and sign up for an account or log in if you already have one.
          </li>
          <li className="mb-4 text-[17px] text-gray-700">
            <strong>Navigate to Developers Section:</strong> Go to the{" "}
            <strong>Developers</strong> section in the left menu of your Stripe
            Dashboard.
          </li>
          <li className="mb-4 text-[17px] text-gray-700">
            <strong>Access API Keys:</strong> Click on <strong>API keys</strong>{" "}
            under the Developers section.
          </li>
          <li className="mb-4 text-[17px] text-gray-700">
            <strong>Retrieve Your Keys:</strong> You'll find your{" "}
            <strong>Publishable key</strong> and <strong>Secret key</strong> in
            the API keys section. Ensure to keep your Secret key secure.
          </li>
          <li className="mb-4 text-[17px] text-gray-700">
            <strong>Integrate Keys:</strong> Copy and paste the keys into the
            respective fields in your application where you integrate Stripe.
          </li>
        </ol>
        <p className="mb-4 text-[17px] text-gray-700">
          <strong>Note:</strong> The test key provided by Stripe is only for
          testing purposes. To activate your account and obtain live keys, make
          sure to activate your account through the Stripe dashboard.
        </p>
        <p className="text-[17px] text-gray-700">
          For further assistance, refer to the{" "}
          <a
            href="https://stripe.com/docs/keys"
            target="_blank"
            rel="noopener noreferrer"
            className="text-primary underline hover:text-primary"
          >
            Stripe Documentation
          </a>{" "}
          or contact Stripe Support.
        </p>
      </div>
    </div>
  );
};

export default StripeInstructions;
