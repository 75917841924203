import React from "react";
import { HomeIcon } from "@heroicons/react/20/solid";
import { ClockIcon, CurrencyDollarIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import Event from "../../types/Events";
import EventImageCarousel from "../../components/EventImageCarousel";

const EventCard: React.FC<Event> = ({
  title,
  startDate,
  duration,
  paid,
  type,
  id,
  organizer,
  imageUrls,
  summary,
}) => {
  const titleNavLinkString = title.split(" ").join("-").toLowerCase();
  // const url = process.env.REACT_APP_EVENT_IMAGE + "/" + imageUrls[0].url;
  const encodedEventId = btoa(id || "");

  return (
    <div className="relative flex max-w-[300px] flex-auto cursor-pointer flex-col overflow-hidden rounded shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)]">
      <div className="relative">
        <EventImageCarousel images={imageUrls} />
        <div className="absolute bottom-0 left-0 right-0 top-0 bg-gray-900 opacity-25 transition duration-300 hover:bg-transparent"></div>
        <div className="absolute bottom-0 left-0 bg-primary px-4 py-2 text-xs text-white transition duration-500 ease-in-out ">
          {organizer}
        </div>

        <div className="absolute right-0 top-0 mr-3 mt-3 flex h-12 w-12 flex-col items-center justify-center rounded-full bg-primary p-0  text-xs text-white transition duration-500 ease-in-out ">
          <span className="text-center font-bold">{startDate}</span>
        </div>
      </div>
      <Link
        to={`/event/${titleNavLinkString}/${encodedEventId}`}
        className="relative flex flex-auto flex-col justify-between"
      >
        <div className="px-3 py-4">
          <p className="inline-block text-lg font-semibold transition duration-500 ease-in-out hover:text-primary">
            {title.length > 55 ? `${title.substring(0, 55)}...` : title}
          </p>
          <p className="text-sm text-gray-500">
            {summary.length > 150 ? `${summary.substring(0, 150)}...` : summary}
          </p>
        </div>
        <div className="flex flex-row  justify-between px-3 py-4 ">
          <span className=" mr-1 flex flex-row items-center py-1  text-gray-900">
            <ClockIcon className="w-4" />
            <span className="ml-1 text-sm">{duration}</span>
          </span>
          <span className=" mr-1 flex flex-row items-center py-1  text-gray-900">
            <CurrencyDollarIcon className="w-4" />
            <span className="ml-1 text-sm">{paid ? "Paid" : "Free"}</span>
          </span>{" "}
          <span className=" mr-1 flex flex-row items-center py-1  text-gray-900">
            <HomeIcon className="w-4" />
            <span className="ml-1 text-sm">{type}</span>
          </span>
        </div>
      </Link>
    </div>
  );
};
export default EventCard;
