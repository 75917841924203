import { useState } from "react";
import UIButton from "../../../components/ui/Button";
import UIInput from "../../../components/ui/Input";
import UILabel from "../../../components/ui/Label";
import { useMutation } from "@apollo/client";
import { FORGET_PASSWORD } from "../../../api/password";
import { toast } from "react-toastify";

const ForgetPassword = () => {
  const [email, setEmail] = useState("");
  const handleChange = (e: any) => {
    setEmail(e.target.value);
  };

  const [sendEmail] = useMutation(FORGET_PASSWORD);

  const handleClick = async () => {
    try {
      const data = await sendEmail({
        variables: {
          email: email,
        },
      });

      if (data) {
        const message = data?.data?.auth_ForgetPassword.message;
        toast.success(message);
      }
    } catch (error) {
      toast.error("Wrong email");
    }
  };

  return (
    <section className="bg-gradient-to-b from-gray-100 to-white">
      <div className="mx-auto max-w-6xl px-4 sm:px-6">
        <div className="pb-12 pt-32 md:pb-20 md:pt-40">
          {/* Page header */}
          <div className="mx-auto max-w-3xl pb-12 text-center md:pb-20">
            <h1 className="h1 mb-4">Let's get you back up on your feet</h1>
            <p className="text-xl text-gray-600">
              Enter the email address you used when you signed up for your
              account, and we'll email you a link to reset your password.
            </p>
          </div>

          {/* Form */}
          <div className="mx-auto max-w-sm">
            <form>
              <div className="-mx-3 mb-4 flex flex-wrap">
                <div className="w-full px-3">
                  <UILabel
                    className="mb-1 block text-sm font-medium text-gray-800"
                    htmlFor="email"
                  >
                    Email <span className="text-red-600">*</span>
                  </UILabel>
                  <UIInput
                    id="email"
                    type="email"
                    className="form-input w-full text-gray-800"
                    placeholder="Enter your email address"
                    required
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="-mx-3 mt-6 flex flex-wrap">
                <div className="w-full px-3">
                  <UIButton type="button" onClick={() => handleClick()}>
                    Send reset link
                  </UIButton>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};
export default ForgetPassword;
