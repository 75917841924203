import { gql } from "@apollo/client";

export const CREATE_EVENT_MUTATION = gql`
  mutation Event_createEvent(
    $title: String!
    $description: String!
    $organizer: String!
    $userId: Int!
    $category: String!
    $timeZone: String!
    $free: Boolean!
    $paid: Boolean!
    $startDate: BigInt!
    $endDate: BigInt!
    $startTime: BigInt!
    $endTime: BigInt!
    $ticketSaleStartDate: BigInt!
    $ticketSaleEndDate: BigInt!
    $ticketInfo: [ticketDetails]
    $address: String
    $location: String
    $eventPattern: patternOfEvent
    $freeTicket: Int
    $summary: String
    $orgnizerSection: String
    $refundPolicy: String
    $language: String
  ) {
    event_createEvent(
      title: $title
      description: $description
      organizer: $organizer
      userId: $userId
      category: $category
      timeZone: $timeZone
      free: $free
      paid: $paid
      startDate: $startDate
      endDate: $endDate
      start_time: $startTime
      end_time: $endTime
      ticketSaleStartDate: $ticketSaleStartDate
      ticketSaleEndDate: $ticketSaleEndDate
      ticketInfo: $ticketInfo
      address: $address
      location: $location
      eventPattern: $eventPattern
      freeTicket: $freeTicket
      summary: $summary
      orgnizerSection: $orgnizerSection
      refundPolicy: $refundPolicy
      language: $language
    )
  }
`;

// import { gql } from "@apollo/client";

// export const CREATE_EVENT_MUTATION = gql`
// mutation Event_createEvent(
//   $title: String!
//   $description: String!
//   $organizer: String!
//   $userId: Int!
//   $type: String!
//   $category: String!
//   $address: String!
//   $timeZone: String!
//   $language: String!
//   $free: Boolean!
//   $paid: Boolean!
//   $startDate: BigInt!
//   $endDate: BigInt!
//   $ticketSaleStartDate: BigInt!
//   $ticketSaleEndDate: BigInt!
//   $eventPattern: String
//   $freeTicket: Int
//   $summary: String
//   $ticketInfo: [ticketDetails]
// ) {
//   event_createEvent(
//     title: $title
//     description: $description
//     organizer: $organizer
//     userId: $userId
//     type: $type
//     category: $category
//     address: $address
//     timeZone: $timeZone
//     language: $language
//     free: $free
//     paid: $paid
//     startDate: $startDate
//     endDate: $endDate
//     ticketSaleStartDate: $ticketSaleStartDate
//     ticketSaleEndDate: $ticketSaleEndDate
//     eventPattern: $eventPattern
//     freeTicket: $freeTicket
//     summary: $summary
//     ticketInfo: $ticketInfo
//   )
// }

// `;
