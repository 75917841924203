import React from "react";
import SignIn from "../../../components/common/SignIn";
import { Link } from "react-router-dom";

const SignInPage: React.FC = () => {
  return (
    <section>
      <div className="mx-auto max-w-6xl px-4 sm:px-6">
        <div className="mt-4 pb-12 md:pb-20">
          <h1 className="mb-4 text-center text-4xl font-bold">Sign in</h1>
          {/* Form */}
          <div className="mx-auto max-w-sm">
            <SignIn hastoNavigate={true} />
            <div className="mb-3 mt-6 text-center text-gray-600">
              Don't you have an account?{" "}
              <Link
                to="/signup"
                className="text-blue-600 transition duration-150 ease-in-out hover:underline"
              >
                Sign up
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SignInPage;
