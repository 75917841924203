import React from "react";
import { Link } from "react-router-dom";

const CancelRefund: React.FC = () => {
  return (
    <section className="relative bg-white px-4 py-8">
      <div className="container mx-auto my-8  max-w-4xl">
        <h1 className="my-4 text-center text-4xl font-bold">
          Cancellation & Refund Policy
        </h1>

        <p>
          Thank you for choosing <strong>bookable</strong> for your booking
          needs. Below are the details of our cancellation policy based on the
          type of subscription. Please review our cancellation and refund policy
          below.
        </p>

        <section className="mb-8">
          <h2 className="my-4  text-2xl font-semibold">Cancellation Policy</h2>
          <h3 className="my-4  text-xl font-semibold">Monthly Subscription:</h3>
          <div className="mb-6">
            <li>
              Users subscribed to our monthly plan can cancel their subscription
              at any time without incurring any cancellation fees.
            </li>
            <li>
              Upon cancellation, users will continue to have access to the
              service until the end of the current billing period. No prorated
              refunds will be issued for the remaining days of the current
              month.
            </li>
          </div>
          <h3 className="my-4  text-xl font-semibold">Yearly Subscription:</h3>
          <div className="mb-6">
            <li>
              Users subscribed to our yearly plan can cancel their subscription
              at any time without incurring any cancellation fees.
            </li>
            <li>
              Upon cancellation, users will continue to have access to the
              service until the end of the current billing period. No prorated
              refunds will be issued for the remaining days of the current year.
            </li>
          </div>
        </section>

        <section className="mb-8">
          <h2 className="my-4 text-2xl font-semibold">Refund Policy</h2>
          <div>
            <li>
              <strong>Refunds for Customer-initiated Cancellations:</strong>{" "}
              Refund eligibility and the amount refunded are subject to the
              cancellation policy. The funds will be returned through the
              original payment method.
            </li>
            <li>
              <strong>Refunds for Technical Issues:</strong> In the event of
              technical glitches or errors affecting the booking process,{" "}
              <strong>bookable</strong> will work diligently to rectify the
              issue.
            </li>
          </div>
        </section>

        <section className="mb-8">
          <h2 className="my-4 text-2xl font-semibold">Contact Information</h2>
          <p>
            For any inquiries or assistance related to cancellations and
            refunds, please contact our customer support by visiting at{" "}
            <Link className="text-blue-500" to={"/support"}>
              Support center
            </Link>
            .
          </p>
        </section>

        <section className="mb-8">
          <h2 className="my-4 text-2xl font-semibold">Note</h2>
          <p>
            <strong>bookable</strong> reserves the right to update or modify
            this policy at any time. Customers will be notified of any changes.
          </p>
        </section>

        <p>
          Thank you for choosing <strong>bookable</strong>. Your satisfaction is
          our priority, and we appreciate your trust in our platform.
        </p>

        <p>
          Remember to carefully review and customize this policy based on the
          specific features and services offered by Bookable Online.
          Additionally, consulting with legal professionals is advisable to
          ensure compliance with relevant laws and regulations.
        </p>
      </div>
    </section>
  );
};

export default CancelRefund;
