import { convertEpochToReadableDate } from "../../helpers/date-helpers"; // You might not need this if you use moment-mini
import moment from 'moment-mini'; // Import moment-mini
import { siteCurrency } from "../../helpers/site-info";

interface SubscriptionDetailsInterface {
  email: string | null;
  amountPaid: number | undefined;
  purchasedAt: string | undefined;
  endsAt: string | undefined;
  title?: string;
  cost?: string;
  frequency?: string;
  cancelled: boolean;
}

const SubscriptionDetails: React.FC<SubscriptionDetailsInterface> = ({
  email,
  amountPaid,
  purchasedAt,
  endsAt,
  cancelled,
  title,
  cost,
  frequency,
}) => {
  const formattedPurchasedAt = purchasedAt ? moment(parseInt(purchasedAt)).format('MMMM D, YYYY') : 'N/A';
  const formattedEndsAt = endsAt ? moment(parseInt(endsAt)).format('MMMM D, YYYY') : 'N/A';

  return (
    <>
      {cancelled ? (
        <div className="mt-2 flex flex-row text-gray-700 text-xs sm:text-sm">
          <p className="sm:w-1/5">{title}</p>
          <p className="sm:w-1/5">{siteCurrency} {cost} per {frequency}</p>
          <p className="sm:w-1/5">{`${siteCurrency} ${amountPaid}`}</p>
          <p className="sm:w-1/5">{formattedPurchasedAt}</p>
          <p className="sm:w-1/5">{formattedEndsAt}</p>
        </div>
      ) : (
        <div className="mb-2 text-gray-800 text-sm sm:text-md">
          <div>
            <span>User e-mail:</span> <span>{email}</span>
          </div>
          <div>
            <span>Amount:</span>{" "}
            <span>{`${siteCurrency} ${amountPaid}`}</span>
          </div>
          <div>
            <span>Purchased at:</span>{" "}
            <span>{formattedPurchasedAt}</span>
          </div>
          {/* <div>
            <span>Valid till:</span>{" "}
            <span>{formattedEndsAt}</span>
          </div> */}
        </div>
      )}
    </>
  );
};

export default SubscriptionDetails;
