import {
  Button,
  Dialog,
  DialogBody,
  DialogFooter,
  Input,
} from "@material-tailwind/react";
import { useFormik } from "formik";
import { useState } from "react";
import { getUserDetails } from "../../helpers/users";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { pricingSchema } from "../../validations/UserValidation";
import { useMutation } from "@apollo/client";
import SignUp from "../../components/common/SignUp";
import SignIn from "../../components/common/SignIn";
import { CREATE_CUSTOMER_MUTATION } from "../../api/Payment/paymentCreateCustomer";
import PageTitle from "../../components/ui/PageTitle";
import { getCountryCode } from "../../helpers/get-country-code";

const PricingDialog: React.FC<any> = ({ open, handleOpen, heading }) => {
  const dynCountryCode = getCountryCode();
  const navigate = useNavigate();
  const { token, userId, name, email, phoneNumber } = getUserDetails();

  const [buyModal, setBuyModal] = useState(
    token && token?.length > 0 ? true : false,
  );
  const [signUpModal, setSignUpModal] = useState(false);
  // const [countries, setCountries] = useState<string[]>([]);

  const originalFormData = {
    name: name || "",
    email: email || "",
    phone: phoneNumber || "",
    city: "",
    country: "",
    line1: "",
    line2: "",
    postal_code: "",
    state: "",
  };
  // useEffect(() => {
  //   const fetchCountries = async () => {
  //     try {
  //       const response = await fetch(
  //         "https://trial.mobiscroll.com/content/countries.json",
  //       );
  //       if (!response.ok) {
  //         throw new Error("Failed to fetch countries");
  //       }
  //       const data = await response.json();
  //       setCountries(data);
  //     } catch (error) {
  //       console.error("Error fetching countries:", error);
  //     }
  //   };

  //   fetchCountries();
  // }, []);

  const formDataForAPI = {
    username: originalFormData.name,
    useremail: originalFormData.email,
    userphoneNo: originalFormData.phone,
    address: {
      line1: originalFormData.line1,
      line2: originalFormData.line2,
      city: originalFormData.city,
      state: originalFormData.state,
      postal_code: originalFormData.postal_code,
      country: originalFormData.country,
    },
  };

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setValues,
  } = useFormik({
    initialValues: originalFormData,
    validationSchema: pricingSchema,
    validateOnBlur: true,
    onSubmit: async (values, action) => {
      try {
        const { data } = await createCustomerMutation({
          variables: {
            userid: Number(userId),
            ...formDataForAPI,
          },
        });

        if (data?.payment_CreateCustomer?.customerId) {
          localStorage.setItem(
            "customerId",
            data.payment_CreateCustomer?.customerId,
          );
          navigate("/payment");
        } else {
          toast.error("Failed to create customer.");
        }
      } catch (error: any) {
        if (error.message.includes("JsonWebTokenError")) {
          toast.error("Please Login or Register.");
          setBuyModal(false);
        } else {
          toast.error("Failed to create customer.");
        }
        console.error("Error creating customer:", error);
      }
      action.resetForm();
    },
  });

  const handleModal = () => {
    const { name, email, phoneNumber } = getUserDetails();
    if (name && email && phoneNumber) {
      setValues({
        name: name,
        email: email,
        phone: phoneNumber,
        city: values.city,
        country: values.country,
        line1: values.line1,
        line2: values.line2,
        postal_code: values.postal_code,
        state: values.state,
      });
    }
    setBuyModal(true);
  };

  const [createCustomerMutation] = useMutation(CREATE_CUSTOMER_MUTATION);

  return (
    <>
      <Dialog
        className="max-h-[600px] overflow-y-auto"
        open={open}
        handler={handleOpen}
      >
        {buyModal ? (
          <form className="w-full overflow-y-auto" onSubmit={handleSubmit}>
            <DialogBody className="min-h-full overflow-y-auto">
              <h4 className="mb-4 text-2xl font-semibold">{heading}</h4>
              <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
                <div>
                  <Input
                    variant="outlined"
                    size="lg"
                    label="Name"
                    onChange={handleChange}
                    value={values.name}
                    onBlur={handleBlur}
                    name="name"
                    required
                    className="mb-4"
                    crossOrigin={undefined}
                  />
                  {errors.name && touched.name && (
                    <span className="text-red-500">{errors.name}</span>
                  )}
                </div>

                <div>
                  <Input
                    variant="outlined"
                    size="lg"
                    label="Email Address"
                    onChange={handleChange}
                    value={values.email}
                    onBlur={handleBlur}
                    name="email"
                    required
                    className="mb-4"
                    crossOrigin={undefined}
                  />
                  {errors.email && touched.email && (
                    <span className="text-red-500">{errors.email}</span>
                  )}
                </div>
                <div className="flex">
                  <select
                    id="countryCode"
                    name="countryCode"
                    className="form-select mr-2 h-12 w-1/3 rounded-lg  p-0 text-sm text-gray-800"
                    required
                    value={values.phone.substring(0, 2)}
                  >
                    <option value="" disabled>
                      Country code
                    </option>
                    <option value="64">+64 (New Zealand)</option>
                    <option value="91">+91 (India)</option>
                  </select>
                  <Input
                    variant="outlined"
                    size="lg"
                    label="Phone Number"
                    name="phone"
                    required
                    onChange={handleChange}
                    value={values.phone.substring(2)}
                    onBlur={handleBlur}
                    className="mb-4"
                    crossOrigin={undefined}
                    minLength={dynCountryCode === "91" ? 10 : 8}
                    maxLength={10}
                  />
                  {errors.phone && touched.phone && (
                    <span className="text-red-500">{errors.phone}</span>
                  )}
                </div>
                <div>
                  <Input
                    variant="outlined"
                    size="lg"
                    label="City"
                    name="city"
                    required
                    onChange={handleChange}
                    value={values.city}
                    onBlur={handleBlur}
                    className="mb-4"
                    crossOrigin={undefined}
                  />
                  {errors.city && touched.city && (
                    <span className="text-red-500">{errors.city}</span>
                  )}
                </div>

                <div>
                  <Input
                    variant="outlined"
                    size="lg"
                    label="Address Line 1"
                    name="line1"
                    required
                    onChange={handleChange}
                    value={values.line1}
                    onBlur={handleBlur}
                    crossOrigin={undefined}
                  />
                  {errors.line1 && touched.line1 && (
                    <span className="text-red-500">{errors.line1}</span>
                  )}
                </div>
                <div>
                  <Input
                    variant="outlined"
                    size="lg"
                    label="Address Line 2"
                    name="line2"
                    onChange={handleChange}
                    value={values.line2}
                    onBlur={handleBlur}
                    className="mt-0"
                    crossOrigin={undefined}
                    required
                  />
                  {errors.line2 && touched.line2 && (
                    <span className="text-red-500">{errors.line2}</span>
                  )}
                </div>
                <div>
                  <Input
                    variant="outlined"
                    size="lg"
                    label="Country"
                    name="country"
                    required
                    onChange={handleChange}
                    value={values.country}
                    onBlur={handleBlur}
                    className="mb-4 mt-0"
                    crossOrigin={undefined}
                  />
                  {errors.country && touched.country && (
                    <span className="text-red-500">{errors.country}</span>
                  )}
                </div>
                <div>
                  <Input
                    variant="outlined"
                    size="lg"
                    typeof="number"
                    label="Postal Code"
                    name="postal_code"
                    required
                    onChange={handleChange}
                    value={values.postal_code}
                    onBlur={handleBlur}
                    className="mb-4"
                    crossOrigin={undefined}
                  />
                  {errors.postal_code && touched.postal_code && (
                    <span className="text-red-500">{errors.postal_code}</span>
                  )}
                </div>
                <div>
                  <Input
                    variant="outlined"
                    size="lg"
                    label="State"
                    name="state"
                    required
                    onChange={handleChange}
                    value={values.state}
                    onBlur={handleBlur}
                    className="mb-4"
                    crossOrigin={undefined}
                  />
                  {errors.state && touched.state && (
                    <span className="text-red-500">{errors.state}</span>
                  )}
                </div>
              </div>
            </DialogBody>
            <DialogFooter className="justify-end">
              <Button type="submit" className="bg-primary">
                Next
              </Button>
            </DialogFooter>
          </form>
        ) : (
          <DialogBody>
            <PageTitle section={`${signUpModal ? "Sign Up" : "Sign In"}`} />
            {signUpModal ? (
              <SignUp setSignUpModal={setSignUpModal} />
            ) : (
              <SignIn handleModal={handleModal} />
            )}
            <div className="mt-6 text-center text-gray-600">
              {signUpModal
                ? "Already using bookable?"
                : "Don't you have an account?"}{" "}
              <span
                onClick={() => setSignUpModal(!signUpModal)}
                className="cursor-pointer text-blue-600 transition duration-150 ease-in-out hover:underline"
              >
                Sign {signUpModal ? "In" : "Up"}
              </span>
            </div>
          </DialogBody>
        )}
      </Dialog>
    </>
  );
};

export default PricingDialog;
