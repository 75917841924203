export const PricingCardSkeleton = () => {
  return (
    <div
      role="status"
      className="mx-auto max-w-[1000px] animate-pulse rounded py-20 shadow">
      <div className="container">
        <div className="mx-auto mb-5 h-5 w-80 rounded-full bg-gray-200"></div>
        <div className="mx-auto mb-5 h-2 w-48 rounded-full bg-gray-200"></div>
        <div className="mx-auto flex max-w-[200px] space-x-3">
          {[...Array(3)].map((_, index) => (
            <div
              key={index}
              className="mx-auto mb-5 h-2 w-8 rounded-full bg-gray-200"
            ></div>
          ))}
        </div>
        <div className="mt-4 flex items-baseline space-x-4">
          {[...Array(3)].map((_, index) => (
            <div
              key={index}
              className="dark:bg-gray-700 h-auto w-full rounded-t-lg border border-gray-200 bg-gray-200 shadow"
            >
              <div className="dark:bg-gray-700 mb-4 flex h-72 w-auto items-center justify-center rounded bg-gray-300">
                <svg
                  className="dark:text-gray-600 h-10 w-10 text-gray-200"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 16 20"
                >
                  <path d="M14.066 0H7v5a2 2 0 0 1-2 2H0v11a1.97 1.97 0 0 0 1.934 2h12.132A1.97 1.97 0 0 0 16 18V2a1.97 1.97 0 0 0-1.934-2ZM10.5 6a1.5 1.5 0 1 1 0 2.999A1.5 1.5 0 0 1 10.5 6Zm2.221 10.515a1 1 0 0 1-.858.485h-8a1 1 0 0 1-.9-1.43L5.6 10.039a.978.978 0 0 1 .936-.57 1 1 0 0 1 .9.632l1.181 2.981.541-1a.945.945 0 0 1 .883-.522 1 1 0 0 1 .879.529l1.832 3.438a1 1 0 0 1-.031.988Z" />
                  <path d="M5 5V.13a2.96 2.96 0 0 0-1.293.749L.879 3.707A2.98 2.98 0 0 0 .13 5H5Z" />
                </svg>
              </div>
              <div className="flex flex-col space-y-2">
                {[...Array(7)].map((_, index) => (
                  <div
                    key={index}
                    className="mx-auto my-1 h-2 w-48 rounded-full bg-gray-300"
                  ></div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
