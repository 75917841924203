import React from "react";
import heroImage from "../../assets/images/inspectionManagement/HeroInspectionManagement.png";
import userFriendly from "../../assets/images/inspectionManagement/userFriendlyUI.png";
import webIntegration from "../../assets/images/inspectionManagement/webIntegration.png";
import appointmentSlots from "../../assets/images/inspectionManagement/appointmentsSlots.png";
import realTimeUpdates from "../../assets/images/inspectionManagement/realTimeUpdates.png";
import confirmations from "../../assets/images/inspectionManagement/confirmationsAndNotifications.png";
import AgentContact from "../../assets/images/inspectionManagement/GetInTouchWithAgent.png";
import propertyInspections from "../../assets/images/inspectionManagement/propertyInspections.png";
import { Link } from "react-router-dom";

const InspectionManagement: React.FC = () => {
  const content = [
    {
      title: "Start Simplifying Your Property Inspections Today",
      description:
        "Experience the convenience of Bookable Online's Inspections Management service. Sign up now and discover how easy it can be to manage your property inspections with us.",
      url: propertyInspections,
      alt: "property inspections",
    },
    {
      title: "User-Friendly Booking Interface",
      description:
        "Bid farewell to inconvenient emails – with our intuitive online scheduling system, users can easily book property inspections or viewings directly through our tool.",
      url: userFriendly,
      alt: "user friendly UI",
    },
    {
      title: "Effortless Embedding into Your Website",
      description:
        "Our booking system offers the convenience of seamlessly embedding booking inspections into your website, eliminating hassle and ensuring a smooth user experience.",
      url: webIntegration,
      alt: "effortless web integration",
    },
    {
      title: "Flexible Appointment Slots",
      description:
        "We offer a variety of flexible appointment slots with your preferable timings to accommodate the diverse schedules of potential buyers or renters.",
      url: appointmentSlots,
      alt: "flexible appointment slots",
    },
    {
      title: "Real-Time Availability",
      description:
        "No more waiting for confirmation – our system displays real-time availability of inspection slots, enabling users to book appointments instantly without delays.",
      url: realTimeUpdates,
      alt: "real time availability",
    },
    {
      title: "Confirmation and Reminder Notifications",
      description:
        "Stay informed at every step – upon booking an inspection, users receive automated confirmation emails, with reminder notifications sent closer to the appointment time to ensure attendance.",
      url: confirmations,
      alt: "confirmations and notifications",
    },
    {
      title: "Easy Access to Agent Contact Information",
      description:
        "Need more details about a property? Get in touch with real estate agents or property managers effortlessly. Contact information is readily available on our tool for quick assistance.",
      url: AgentContact,
      alt: "agent contact info",
    },
  ];

  return (
    <div>
      <div className="bg-secondary">
        <div className="mx-auto mb-4 flex flex-col px-2 py-2 md:max-w-[1000px] md:flex-row md:items-center md:py-6">
          <div className="md:w-3/5">
            <h1 className="mt-4 text-center text-xl font-bold text-white md:text-left md:text-4xl">
              Effortless{" "}
              <span className="text-primary"> Property Inspections </span> with
              Bookable Online
            </h1>
            <p className="px-2 md:px-0 my-8 text-sm text-white md:text-lg">
              Welcome to Bookable Online's Inspections Management service, your
              go-to solution for seamless property inspections. As a trusted
              intermediary between service providers and customers, we're
              dedicated to simplifying the process of scheduling and managing
              property viewings for both buyers and renters.
            </p>
          </div>
          <img
            src={heroImage}
            alt="event-management"
            className="mx-auto h-auto w-48 md:w-1/3"
          />
        </div>
      </div>

      <div className="mx-auto px-2 sm:max-w-[1000px]">
        {content.map((item, index) => (
          <section
            key={index}
            className={`my-12 flex flex-col items-center ${
              index % 2 === 0 ? "md:flex-row" : "md:flex-row-reverse"
            }`}
          >
            <div className="md:w-1/3">
              <div
                className="h-auto w-48 md:w-64 "
                style={{
                  boxShadow:
                    "rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em",
                }}
              ></div>
              <img
                src={item.url}
                loading="lazy"
                alt={item.alt}
                className="h-auto w-48 md:w-64 mb-2"
              />
            </div>
            <div className="px-2 md:w-2/3">
              <p className="text-md mb-2 font-bold text-primary md:sm:text-3xl">
                {item.title}
              </p>
              <span className="mb-2 text-sm text-xs leading-6 text-gray-700 md:text-lg">
                {item.description}
              </span>
            </div>
          </section>
        ))}
        <div className=" px-2 md:px-0 md:text-center text-xs text-gray-700 md:text-lg">
          <p className="mt-8">Ready to simplify your Property Inspections</p>
          <p>
            <Link
              to="/signup"
              className="font-bold text-primary underline transition duration-150 ease-in-out"
            >
              Sign up
            </Link>{" "}
            for bookable online today and experience the difference!
          </p>
        </div>
      </div>
    </div>
  );
};

export default InspectionManagement;
