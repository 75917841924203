export const splitTitleToLink = (title: string) =>
  title.split(" ").join("-").toLowerCase();

export const joinLinkToTitle = (linkTitle: string) => {
  const words = linkTitle.split("-");
  for (let i = 0; i < words.length; i++) {
    words[i] = words[i][0].toUpperCase() + words[i].substring(1);
  }
  return words.join(" ");
};
