import React, { HTMLAttributes } from "react";
import { CheckCircleIcon } from "@heroicons/react/20/solid";

interface IListingCard extends HTMLAttributes<HTMLDivElement> {
  list?: string[];
  bgImage: string;
  name: string;
  pricing?: string;
  pricingTag?: string;
  comming?: boolean;
}

const ListingCard: React.FC<IListingCard> = ({
  list,
  bgImage,
  name,
  pricing,
  pricingTag,
  comming,
  children,
}) => {
  return (
    <div className="flex w-72  md:w-96 flex-col justify-start rounded-2xl border-[1px] border-blue-100 bg-white p-4 shadow-lg">
      <div className="relative flex h-64 justify-center overflow-hidden rounded-lg">
        <div className="w-full transform transition-transform duration-500 ease-in-out hover:scale-110">
          <div
            style={{ backgroundImage: `url(${bgImage})` }}
            className="relative h-full rounded-xl bg-white bg-cover bg-center px-4 py-4 pb-24 shadow-2xl before:absolute before:left-0 before:top-0 before:z-0 before:h-full before:w-full before:rounded-2xl before:bg-black before:opacity-50 before:content-['']"
          ></div>
        </div>
        {comming && (
          <div className="absolute right-[-50px] top-[-50px] h-[100px] w-[100px] rotate-45 bg-[#52c908] text-center text-[10px] text-white shadow-[0_0_3px_2px_rgba(0,0,0,0.2)]">
            <span className="absolute bottom-0 flex w-full items-center justify-center font-bold">
              Coming Soon
            </span>
          </div>
        )}
        <div className="absolute bottom-0 mb-3 flex justify-center">
          <h5 className="h5 rounded-lg bg-primary px-4 py-1 font-bold text-white shadow">
            {name}
          </h5>
        </div>
      </div>
      {pricing && (
        <div className="mt-6 block text-center">
          <h4 className="h4 text-gray-800">{pricing}</h4>
          <p className="text-gray-500">{pricingTag}</p>
        </div>
      )}
      {list && list.length > 0 && (
        <div className="flex flex-auto flex-col justify-between">
          <ul className="mb-6 mt-6 w-full text-sm text-gray-600">
            {list.map((item, i) => (
              <li key={i} className="mb-3 flex items-center ">
                <CheckCircleIcon className="mr-2 w-6 text-green-400" />
                {item}
              </li>
            ))}
          </ul>
        </div>
      )}

      {children && (
        <div className="mt-6 flex flex-auto flex-col items-center justify-end">
          {children}
        </div>
      )}
    </div>
  );
};

export default ListingCard;