import React, { ReactNode } from "react";
import { FormikProps } from "formik";
import { Input, Textarea } from "@material-tailwind/react";
import {
  eventCategoryOptions,
  languageOptions,
  timeZoneOptions,
} from "../FormFieldsOptions";
import EventType from "./EventType";
import EventLocation from "./EventLocation";
import ReactQuill from "react-quill";
import "quill/dist/quill.snow.css";
import { quillFormats, quillModules } from "../../../helpers/quill-modules";

interface FormFieldsProps {
  formik: FormikProps<any>;
}

const getError = (field: string, formik: FormikProps<any>): ReactNode => {
  const error: any = formik.touched[field] && formik.errors[field];
  return error ? <div className="text-xs text-red-600">{error}</div> : null;
};

const FormFields: React.FC<FormFieldsProps> = ({ formik }) => {
  const handleDescriptionChange = (content: string) => {
    if (content.replace(/<(.|\n)*?>/g, "").trim().length === 0) {
      formik.setFieldValue("description", "");
    } else {
      formik.setFieldValue("description", content);
    }
  };

  return (
    <div>
      <div className="mb-2  pr-2">
        <Input
          aria-rowspan={8}
          type="text"
          className="w-full rounded-md border  text-sm focus:border-gray-900"
          placeholder="Enter Event Title"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.title}
          label="Event Title *"
          name="title"
          crossOrigin={undefined}
          maxLength={64}
        />
        {getError("title", formik)}
      </div>
      <div className="bg-white pr-2">
        <ReactQuill
          theme="snow"
          modules={quillModules}
          formats={quillFormats}
          className="w-full"
          placeholder="Enter Event Description *"
          value={formik.values.description}
          onChange={handleDescriptionChange}
          onBlur={() => formik.setFieldTouched("description", true, true)}
        />
        {/* <Textarea
          className="w-full rounded-md border  text-sm focus:border-gray-900 "
          label="Enter Event Description *"
          rows={3}
          resize={true}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.description}
          name="description"
          maxLength={6000}
        /> */}
        {getError("description", formik)}
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2">
        <div className="bg-white py-2 pr-2">
          <Input
            type="text"
            className="mr-2 w-full rounded-md border px-3 py-3 text-sm focus:border-gray-900"
            placeholder="Enter Organizer Name"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.organizer}
            label="Organizer Name *"
            name="organizer"
            crossOrigin={undefined}
          />
          {getError("organizer", formik)}
        </div>

        <div className="bg-white py-2 pr-2">
          <select
            id="eventCategory"
            name="category"
            value={formik.values.category}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className="w-full rounded-md  border bg-white px-3 py-3 text-sm focus:border-gray-900"
            style={{
              border: "1px solid rgb(176, 190, 197)",
              padding: "0.6rem",
              color: "#607D8B",
            }}
          >
            <option value="" disabled>
              Event Category *
            </option>
            {eventCategoryOptions.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
          {getError("category", formik)}
        </div>
      </div>
      <div className="py-2 pr-2">
        <Textarea
          name="orgnizerSection"
          id="orgnizerSection"
          label="Organizer Detail *"
          rows={2}
          resize={true}
          onChange={formik.handleChange}
          value={formik.values.orgnizerSection}
          onBlur={formik.handleBlur}
          className="w-full rounded-md border  text-sm focus:border-gray-900 "
          minLength={10}
          maxLength={200}
        />
      </div>

      <div className="  py-2 pr-2"></div>
      <EventLocation formik={formik} />
      {getError("location", formik)}
      <EventType formik={formik} />
      <div className="grid grid-cols-1 sm:grid-cols-2">
        <div className="bg-white  py-2 pr-2">
          <select
            id="eventTimeZone"
            name="timeZone"
            value={formik.values.timeZone}
            onChange={formik.handleChange}
            onBlur={(e) => {
              formik.handleBlur(e);
              formik.setTouched({ ...formik.touched, timeZone: true });
            }}
            style={{
              border: "1px solid rgb(176, 190, 197)",
              padding: "0.6rem",
              color: "#607D8B",
            }}
            className="w-full rounded-md border bg-white px-3 py-3 text-sm focus:border-gray-900"
          >
            <option value="" disabled>
              Event Time Zone *
            </option>
            {timeZoneOptions.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
          {getError("timeZone", formik)}
        </div>

        <div className="bg-white  py-2 pr-2">
          <select
            name="language"
            id="eventLanguage"
            value={formik.values.language}
            onChange={formik.handleChange}
            onBlur={(e) => {
              formik.handleBlur(e);
              formik.setTouched({ ...formik.touched, language: true });
            }}
            style={{
              border: "1px solid rgb(176, 190, 197)",
              padding: "0.6rem",
              color: "#607D8B",
            }}
            className="w-full rounded-md border bg-white px-3 py-3 text-sm focus:border-gray-900"
          >
            <option value="" disabled>
              Event Language
            </option>
            {languageOptions.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
          {getError("language", formik)}
        </div>
      </div>
    </div>
  );
};

export default FormFields;
