import { FC, Fragment, useMemo } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Listing from "../../../types/EventCustomerListing";
import { GET_CUSTOMER_TICKET_DETAIL } from "../../../api/Event/getCustomerTicketDetails";
import { useMutation, useQuery } from "@apollo/client";
import { EVENT_RESEND_TICKET_DETAILS } from "../../../api/Event/resendTicketDetails";
import { toast } from "react-toastify";
import { capitalizeFirstLetter } from "../../../helpers/messages-helper";
import EventCustomerTicketListing from "../../../types/EventCustomerTicketListing";

interface MyModalProps {
  organizer: string;
  listing: Listing;
  closeModal: () => void;
  isOpen: boolean;
}

const TicketDetailsModal: FC<MyModalProps> = ({
  organizer,
  listing,
  closeModal,
  isOpen,
}: MyModalProps) => {
  const {
    customerId,
    paymentTime,
    firstName,
    lastName,
    ticketId,
    ticketDetails,
    email,
    totalTicketBooked,
    totalPrice,
  } = listing;

  const closeModalInternal = () => {
    closeModal();
  };

  const { error: ticketDetailError, data: ticketDetailData } = useQuery<{
    event_getCustomerTicketDetails: EventCustomerTicketListing;
  }>(GET_CUSTOMER_TICKET_DETAIL, {
    variables: {
      customerId: customerId,
    },
  });

  const ticketDetailDataFinal = useMemo(
    () => ticketDetailData?.event_getCustomerTicketDetails,
    [ticketDetailData?.event_getCustomerTicketDetails],
  );

  const paymentDate = useMemo(() => {
    if (paymentTime) {
      return new Date(paymentTime.split(" ")[0]).toLocaleDateString();
    }
    return "N/A";
  }, [paymentTime]);

  const paymentTimeFinal = useMemo(() => {
    if (paymentTime) {
      return new Date(paymentTime.split(" ")[0]).toLocaleTimeString();
    }
    return "N/A";
  }, [paymentTime]);
  const [resendTicket] = useMutation(EVENT_RESEND_TICKET_DETAILS);

  const handleResend = async () => {
    try {
      const resendData = await resendTicket({
        variables: {
          cstName: `${firstName} ${lastName}`,
          ticketId: ticketId || "null",
          eventName: organizer,
          email: email,
          ticketDetails: ticketDetails.map((ticket) => ({
            currency: ticket?.currency || "",
            label: ticket?.label || "Free",
            perTicketPrice: ticket?.perTicketPrice || 0,
            ticketChargespaid: ticket?.totalChargespaid || 0,
            ticketbooked: ticket?.ticketbooked || 0,
          })),
          organizerName: organizer,
        },
      });
      if (resendData?.data?.event_resendTicketDetails !== null) {
        toast.success(
          capitalizeFirstLetter(resendData.data.event_resendTicketDetails),
          {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2000,
          },
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 overflow-y-auto"
        onClose={closeModalInternal}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-black/25" />
        </Transition.Child>

        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <div className="flex h-screen items-center justify-center">
            <div className="rounded-2xl bg-white  text-center">
              <div className="rounded-2xl bg-white px-4 py-6 text-gray-800 ">
                {" "}
                <Dialog.Title className="text-2xl font-bold leading-6  underline">
                  {`${firstName.toUpperCase()} ${lastName.toUpperCase()} Details`}
                </Dialog.Title>
                <Dialog.Title className="mt-2 text-base font-bold italic leading-6 ">
                  #{ticketId}
                </Dialog.Title>
              </div>
              {ticketDetailError && (
                <div className="mt-2 text-sm text-gray-500">
                  Something went wrong!..
                </div>
              )}

              {ticketDetailData && (
                <div className="text-left px-3" >
                  <div className=" border-gray-200">
                    <dl>
                      <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-base font-medium text-gray-800">
                          Event:
                        </dt>
                        <dd className="mt-1 text-base text-gray-900 sm:col-span-2">
                          {
                            ticketDetailData?.event_getCustomerTicketDetails
                              .eventName
                          }
                        </dd>
                      </div>
                      <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-base font-medium text-gray-800">
                          Tickets:
                        </dt>
                        <dd className="mt-1 text-base text-gray-900 sm:col-span-2">
                          <ul className=" list-disc">
                            {ticketDetailDataFinal?.ticketDetails &&
                            ticketDetailDataFinal?.ticketDetails[0].label !==
                              null ? (
                              ticketDetailDataFinal?.ticketDetails?.map(
                                (item: any, id: any) => (
                                  <li key={id} className="ml-6 text-base">
                                    {item.ticketbooked} {item.label}
                                  </li>
                                ),
                              )
                            ) : (
                              <div className="text-base">
                                {totalTicketBooked}
                              </div>
                            )}
                          </ul>
                        </dd>
                      </div>
                      <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-base font-medium text-gray-800">
                          Total price:
                        </dt>
                        <dd className="mt-1 text-base text-gray-900 sm:col-span-2">
                          {ticketDetailDataFinal?.ticketDetails &&
                            ticketDetailDataFinal?.ticketDetails[0]
                              ?.currency}{" "}
                          {totalPrice || "Free"}
                        </dd>
                      </div>
                      <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-base font-medium text-gray-800">
                          Payment date:
                        </dt>
                        <dd className="mt-1 text-base text-gray-900 sm:col-span-2">
                          {paymentDate || "N/A"}
                        </dd>
                      </div>
                      <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-base font-medium text-gray-800">
                          Payment time:
                        </dt>
                        <dd className="mt-1 text-base text-gray-900 sm:col-span-2">
                          {paymentTimeFinal || "N/A"}
                        </dd>
                      </div>
                    </dl>
                  </div>
                </div>
              )}
              {/* Buttons */}
              <div className="my-4 space-x-4">
                <button
                  type="button"
                  className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                  onClick={closeModalInternal}
                >
                  Close
                </button>
                <button
                  type="button"
                  className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                  onClick={handleResend}
                >
                  Resend
                </button>
              </div>
            </div>
          </div>
        </Transition.Child>
      </Dialog>
    </Transition>
  );
};

export default TicketDetailsModal;
