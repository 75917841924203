import { gql } from "@apollo/client";

export const UPDATE_CUSTOMER_ATTENDENCE = gql`
  mutation Event_updateCustomerAttendence(
    $customerid: Int!
    $present: Boolean!
  ) {
    event_updateCustomerAttendence(customerid: $customerid, present: $present)
  }
`;
