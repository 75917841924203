/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import Card from "../../components/common/Card";
import { EVENT_GET_EVENT_BY_USER_ID } from "../../api/userEvent";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import { convertEpochToReadableDate } from "../../helpers/date-helpers";
import DashboardChildrenProps from "../../types/DashboardChildren";
import { useNavigateSearch } from "../../hooks/useNavigateSearch";
import { Switch } from "@headlessui/react";
import { formatEventDuration } from "../../utils/formatEventDuration";

interface YourEventProps extends DashboardChildrenProps {
  userPlanId?: any;
}

const YourEvent: React.FC<YourEventProps> = ({
  userPlanId,
  pathName,
  section,
  tab,
}) => {
  const navigateSearch = useNavigateSearch();

  const userId = localStorage.getItem("userId");
  const [publishStatus, setPublishStatus] = useState<string>(
    tab === "" ? "live" : tab,
  );
  const [expired, setExpired] = useState<boolean>(
    tab === "expired" ? true : false,
  );

  const handlePublishStatusChange = (status: string) => {
    let newStatus = status;
    if (status === "expired") {
      newStatus = "live";
      setExpired(true);
    } else {
      setExpired(false);
    }

    navigateSearch({
      pathname: pathName,
      params: { section: section, tab: newStatus },
    });
    setPublishStatus(newStatus);
  };

  const handlePublishedStatusInExpiredEvents = (status: string) => {
    setExpired(true);
    setPublishStatus(status);
  };

  const { error, data } = useQuery<{
    event_getEventByUserId: any;
  }>(EVENT_GET_EVENT_BY_USER_ID, {
    variables: {
      userid: Number(userId),
      publishStatus: publishStatus === "live" ? true : false,
      expire: expired,
      limit: 10,
      offset: 0,
    },
  });

  const events: any = data?.event_getEventByUserId || [];

  return (
    <div className="flex flex-col items-center justify-center gap-3 pl-2">
      <div className="flex w-full flex-col  justify-start bg-gray-100 p-2  md:flex-row">
        <button
          className={`mx-0 rounded-md px-4 py-1 text-center text-sm md:m-0 ${
            publishStatus === "live" && !expired
              ? "rounded-none border-b-[2px] border-l-0 border-r-0 border-t-0 border-secondary font-bold text-secondary"
              : "border-none text-gray-500"
          }`}
          onClick={() => handlePublishStatusChange("live")}
        >
          Live
        </button>
        <button
          className={`m-1 mx-0 rounded-md  px-4 py-1 text-center text-sm md:m-0 ${
            publishStatus === "draft" && !expired
              ? "rounded-none border-b-[2px] border-l-0 border-r-0 border-t-0 border-secondary font-bold text-secondary"
              : "border-none text-gray-500"
          }`}
          onClick={() => handlePublishStatusChange("draft")}
        >
          Draft
        </button>
        <button
          className={`m-1 mx-0 rounded-md  px-4 py-1 text-center text-sm md:m-0 ${
            publishStatus === "expired" || expired
              ? "rounded-none border-b-[2px] border-l-0 border-r-0 border-t-0 border-secondary font-bold text-secondary"
              : "border-none text-gray-500"
          }`}
          onClick={() => handlePublishStatusChange("expired")}
        >
          Expired
        </button>
      </div>
      {expired && (
        <div className="flex w-full items-center justify-end text-sm">
          <span className="mr-4 text-gray-600">Live</span>
          <Switch
            checked={publishStatus === "live"}
            onChange={() =>
              handlePublishedStatusInExpiredEvents(
                publishStatus === "live" ? "draft" : "live",
              )
            }
            className={`relative mr-4 inline-flex h-6 w-11 items-center rounded-full ${
              publishStatus === "live" ? "bg-secondary" : "bg-secondary"
            }`}
          >
            <span
              className={`ml-[2px]  inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out ${
                publishStatus === "draft" ? "translate-x-5" : "translate-x-0"
              }`}
            />
          </Switch>
          <span className="text-gray-700">Draft</span>
        </div>
      )}
      {events.length > 0 ? (
        <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 md:grid-cols-3">
          {events?.map((event: any) => {
            const startTime =
              event.recurringType?.start_time || event.singleDay.start_time;
            const endTime =
              event.recurringType?.end_time || event.singleDay.end_time;

            return (
              <Card
                key={event.id}
                eventID={event.id}
                title={event.title}
                description={event.description}
                summary={event.summary}
                location={event.address}
                type={event.type}
                paid={event.paid}
                publishStatus={event.publish}
                organizer={event.organizer}
                startDate={convertEpochToReadableDate(event.startDate)}
                duration={formatEventDuration(startTime, endTime)}
                userPlanId={userPlanId}
                imageUrls={event.imageUrls}
              />
            );
          })}
        </div>
      ) : (
        <div className="mx-auto w-full max-w-screen-md text-center text-lg font-medium text-gray-600">
          Oops! It seems there are no active events on your end. Don't worry,
          you can kick off the excitement by{" "}
          <span className="font-bold text-primary">
            <Link to="/plan-your-event">creating a new event</Link>
          </span>{" "}
          ✨. Let your imagination run wild and bring your ideas to life!
        </div>
      )}
    </div>
  );
};

export default YourEvent;
