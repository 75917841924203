import React, { HTMLAttributes } from "react";

interface InputWrapperProps extends HTMLAttributes<HTMLDivElement> {}

const UIInputWrapper: React.FC<InputWrapperProps> = ({
  children,
  className = "",
  ...rest
}) => {
  return (
    <div className={"mb-4 flex flex-wrap " + className} {...rest}>
      <div className="w-full">{children}</div>
    </div>
  );
};

export default UIInputWrapper;
