import React, { useState } from "react";
import UIInput from "../../../components/ui/Input";

interface FreeTicketProps {
  freeTicket: number;
  onFreeTicketChange: (value: number) => void;
}

const FreeTicket: React.FC<FreeTicketProps> = ({
  freeTicket,
  onFreeTicketChange,
}) => {
  const [confirmation, setConfirmation] = useState<string | null>(null);

  const handleConfirm = () => {
    if (freeTicket === 0) {
      setConfirmation("Please select the number of tickets.");
    } else {
      setConfirmation(`You have selected ${freeTicket} tickets.`);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(e.target.value);
    onFreeTicketChange(value); 
  };

  return (
    <div className="rounded-md bg-gray-200 p-4">
      <h2 className="mb-2 text-lg font-bold">Free Ticket</h2>
      <p className="text-gray-800">
        This ticket option is free. Please select the number of tickets:
      </p>

      
      <div className="mt-2 flex items-center">
        <UIInput
          type="number"
          min={0}
          value={freeTicket}
          onChange={handleChange}
          className="w-16 rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
        />
        <button
          type="button"
          onClick={handleConfirm}
          className="ml-2 rounded-md bg-secondary px-4 py-2 text-white"
        >
          Confirm
        </button>
      </div>
      {confirmation && (
        <div className="mt-4">
          <p className="text-gray-900 text-sm italic">{confirmation}</p>
        </div>
      )}
    </div>
  );
};

export default FreeTicket;
