import React, { useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const CheckoutForm = () => {
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState<string | null>(null);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsProcessing(true);

    const { error } = await stripe.confirmSetup({
      elements,
      confirmParams: {
        return_url: `${window.location.origin}/completion`,
      },
      //redirect: 'if_required'
    });

    if (!error) {
      toast.success("Congratulations! Your payment was successful.");
      setMessage("Congratulations! Your payment was successful.");
      navigate("/completion");
    } else if (
      error.type === "card_error" ||
      error.type === "validation_error"
    ) {
      toast.error("Something went wrong");
    } else {
      toast.error("An unexpected error occurred.");
    }

    setIsProcessing(false);
  };

  return (
    <form className="text-center" id="payment-form" onSubmit={handleSubmit}>
      <PaymentElement id="payment-element" />
      <button
        disabled={isProcessing || !stripe || !elements}
        id="submit"
        className="align-middle select-none font-sans font-bold text-center uppercase transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 px-6 rounded-lg text-white shadow-md shadow-gray-900/10 hover:shadow-lg hover:shadow-gray-900/20 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none bg-secondary mt-4"
      >
        <span id="button-text">
          {isProcessing ? "Processing ... " : "Add card"}
        </span>
      </button>

      {/* Show any error or success messages */}
      {/* {message && <div id="payment-message">{message}</div>} */}
    </form>
  );
}
export default CheckoutForm;