import { gql } from "@apollo/client";

export const CREATE_USER_MUTATION = gql`
  mutation UserRegistration(
    $name: String!
    $email: String!
    $password: String!
    $userName: String!
    $phoneNumber: String!
    $inspectionApiAccess: Boolean
  ) {
    userRegistration(
      name: $name
      email: $email
      password: $password
      userName: $userName
      phoneNumber: $phoneNumber
      inspectionApiAccess: $inspectionApiAccess
    )
  }
`;

export const LOGIN_MUTATION = gql`
  mutation UserLogin($email: String!, $password: String!) {
    userLogin(email: $email, password: $password) {
      user {
        id
        name
        email
        phoneNumber
        onBoardingStep
      }
      token
      access {
        inspectionApiAccess
        reraAccess
      }
      stripeKeys
      totalEvent
      liveEvent
      draftEvent
    }
  }
`;

export const EMAIL_VALIDATION_MUTATION = gql`
  mutation UpdateEmailValidation(
    $uid: String!
    $userId: Int!
    $emailValidate: Boolean!
  ) {
    updateEmailValidation(
      uid: $uid
      userId: $userId
      emailValidate: $emailValidate
    )
  }
`;
