import { gql } from "@apollo/client";

export const EVENT_RESEND_TICKET_DETAILS = gql`
  mutation Event_resendTicketDetails(
    $cstName: String!
    $ticketId: String!
    $eventName: String!
    $email: String!
    $ticketDetails: [resendTicketDetail]!
    $organizerName: String!
  ) {
    event_resendTicketDetails(
      cst_Name: $cstName
      ticketId: $ticketId
      eventName: $eventName
      email: $email
      ticketDetails: $ticketDetails
      organizerName: $organizerName
    )
  }
`;
