import {
  EyeIcon,
  TicketIcon,
  PhotoIcon,
  Square3Stack3DIcon,
} from "@heroicons/react/20/solid";
import * as Yup from "yup";
import { CreateEventVariables } from "../../types/CreateEventForm";

export const CreateEventSection = [
  {
    label: "Basic Info",
    value: "basicInfo",
    icon: Square3Stack3DIcon,
  },
  {
    label: "Media",
    value: "details",
    icon: PhotoIcon,
  },
  {
    label: "Add Tickets",
    value: "addTickets",
    icon: TicketIcon,
  },
  {
    label: "Save Event",
    value: "save",
    icon: EyeIcon,
  },
];

export const validationSchema = Yup.object({
  title: Yup.string().required("Title is required"),
  description: Yup.string()
    .required("Description is required")
    .min(150, "Description is too short")
    .max(6000, "Description is too large"),
  organizer: Yup.string().required("Organizer is required"),
  category: Yup.string().required("Category is required"),
  location: Yup.string().required("location is required"),
  timeZone: Yup.string().required("Time Zone is required"),
  // free: Yup.boolean().required("Free field is required"),
  // paid: Yup.boolean().required("Paid field is required"),
  // startDate: Yup.string().required("Start Date is required"),
  // endDate: Yup.string().required("End Date is required"),
  // ticketSaleStartDate: Yup.string().required(
  //   "Ticket Sale Start Date is required",
  // ),
  // ticketSaleEndDate: Yup.string().required("Ticket Sale End Date is required"),
  // eventPattern: Yup.string().required("Event Pattern is required"),
  // summary: Yup.string().required("Summary is required"),
  // freeTicket: Yup.number().required("Free Ticket is required"),
  // language: Yup.string(),

  // ticketInfo: Yup.array()
  //   .of(
  //     Yup.object().shape({
  //       currency: Yup.string().required("Currency is required"),
  //       description: Yup.string().required("Description is required"),
  //       label: Yup.string().required("Label is required"),
  //       price: Yup.number().required("Price is required"),
  //       ticketQuantity: Yup.number().required("Ticket Quantity is required"),
  //       ticketWarningOn: Yup.number().required("Ticket Warning On is required"),
  //     }),
  //   )
  //   .required("Ticket Info is required"),
});

export const initialValues: CreateEventVariables = {
  title: "",
  description: "",
  organizer: "",
  userId: 0,
  category: "",
  location: "",
  address: "",
  timeZone: "",
  language: "",
  free: false,
  paid: false,
  startDate: "",
  endDate: "",
  startTime: "",
  endTime: "",
  ticketSaleStartDate: "",
  ticketSaleEndDate: "",
  eventPattern: "",
  summary: "",
  freeTicket: 0,
  ticketInfo: [
    {
      currency: "",
      description: "",
      label: "",
      price: 0,
      ticketQuantity: 0,
      ticketWarningOn: 0,
    },
  ],
  activeTab: "basicInfo",
};
