import React, { useState, useCallback, FC } from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import { debounce } from "../../../utils/debounce";
import { Input } from "@material-tailwind/react";

const EventLocation: FC<any> = ({ formik }) => {
  const [suggestions, setSuggestions] = useState<any[]>([]);
  const [location, setLocation] = useState<{ lat: number; lon: number } | null>(
    null,
  );

  const fetchSuggestions = async (address: string) => {
    try {
      const response = await fetch(
        `https://nominatim.openstreetmap.org/search?q=${encodeURIComponent(
          address,
        )}&format=json&addressdetails=1&limit=5`,
      );
      const data = await response.json();
      const filteredSuggestions = data.filter(
        (item: any) =>
          item.address.country === "India" ||
          item.address.country === "New Zealand",
      );
      setSuggestions(filteredSuggestions);
    } catch (error) {
      console.error("Error fetching suggestions:", error);
    }
  };

  const debouncedFetchSuggestions = useCallback(
    debounce((address: string) => fetchSuggestions(address), 100),
    [],
  );

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newAddress = e.target.value;
    formik.setFieldValue("location", newAddress);
    formik.setFieldValue("address", newAddress);
    if (newAddress.length > 2) {
      debouncedFetchSuggestions(newAddress);
    } else {
      setSuggestions([]);
    }
  };

  const handleSuggestionClick = (suggestion: any) => {
    const { lat, lon, display_name } = suggestion;
    formik.setFieldValue("location", display_name);
    formik.setFieldValue("address", display_name);

    setLocation({ lat: parseFloat(lat), lon: parseFloat(lon) });
    setSuggestions([]);
  };

  const handleMarkerDragEnd = (event: L.DragEndEvent) => {
    const { lat, lng } = event.target.getLatLng();
    setLocation({ lat, lon: lng });
    fetchSuggestions(`${lat}, ${lng}`);
  };

  const customIcon = new L.Icon({
    iconUrl:
      "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png",
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowUrl:
      "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png",
    shadowSize: [41, 41],
  });

  return (
    <div>
      <Input
        label="Enter event location *"
        type="text"
        value={formik.values.location}
        onChange={handleInputChange}
        onBlur={formik.handleBlur}
        placeholder="Enter event location"
        name="location"
        className="w-full rounded-md border text-sm focus:border-gray-900"
        crossOrigin={undefined}
      />

      {suggestions.length > 0 && (
        <ul className="rounded-lg border border-gray-300 bg-white shadow-lg z-10">
          {suggestions.map((suggestion, index) => (
            <li
              key={index}
              onClick={() => handleSuggestionClick(suggestion)}
              className="cursor-pointer border-b border-gray-200 px-4 py-2 transition-colors duration-200 ease-in-out last:border-none hover:bg-secondary hover:text-white focus:bg-secondary focus:text-white focus:outline-none "
            >
              {suggestion.display_name}
            </li>
          ))}
        </ul>
      )}

      {location && (
        <div className="relative z-0 mt-4">
          <MapContainer
            center={[location.lat, location.lon]}
            zoom={13}
            style={{ height: "400px", width: "100%" }}
            key={`${location.lat}-${location.lon}`}
          >
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution="&copy; <a href='https://www.openstreetmap.org/copyright'>OpenStreetMap</a> contributors"
            />
            <Marker
              position={[location.lat, location.lon]}
              icon={customIcon}
              draggable={true}
              eventHandlers={{
                dragend: handleMarkerDragEnd,
              }}
            >
              <Popup>{formik.values.location}</Popup>
            </Marker>
          </MapContainer>
        </div>
      )}
    </div>
  );
};

export default EventLocation;
