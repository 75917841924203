import React, { useEffect, useState } from "react";
import PageTitle from "../../../components/ui/PageTitle";
import {
  Card,
  CardBody,
  CardFooter,
  Typography,
  Button,
  Spinner,
} from "@material-tailwind/react";
import { ArrowRightIcon, CheckCircleIcon } from "@heroicons/react/20/solid";
import { Link, useParams } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import { EMAIL_VALIDATION_MUTATION } from "../../../api/auth";
import LoadingSpinner from "../../../components/common/LoadingSpinner/LoadingSpinner";

const Verify: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(false);

  const { uid, userId } = useParams();

  const [validationStatus, { error }] = useMutation(EMAIL_VALIDATION_MUTATION);

  const setValidationStatus = async () => {
    if (uid && userId && uid.length > 0 && userId.length > 0) {
      setLoading(true);
      try {
        const res = await validationStatus({
          variables: {
            uid: uid,
            userId: Number(userId),
            emailValidate: true,
          },
        });
        if (res && res?.data?.updateEmailValidation) {
          setStatus(true);
          setLoading(false);
        } else {
          setStatus(false);
          setLoading(false);
        }
      } catch (error: any) {
        toast.error("Unable to verify email.");
        console.error(error);
        setStatus(false);
        setLoading(false);
      }
      setLoading(false);
    } else {
      setLoading(false);
      setStatus(false);
    }
  };

  useEffect(() => {
    setValidationStatus();
  }, []);

  if (error && !loading && !status) {
    return (
      <section className="flex min-h-[40vh] items-center px-4 py-10 text-center">
        <PageTitle heading="Oops! Something went wrong. Please try again later." />
      </section>
    );
  } else if (loading) {
    return (
      <section className="px-4 py-10 text-center">
        <div className="flex h-20 w-full justify-center">
          <LoadingSpinner />
        </div>
      </section>
    );
  } else if (status) {
    return (
      <section className="px-4 py-10 text-center">
        <div className="container mx-auto flex flex-col justify-center">
          <PageTitle section="Thank you" heading="Validation successful" />
          <Card className="mx-auto mt-6 w-72 items-center border border-secondary md:w-96">
            <CardBody>
              <CheckCircleIcon className="mx-auto h-20 w-20 text-green-400" />
              <Typography variant="h5" color="blue-gray" className="mb-2">
                Thank You! Your E-mail has been verified.
              </Typography>
              <Typography>Please proceed back to home.</Typography>
            </CardBody>
            <CardFooter className="pt-0">
              <Link to={"/"} className="inline-block">
                <Button
                  size="sm"
                  variant="text"
                  className="flex items-center gap-2"
                >
                  Back to home
                  <ArrowRightIcon className="h-4 w-4" />
                </Button>
              </Link>
            </CardFooter>
          </Card>
        </div>
      </section>
    );
  } else {
    return (
      <section className="flex min-h-[40vh] items-center px-4 py-10 text-center">
        <PageTitle heading="Oops! Something went wrong. Please try again later." />
      </section>
    );
  }
};

export default Verify;
