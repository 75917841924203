export const DiscountArrow: React.FC<{ strokColor: string }> = ({ strokColor }) => {
  return (
    <svg
      width="107"
      height="88"
      viewBox="0 0 107 88"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="-mt-8 h-20 w-14 md:w-20"
    >
      <path
        d="M95.4463 61.5975C83.1573 64.6611 68.4838 65.2433 57.6839 57.506C50.782 52.5613 47.1171 42.5628 49.6964 34.4471C52.1324 26.7825 57.8212 20.0482 66.3457 20.2534C70.789 20.3604 74.6201 22.4047 75.429 27.084C76.6648 34.2329 69.5331 41.6308 63.8629 44.7405C46.1672 54.4452 21.1341 53.9052 4.27686 42.6407"
        stroke={strokColor}
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path
        d="M11.7068 55.8447C9.64482 52.9634 5.14208 46.2418 3.62681 42.4054"
        stroke={strokColor}
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path
        d="M3.62695 42.4055C7.1396 41.942 15.124 40.6363 18.9603 39.121"
        stroke={strokColor}
        strokeWidth="3"
        strokeLinecap="round"
      />
    </svg>
  );
};
