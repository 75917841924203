import React, { useEffect, useState } from "react";
import { loadStripe, Stripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { gql, useMutation } from "@apollo/client";
import CheckoutForm from "../Pricing/CheckoutForm";
import { PAYMENT_SETUP_INTENT_MUTATION } from "../../api/Payment/setupIntent";
import { GET_STRIPE_PUBLISH_KEY } from "../../api/Payment/getStripePublishKey";
import SubscriptionDetails from "./SubscriptionDetails";
import { Link } from "react-router-dom";

const StripePaymentForm: React.FC = () => {
  // "pk_test_51MMFIWSFowwS5vuDmXfnr0z4ljWluRvJSGG1nEi989S8bqcXeevWaan3bxW2v5eXjUNLMc5xBPJOqcInfGGQ1xuk00LRPalgWp";
  const [stripePromise, setStripePromise] = useState<Stripe | null>(null);
  const [clientSecret, setClientSecret] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [getPublishKey, { data }] = useMutation(GET_STRIPE_PUBLISH_KEY);

  const [paymentSetupIntent] = useMutation(PAYMENT_SETUP_INTENT_MUTATION);

  useEffect(() => {
    const fetchPublishKey = async () => {
      try {
        const result = await getPublishKey({
          variables: {
            publishKey: true,
          },
        });
        const publishableKey = result.data.payment_getPublishKey.publishableKey;
        return publishableKey;
      } catch (error) {
        console.error("Error fetching publishable key:", error);
      }
    };

    const loadStripeAsync = async () => {
      try {
        const publishableKey = await fetchPublishKey();
        const stripe = await loadStripe(publishableKey);
        setStripePromise(() => stripe);
      } catch (error) {
        console.error("Error loading Stripe:", error);
      }
    };

    fetchPublishKey();
    loadStripeAsync();
  }, [getPublishKey, setStripePromise]);

  const fetchData = async () => {
    setIsLoading(true);
    setError(null);

    const customerId = localStorage.getItem("customerId");

    if (customerId) {
      try {
        const { data } = await paymentSetupIntent({
          variables: { customerid: customerId },
        });
        setClientSecret(data.payment_setupIntent.clientSecret);
      } catch (error) {
        setError("Error fetching client secret");
      }
    }

    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [paymentSetupIntent]);

  return (
    <div className="container mx-auto max-w-7xl px-4 pb-24 pt-6 md:px-8">
      <div className="mb-8 flex flex-col items-center justify-center">
        <img src="/logo.png" alt="Logo" className="h-auto w-32" />
      </div>

      <div className="rounded-xl flex flex-col md:flex-row max-w-3xl mx-auto shadow-md border border-solid border-gray-300">
        <div className="basis-1/3 rounded-t-xl md:rounded-l-xl md:rounded-tr-none bg-gray-200 p-10">
          <SubscriptionDetails />
        </div>
        <div className="rounded-b-xl md:rounded-r-xl basis-2/3 px-8 py-10 md:px-12">
          {isLoading && <div className="animate-pulse"><div className="mx-auto mb-5 h-80 w-full rounded-xl bg-gray-200"></div></div>}

          {error && (
            <p className="mb-4 text-sm font-bold text-red-500">{error}</p>
          )}

          {stripePromise && clientSecret && (
            <Elements stripe={stripePromise} options={{ clientSecret }}>
              <CheckoutForm />
            </Elements>
          )}
        </div>
      </div>

      <p className="mt-8 text-center text-gray-500">
        By completing this payment, you agree to our <Link to={'/terms-of-service'}>terms of service</Link>.
      </p>
    </div>
  );
};

export default StripePaymentForm;
