import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { GET_EVENT } from "../../../api/get-event";
import GetTicketDialog from "../GetTicketDialog";
import PageTitle from "../../../components/ui/PageTitle";
import { embeddedPageFlag } from "../../../helpers/embedded-paths";
import EventComponent from "../../../components/common/EventComponent/EventComponent";
import LoadingSpinner from "../../../components/common/LoadingSpinner/LoadingSpinner";

export const SingleEvent: React.FC = () => {
  const { encodedEventId } = useParams();
  const encodedId = atob(encodedEventId || "");
  const eventId = Number(encodedId);
  const {
    loading,
    error,
    data: getEventData,
  } = useQuery(GET_EVENT, {
    variables: { eventId },
  });
  

  const eventData = !loading && getEventData?.iframe_getEventByid[0];

  const pathname = window.location.pathname;
  const isEmbeddedEvent = embeddedPageFlag(pathname);
  const [open, setOpen] = useState(false);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const handleOpen = () => setOpen(!open);



  if (loading) {
    return (
      <section className="relative mt-2 min-h-[200px] bg-white px-4 pt-12 md:pt-0">
        <div className="mt-5 flex w-full justify-center">
          <LoadingSpinner />
        </div>
      </section>
    );
  } else if (error) {
    toast.error("Unable to fetch event details.");
    return (
      <section className="relative mt-2 min-h-[200px] bg-white px-4 pt-12 md:pt-0">
        <div className="flex w-full justify-center">
          <PageTitle heading="No Data Available">No Event Available</PageTitle>
        </div>
      </section>
    );
  } else if (getEventData && getEventData.iframe_getEventByid[0].id) {
    return (
      <section className="mt-2 bg-white px-4 pt-6 max-w-6xl mx-auto">
        {!loading && getEventData && eventData?.title ? (
          <EventComponent
            handleOpen={handleOpen}
            eventData={eventData}
            eventImages={eventData.imageUrls}
          />
        ) : (
          <PageTitle heading="No Data Available">No Event Available</PageTitle>
        )}
        {!loading && getEventData && eventData?.title && (
          <GetTicketDialog
            open={open}
            handleOpen={handleOpen}
            eventId={eventId}
            loadingBtn={loadingBtn}
            setLoadingBtn={setLoadingBtn}
            isEmbeddedEvent={isEmbeddedEvent}
            eventData={eventData}
          />
        )}
      </section>
    );
  } else {
    return (
      <section className="relative mt-2 min-h-[200px] bg-white px-4 pt-12 md:pt-0">
        <div className="flex w-full justify-center">
          <PageTitle heading="No Data Available">No Event Available</PageTitle>
        </div>
      </section>
    );
  }
};

export default SingleEvent;
