import { createSearchParams, useNavigate } from "react-router-dom";

interface NavigateSearch {
  pathname: string
  params: {
    section?: string
    tab?: string
  }
}

export const useNavigateSearch = () => {
  const navigate = useNavigate();
  return (args: NavigateSearch) =>
    navigate(`${args.pathname}?${createSearchParams(args.params)}`);
};