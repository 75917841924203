import { gql } from '@apollo/client';

export const CREATE_CUSTOMER_MUTATION = gql`
  mutation Payment_CreateCustomer(
    $userid: Int!,
    $username: String!,
    $useremail: String!,
    $userphoneNo: BigInt!,
    $address: stripeCreateCustomerAddress
  ) {
    payment_CreateCustomer(
      userid: $userid,
      username: $username,
      useremail: $useremail,
      userphone_no: $userphoneNo,
      address: $address
    ) {
      customerId
    }
  }
`;

